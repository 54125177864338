import { SlackAction } from "src/Alerts/AlertsDrawer"
import { Avatar, Space } from "antd"
import { Image } from "src/ui/Image"
import { ALERT_TYPE } from "src/Alerts/words"
import { Button } from "src/ui/Button/Button"
import { DeleteSlackActionModal } from "src/Alerts/DeleteSlackActionModal"
import { useState } from "react"
import { AlertCard } from "src/ui/Card/Card"

interface SlackAlertCardProps {
  slackAction: SlackAction
  checkpointId: string
  onEdit: () => void
  refetch: () => void
}

export const SlackAlertCard = ({ slackAction, checkpointId, onEdit, refetch }: SlackAlertCardProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  return (
    <>
      <AlertCard
        metaProps={{
          title: slackAction.name,
          avatar: <Avatar src={<Image type="slack" />} shape="square" size="small" />,
        }}
        actions={
          <Space>
            <Button type="text" size="small" icon="edit" onClick={onEdit} aria-label="Edit alert" />
            <Button
              type="text"
              size="small"
              icon="trash"
              aria-label="Delete alert"
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
          </Space>
        }
        description={
          <Space>
            <span>Alert type:</span>
            <span>{ALERT_TYPE[slackAction.action.notify_on.toUpperCase()]}</span>
          </Space>
        }
      />
      <DeleteSlackActionModal
        open={deleteModalOpen}
        checkpointId={checkpointId}
        onClose={() => setDeleteModalOpen(false)}
        slackActionName={slackAction.name}
        refetch={refetch}
      />
    </>
  )
}
