import { CaptureOptions, CaptureResult, Properties } from "posthog-js"
import { usePostHog } from "posthog-js/react"
import { SERVICE_NAME } from "src/common/config"
import { REACT_APP_ENV } from "src/common/env"
import { useOrganizations } from "src/organizations/useOrganizations"

/*
When possible, add events to the backend, as they are more reliable than front-end events (which may not reach us due to ad blockers, firewalls, etc.).
If testing customer adoption of a new button/field/page, PostHog autocapture can do this without a custom event type.
PostHog autocapture cannot handle certain actions like hover; see https://posthog.com/docs/product-analytics/autocapture#limitations-of-autocapture for details.
*/

type AnalyticsEvent =
  | "Expectation with Conditional Param"
  | "Expectation with Evaluation Param"
  | "Hover over Evaluation Param value in Validation Result"
  | "expectation.create_succeeded"
  | "expectation.create_failed"
  | "expectation.selected"
  | "checkpoint.view_snippet"
  | "checkpoint.run_from_agent"
  | "checkpoint.run_from_runner"
  | "$logout" // we have "$login" event send by auth0 so we need to have "$logout" as well
  | "survey.submitted"
  | "survey.seen"
  | "agent_setup.show_access_token_clicked"
  | "agent_setup.complete"
  | "agent_setup.feedback_submitted"
  | "agent_setup.setup_guide_clicked"
  | "agent_setup.prefer_to_use_api_clicked"
  | "invalid_url.accessed"
  | "add_asset.gxUser_modal_viewed"
  | "add_asset.snippet_copied"
  | "add_asset.continue_clicked"
  | "add_asset.existing_datasource.submit"
  | "validation_result.accessed_from_slack_notification"
  | "alerts.button_clicked"
  | "alerts.add_alert_button_clicked"
  | "banner_pricing_link.clicked"
  | "validation_result.accessed_from_email_alert"
  | "expectations_tab.accessed_from_email_alert"

type GroupType = "organization"

interface AnalyticsHandle {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- 3rd party type, we don't control it.
  capture: (event: AnalyticsEvent, properties?: Properties, opts?: CaptureOptions) => void | CaptureResult
  group: (type: GroupType, key: string, properties?: Properties) => void
  identify: (id: string, properties?: Properties) => void
}

// useAnalytics provides analytics capabilities.
// It wraps `usePostHog` which should be imported in this file only (clean architecture).
export function useAnalytics(): AnalyticsHandle | undefined {
  const lib = usePostHog()
  const orgID = useOrganizations().currentOrg?.id

  if (lib === undefined) {
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- 3rd party type, we don't control it.
  const capture = (event: AnalyticsEvent, properties?: Properties, opts?: CaptureOptions): void | CaptureResult => {
    lib.capture(
      event,
      { organization_id: orgID, environment: REACT_APP_ENV, service: SERVICE_NAME, ...properties },
      opts,
    )
  }

  const group = (type: GroupType, key: string, properties?: Properties): void => {
    lib.group(type, key, properties)
  }

  const identify = (id: string, properties?: Properties): void => {
    lib.identify(id, properties)
  }

  return { capture, group, identify }
}
