import { FragmentType, graphql, unmaskFragment } from "src/api/graphql"

export const UseDataSourceName_DataAssetFragmentDoc = graphql(`
  fragment UseDataSourceName_DataAsset on AssetRef {
    datasourceV2 {
      name
    }
  }
`)

type FragmentData = FragmentType<typeof UseDataSourceName_DataAssetFragmentDoc> | null | undefined

export function useDataSourceName(asset: FragmentData): string | undefined {
  const src = unmaskFragment(UseDataSourceName_DataAssetFragmentDoc, asset)
  return src?.datasourceV2?.name
}
