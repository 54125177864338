import styled from "styled-components"
import { SlackAlertCard } from "src/Alerts/SlackAlertCard"
import { SlackAction } from "src/Alerts/AlertsDrawer"
import { Button } from "src/ui/Button/Button"
import { useAnalytics } from "src/analytics/useAnalytics"
import { Space } from "antd"
import { Image } from "src/ui/Image"

const StyledButton = styled(Button)`
  margin: 0 auto;
`

type SlackActionsListProps = {
  checkpointId: string
  slackActions: SlackAction[]
  onEdit: (slackAction: SlackAction) => void
  onAdd: () => void
  refetch: () => void
}

export const SlackActionsList = ({ checkpointId, slackActions, onEdit, onAdd, refetch }: SlackActionsListProps) => {
  const posthog = useAnalytics()
  const onClick = () => {
    posthog?.capture("alerts.add_alert_button_clicked")
    onAdd()
  }
  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      {slackActions?.map((slackAction) => {
        return (
          <SlackAlertCard
            checkpointId={checkpointId}
            refetch={refetch}
            key={slackAction.name}
            onEdit={() => onEdit(slackAction)}
            slackAction={slackAction}
          />
        )
      })}
      <StyledButton type="dashed" icon={<Image type="slack" />} style={{ maxWidth: "auto" }} onClick={onClick}>
        Add Slack alert
      </StyledButton>
    </Space>
  )
}
