import React from "react"
import { FilterBox } from "src/ui/FilterBox/FilterBox"
import { ActiveListType, FailuresToggle } from "src/ui/Button/FailuresToggle"
import { LeftPanelWrapper } from "src/DataAssets/AssetDetails/Validations/ValidationTab.styles"
import { RunHistoryItem, RunHistoryUI } from "src/DataAssets/AssetDetails/Validations/RunHistory"
import { ExpectationSuiteSelector } from "src/DataAssets/AssetDetails/ExpectationSuiteSelector/ExpectationSuiteSelector"
import { Grid } from "antd"
import { useExpectationSuites } from "src/DataAssets/AssetDetails/Validations/ValidationHooks"
import { ValidationsTab_DataAssetQuery } from "src/api/graphql/graphql"

// Define an interface for the props
interface ValidationsTabFilterProps {
  aggregateFilteredSearchColumnNames: string[]
  aggregateSuiteLoading: boolean
  assetId: string
  columnNamesLoading: boolean
  dataAssetData: ValidationsTab_DataAssetQuery | undefined
  expectationSuiteId: string
  filteredSearchedColumnNames: string[]
  onAnchorSelection: (value: string) => void
  onSearch: (value: string) => void
  runHistoriesForAllValidations: RunHistoryItem[]
  runHistoriesLoading: boolean
  selectedRun: string
  setFilterByFailures: (value: ActiveListType) => void
  validationResultId: string
  validationResultLoading: boolean
}

export const ValidationsTabFilter: React.FC<ValidationsTabFilterProps> = ({
  aggregateFilteredSearchColumnNames,
  aggregateSuiteLoading,
  assetId,
  columnNamesLoading,
  dataAssetData,
  expectationSuiteId,
  filteredSearchedColumnNames,
  onAnchorSelection,
  onSearch,
  runHistoriesForAllValidations,
  runHistoriesLoading,
  selectedRun,
  setFilterByFailures,
  validationResultId,
  validationResultLoading,
}) => {
  const { useBreakpoint } = Grid
  const { lg } = useBreakpoint()
  const showAggregateRuns = validationResultId.length === 0
  const { expectationSuitesError } = useExpectationSuites(assetId)

  return (
    <LeftPanelWrapper direction="vertical" size="large" $lg={lg}>
      <FailuresToggle onToggleClick={setFilterByFailures} defaultValue="all" disabled={showAggregateRuns} />
      <ExpectationSuiteSelector currentTab="validations" />
      <RunHistoryUI
        error={expectationSuitesError}
        loading={runHistoriesLoading}
        validationRunHistories={runHistoriesForAllValidations}
        validationId={validationResultId}
        expectationSuiteId={expectationSuiteId}
        assetId={dataAssetData?.dataAsset?.id || ""}
      />
      <FilterBox
        onSearch={onSearch}
        list={showAggregateRuns ? aggregateFilteredSearchColumnNames : filteredSearchedColumnNames}
        loading={
          showAggregateRuns
            ? aggregateSuiteLoading
            : runHistoriesLoading || validationResultLoading || columnNamesLoading
        }
        onSelect={onAnchorSelection}
        selectedItem={selectedRun}
        title="Table of contents"
      />
    </LeftPanelWrapper>
  )
}
