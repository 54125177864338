/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Avatar, List } from "antd"
import { StyledListItem, StyledAppAlert, Inline } from "src/GXAgent/GXAgent.styles"
import { Icon, IconTypes } from "src/ui/Icon"
import { DataAssetFragmentDoc } from "src/api/graphql/graphql-operations"
import { JobStatus } from "src/api/graphql/graphql"
import { theme } from "src/ui/themes/theme"
import { Loading3QuartersOutlined } from "@ant-design/icons"
import { useFragment_experimental, useQuery } from "@apollo/client"
import { ExecutableDefinitionNode } from "graphql"
import { capitalize } from "lodash-es"
import { intlFormatDistance } from "date-fns"
import { Button } from "src/ui/Button/Button"
import { useState } from "react"
import { formatLocalCalendarDateWithTime } from "src/common/utils/formatTime"
import {
  CreateTestDatasourceJob,
  GenerateExpectationSuiteJob,
  CreateMetricsListJob,
  JobType,
  JobUnion,
  UnknownJob,
  ValidateDataJob,
  mapJobtoUnionType,
  CreateListTableNamesJob,
  GenerateDataQualityCheckExpectationsJob,
} from "src/GXAgent/JobUnion"
import { NODE_ENV } from "src/common/env"
import { JobErrorCodeEnum, translateJobErrorToDescription } from "src/GXAgent/JobErrorDescriptions"
import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql"
import { BodyStrong, DescriptionNormal } from "src/ui/typography/Text/Text"

export const JobsListCheckpointInfoDocument = graphql(`
  query JobsListCheckpointInfo {
    checkpoints {
      id
      name
      validations {
        id
        dataAsset {
          name
        }
      }
    }
  }
`)

export const JobDisplayNames: Record<JobType, string> = {
  GenerateExpectationSuiteJob: "Create Expectation Suite for Asset",
  CreateTestDatasourceJob: "Test Data Source Config",
  ValidateDataJob: "Validate Data",
  CreateMetricsListJob: "Fetch Metrics",
  CreateListTableNamesJob: "List table names",
  GenerateDataQualityCheckExpectationsJob: "Autogenerate expectations",
  Unknown: "Unsupported Job Type",
}

export const JobListItemInfoDocument = graphql(`
  fragment JobListItemInfo on Job {
    __typename
    id
    timeQueued
    errorMessage
    status
    sourceResources {
      entityId
      entityType
    }
    createdResources {
      entityId
      entityType
    }
    jobError {
      errorCode
      errorParams
      errorStackTrace
    }
  }
`)

type Props = {
  job: FragmentType<typeof JobListItemInfoDocument>
}
export function JobListItem({ job: _job }: Props) {
  const job = mapJobtoUnionType(unmaskFragment(JobListItemInfoDocument, _job))
  const [toggle, setToggle] = useState(false)
  const logButton = (
    <Inline key="logbutton">
      <Button
        type="link"
        title="See log"
        size="small"
        onClick={() => {
          setToggle(!toggle)
        }}
      >
        {toggle ? "Hide log" : "Show log"}
        <Icon
          name={toggle ? "chevronUp" : "chevronDown"}
          size="21px"
          style={{ marginTop: "5px", paddingLeft: "5px" }}
        />
      </Button>
    </Inline>
  )

  const actions = [job.errorMessage?.length ? [logButton] : []]
  let descriptionShown = job.errorMessage
  // When removing FF, just remove first condition
  // When completing taxonomy V1, remove the GENERIC_UNHANDLED_ERROR condition
  //   bc when theres no helpful taxonomy info, the stack trace is only actionable info
  if (job.jobError && job.jobError.errorCode && job.jobError.errorCode !== JobErrorCodeEnum.GENERIC_UNHANDLED_ERROR) {
    const translatedErrorMessage = translateJobErrorToDescription(job.jobError.errorCode)
    if (translatedErrorMessage) {
      descriptionShown = translatedErrorMessage
    }
  }

  return (
    <StyledListItem actions={actions}>
      <List.Item.Meta
        avatar={
          <Avatar
            size={16}
            style={{ background: job.status === "inProgress" ? "white" : jobStatusToIconColor(job.status) }}
            icon={<JobStatusIcon status={job.status} />}
          />
        }
        title={
          <>
            <JobListItemTitle job={job} />

            <DescriptionNormal>{formatLocalCalendarDateWithTime(job.timeQueued)}</DescriptionNormal>
          </>
        }
        // Convert new line to <br /> tag
        description={
          <StyledAppAlert
            $toggle={toggle}
            description={descriptionShown?.split("\n").map((e) => {
              return (
                <>
                  {e}
                  <br />
                </>
              )
            })}
            type="error"
            banner
            showIcon={false}
          />
        }
      />
    </StyledListItem>
  )
}

function JobListItemTitle({ job }: { job: JobUnion }) {
  switch (job.jobType) {
    case "CreateTestDatasourceJob":
    case "CreateListTableNamesJob":
      return <CreateAssetsJobTitle job={job} />
    case "GenerateExpectationSuiteJob":
      return <GenerateExpectationSuiteJobTitle job={job} />
    case "ValidateDataJob":
      return <ValidateDataJobTitle job={job} />
    case "CreateMetricsListJob":
      return <CreateMetricsListJobTitle job={job} />
    case "GenerateDataQualityCheckExpectationsJob":
      return <GenerateDataQualityCheckExpectationsJobTitle job={job} />
    case "Unknown":
      return <UnknownJobTitle job={job} />
  }
}

function GenerateDataQualityCheckExpectationsJobTitle({ job }: { job: GenerateDataQualityCheckExpectationsJob }) {
  const jobDisplayName = JobDisplayNames[job.jobType]
  const numAssets = job.sourceResources.filter(({ entityType }) => entityType === "DataAsset").length
  return (
    <span>
      {jobDisplayName} for {numAssets} data asset{`${numAssets > 1 ? "s" : ""}`}
    </span>
  )
}

function CreateAssetsJobTitle({ job }: { job: CreateTestDatasourceJob | CreateListTableNamesJob }) {
  const jobDisplayName = JobDisplayNames[job.jobType]
  return <span>{jobDisplayName}</span>
}

function CreateMetricsListJobTitle({ job }: { job: CreateMetricsListJob }) {
  const jobDisplayName = JobDisplayNames[job.jobType]
  const result = useFragment_experimental({
    fragment: DataAssetFragmentDoc,
    fragmentName: (DataAssetFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
    returnPartialData: true,
    from: { __typename: "AssetRef", id: job.dataAssetId },
  })

  return (
    <span>
      {jobDisplayName}
      {result.data?.name && <>: {result.data?.name}</>}
    </span>
  )
}

function GenerateExpectationSuiteJobTitle({ job }: { job: GenerateExpectationSuiteJob }) {
  const jobDisplayName = JobDisplayNames[job.jobType]

  const result = useFragment_experimental({
    fragment: DataAssetFragmentDoc,
    fragmentName: (DataAssetFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
    returnPartialData: true,
    from: { __typename: "AssetRef", id: job.dataAssetId },
    // variables: { dataAssetId: sourceResource.entityId },
  })

  return (
    <span>
      {jobDisplayName}
      {result.data?.name && (
        <>
          : <BodyStrong>{result.data?.name}</BodyStrong>
        </>
      )}
    </span>
  )
}

function ValidateDataJobTitle({ job }: { job: ValidateDataJob }) {
  const jobDisplayName = JobDisplayNames[job.jobType]
  const { data } = useQuery(JobsListCheckpointInfoDocument)
  const checkPointRef = data?.checkpoints.find((c) => c.id === job.checkpointId)
  // find the first data asset name that is not null
  const assetName = checkPointRef?.validations.find((c) => c.dataAsset?.name)?.dataAsset?.name

  return (
    <span>
      {jobDisplayName}

      {assetName && (
        <>
          : <BodyStrong>{assetName}</BodyStrong>
        </>
      )}
    </span>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function UnknownJobTitle({ job }: { job: UnknownJob }) {
  if (NODE_ENV === "development") {
    throw new Error("unimplemented job type found")
  }
  return <BodyStrong>Job</BodyStrong>
}

function JobStatusIcon({ status }: { status: JobStatus }) {
  if (status === "inProgress") {
    return <Loading3QuartersOutlined spin style={{ fontSize: "14px", color: jobStatusToIconColor(status) }} />
  }
  const iconName = jobStatusToIconName(status)
  return (
    <Icon
      size="14px"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      name={iconName}
      color={theme.colors.neutralColorPalette.whites.white}
    />
  )
}

function jobStatusToIconColor(status: JobStatus): string {
  switch (status) {
    case "complete":
      return theme.colors.success.gxSuccess
    case "error":
      return theme.colors.error.gxError
    case "queued":
      return theme.colors.neutralColorPalette.blacks.colorFillTertiary
    case "inProgress":
      return theme.colors.warning.colorWarning
  }
}

function jobStatusToIconName(status: JobStatus): IconTypes {
  switch (status) {
    case "complete":
      return "check"
    case "error":
      return "close"
    case "queued":
      return "stopwatch"
    case "inProgress":
      return "refresh"
  }
}

export function fmtApprox(iso?: string) {
  if (!iso) {
    return ""
  }
  return capitalize(intlFormatDistance(new Date(iso), new Date()))
}
