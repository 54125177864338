import { ConfigProvider, Segmented as AntDSegmented, SegmentedProps } from "antd"
import styled, { useTheme } from "styled-components"

const StyledSegmented = styled(AntDSegmented)`
  .ant-segmented-item.ant-segmented-item-selected.ant-segmented-item-disabled {
    background-color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorFill};
    color: ${({ theme }) => theme.colors.neutralColorPalette.whites.colorTextSecondaryDark};
  }
  .ant-segmented-item.ant-segmented-item-disabled {
    color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorTextQuaternary};
  }
` as typeof AntDSegmented

function Segmented<T extends string | number>(props: SegmentedProps<T>) {
  const theme = useTheme()
  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            itemSelectedBg: theme.colors.primaryColors.gxPrimaryMedium,
            itemSelectedColor: theme.colors.neutralColorPalette.whites.white,
          },
        },
      }}
    >
      <StyledSegmented<T>
        block={props.block}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      />
    </ConfigProvider>
  )
}

export { Segmented }
