import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

import { graphql } from "src/api/graphql/gql"
import {
  getSplitterConfig,
  SplitterUtils_SplitterFragmentDocument,
  useGetBatchDefinition,
} from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { SplitterUnion } from "src/api/graphql/graphql"
import { unmaskFragment } from "src/api/graphql"
import { DatePickerProps } from "antd"

export const UseGetSplitterDocument = graphql(`
  query UseGetSplitter($id: UUID!) {
    dataAsset(id: $id) {
      id
      splitter {
        ...SplitterUtils_Splitter
      }
    }
  }
`)

const supportedSplitterTypes = [
  "SplitterYearAndMonthAndDay",
  "SplitterYearAndMonth",
  "SplitterYear",
  "SplitterWholeAsset",
] satisfies Array<SplitterType>
export type SplitterType = SplitterUnion["__typename"]

export type SplitterData = {
  splitter: string
  splitterColumns?: string
  isSplitterSupported: boolean
  dateFormat: DatePickerProps["format"]
  picker: "month" | "year" | undefined
  splitterMethod: string
  splitterOptionsString: string
  isMulticolumnSplitter: boolean
}

export function useGetSplitterData({ isVisible }: { isVisible: boolean }): SplitterData {
  const { assetId: _assetId = "" } = useParams<{
    //TODO: it seems weird that this hook relies on an external URL param.
    assetId: string
    expectationSuiteId?: string
  }>()

  const assetId = _assetId ?? null
  const { data: dataAsset } = useQuery(UseGetSplitterDocument, {
    variables: { id: assetId },
    skip: !assetId || !isVisible,
  })
  const splitterData = unmaskFragment(SplitterUtils_SplitterFragmentDocument, dataAsset?.dataAsset?.splitter)
  const splitterType = splitterData?.["__typename"] ?? undefined
  const splitter = useGetBatchDefinition(splitterType)
  const { splitterMethod, dateFormat, picker, splitterOptionsString } = getSplitterConfig(splitterType) ?? {}

  const isSplitterSupported = splitterMethod ? (supportedSplitterTypes as string[]).includes(splitterType ?? "") : false

  const isMulticolumnSplitter = splitterData?.["__typename"] === "SplitterMultiColumnValue"
  const columnName = splitterData && "columnName" in splitterData ? splitterData.columnName : undefined
  const splitterColumns = isMulticolumnSplitter ? splitterData?.columnNames.join(", ") : columnName

  return {
    splitter,
    splitterColumns,
    isSplitterSupported,
    dateFormat,
    picker,
    splitterMethod,
    splitterOptionsString,
    isMulticolumnSplitter,
  }
}
