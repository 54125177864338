import { Drawer } from "src/ui/Drawer/Drawer"

type Props = {
  page: number
  setPage: (page: 1 | 2) => void
  isLoading: boolean
  onPage1Continue: () => void
  onPage2Submit: () => void
  onClose: () => void
  tableNames: string[]
}

export function AssetDrawerFooter({
  page,
  setPage,
  isLoading,
  onPage1Continue,
  onPage2Submit,
  onClose,
  tableNames,
}: Props) {
  const page2AddAssetsWithCheckboxAssetCreationText =
    tableNames.length === 1 ? `Add 1 Asset` : `Add ${tableNames.length} Assets`
  const page2FinishButtonText = page2AddAssetsWithCheckboxAssetCreationText
  return (
    <Drawer.Footer>
      <Drawer.FooterButton
        onClick={() => {
          switch (page) {
            case 1:
              return onClose()
            case 2:
              return setPage(1)
          }
        }}
      >
        Back
      </Drawer.FooterButton>
      <Drawer.FooterButton
        type="primary"
        loading={isLoading}
        onClick={() => {
          switch (page) {
            case 1:
              return onPage1Continue()
            case 2:
              return onPage2Submit()
          }
        }}
      >
        {page === 1 ? "Connect" : page2FinishButtonText}
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}
