/* eslint-disable react-refresh/only-export-components */ // FIXME
import { AggregateSuiteValidationResultQuery } from "src/api/graphql/graphql-operations"
import { StyledExpectationGroup, StyledList } from "src/DataAssets/AssetDetails/Validations/ValidationTab.styles"
import { FC } from "react"
import { List, ListProps } from "antd"
import { ValidationResultCharts } from "src/Expectation/ValidationResultCharts"
import { getExpectationGroupName } from "src/common/utils/getExpectationGroupName"
import { AggregateSuiteValidationResult } from "src/api/graphql/graphql"
import { removeInvalidHtmlCharacters } from "src/common/utils/removeInvalidHtmlCharacters"
import { sortAscWithTableExpectationsFirst } from "src/common/utils/sortAscWithTableExpectationsFirst"

interface ValidationResultChartListProps {
  aggregateValidations: AggregateSuiteValidationResultQuery | undefined
  selectedAnchor: string
}
export const ValidationResultChartList = ({ aggregateValidations, selectedAnchor }: ValidationResultChartListProps) => {
  const groupNameExpectationIdResultMap = getGroupNameAggregateValidationResults(aggregateValidations)
  const groupNameExpectationIdResultArray = Object.entries(groupNameExpectationIdResultMap).sort((a, b) => {
    return sortAscWithTableExpectationsFirst(a[0], b[0])
  })

  return (
    <>
      {groupNameExpectationIdResultArray.map(([groupName, validationResultGroup]) => {
        return (
          <div key={groupName}>
            <StyledExpectationGroup
              groupName={groupName}
              headerBottomBorder={false}
              $isSelected={selectedAnchor === removeInvalidHtmlCharacters(groupName.toLowerCase())}
            >
              <StyledList<FC<ListProps<AggregateSuiteValidationResult>>>
                itemLayout="vertical"
                dataSource={validationResultGroup}
                renderItem={(listValidationGroup: AggregateSuiteValidationResult) => (
                  <List.Item key={listValidationGroup?.expectationId}>
                    <ValidationResultCharts validationResultCharts={listValidationGroup?.results} />
                  </List.Item>
                )}
              />
            </StyledExpectationGroup>
          </div>
        )
      })}
    </>
  )
}

export const getGroupNameAggregateValidationResults = (
  aggregateValidations: AggregateSuiteValidationResultQuery | undefined,
): Record<string, AggregateSuiteValidationResult[]> => {
  const groupNameExpectationIdResultMap: Record<string, AggregateSuiteValidationResult[]> = {}
  aggregateValidations?.aggregateSuiteValidationResult.forEach((aggregateValidation) => {
    const most_recent_result = aggregateValidation.results[aggregateValidation.results.length - 1]
    const groupName = getExpectationGroupName(most_recent_result.expectationConfig)
    if (groupName in groupNameExpectationIdResultMap) {
      groupNameExpectationIdResultMap[groupName]?.push(aggregateValidation)
    } else {
      const listAggregateValidation = []
      listAggregateValidation.push(aggregateValidation)
      groupNameExpectationIdResultMap[groupName] = listAggregateValidation
    }
  })
  return groupNameExpectationIdResultMap
}
