import { FallbackProps } from "react-error-boundary"
import { ErrorState } from "src/ui/error"
import { useEffect, useMemo } from "react"
import { Sentry } from "src/observability/sentry/sentry"
import { NODE_ENV } from "src/common/env"

export function FeedbackForm(props: FallbackProps) {
  const isProd = NODE_ENV === "production"
  const errors: string[] = useMemo(() => [], [])
  const capturedMessage = props.error.message

  useEffect(() => {
    // We leave this outside of the renderForm to capture every event in Sentry
    const eventId = Sentry.captureMessage(capturedMessage)

    const renderForm = (id: string) => {
      Sentry.showReportDialog({ eventId: id })
    }

    if (isProd && !errors.includes(capturedMessage)) {
      renderForm(eventId)
      errors.push(capturedMessage)
    }
  }, [errors, capturedMessage, isProd])

  return <ErrorState resetErrorBoundary={props.resetErrorBoundary} />
}
