/* eslint-disable react-refresh/only-export-components */ // FIXME
import React from "react"
import { GroupLayout, JsonFormsRendererRegistryEntry, OwnPropsOfRenderer, rankWith, uiTypeIs } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { AntDLayoutRenderer } from "src/jsonforms/layouts/AntDLayoutRenderer"
import { Heading3 } from "src/ui/typography/Text/Text"

export const GroupRendererRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(10, uiTypeIs("Group")),
  renderer: React.memo(GroupComponent),
}

export type LayoutRendererProps = OwnPropsOfRenderer & {
  elements: UISchema<unknown>[]
}

function GroupComponent({ visible, enabled, uischema, ...props }: LayoutRendererProps) {
  const groupLayout = uischema as GroupLayout
  return (
    <>
      {groupLayout?.label && <Heading3>{groupLayout.label}</Heading3>}
      <AntDLayoutRenderer {...props} visible={visible} enabled={enabled} elements={groupLayout.elements} />
    </>
  )
}
