/*
 * Ideally, meta would be typed on the backend.
 * As of today, it isn't, though. For the time being,
 * We'll accept a json dict, but map it to a typed
 * frontend shape.
 */

type MetaFormat = "string" | "markdown"

export interface MetaNotes {
  format: MetaFormat
  content: Array<string>
}

type JSONString = string
export const parseMetaNotes = (rawMetaNotes: JSONString): MetaNotes => {
  // Being a bit trusting here that things parse to a valid meta
  // object. TODO: proper validation w/ a library
  try {
    const metaNotes: Record<string, unknown> = JSON.parse(rawMetaNotes || "")
    if (!metaNotes.format || !metaNotes.content) {
      throw new Error("Error parsing metaNotes")
    }
    return canonicalizeMetaNotes(metaNotes)
  } catch {
    throw new Error("Error parsing metaNotes")
  }
}

export const canonicalizeMetaNotes = (metaNotes?: Record<string, unknown>): MetaNotes => {
  let contentArray: string[] = []
  if (typeof metaNotes?.content === "string") {
    contentArray = [metaNotes.content]
  } else if (Array.isArray(metaNotes?.content)) {
    contentArray = metaNotes?.content
  } else {
    contentArray = []
  }

  return {
    // silently falling back to markdown formatting
    format: "markdown",
    content: contentArray,
  }
}
