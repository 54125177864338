import { AgentSetupMockModal } from "src/agentSetup/AgentSetupMockModal"
import { Flex, Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"

interface AgentSetupFeedbackSubmittedProps {
  onContinue: () => void
}

export function AgentSetupFeedbackSubmitted({ onContinue }: AgentSetupFeedbackSubmittedProps) {
  const modalContent = (
    <Flex align="center" vertical wrap="wrap" style={{ margin: "36px 60px 36px 60px" }}>
      <h2>Thanks for your feedback!</h2>
      <p style={{ marginBottom: "0px" }}>Your response has been submitted!</p>
      <p> A member of our product team will reach out to you shortly.</p>
      <Button icon={<ArrowLeftOutlined />} onClick={onContinue}>
        Back to instructions
      </Button>
    </Flex>
  )

  return <AgentSetupMockModal content={modalContent} />
}
