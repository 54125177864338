import { Spin } from "antd"
import styled, { css } from "styled-components"

const LoadingWrapper = styled.section<{ $absolute?: boolean }>`
  ${({ $absolute }) =>
    $absolute &&
    css`
      position: absolute;
    `}
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

function LoadingState({ loading, absolute = true }: { loading: boolean; absolute?: boolean }) {
  if (!loading) return null
  return (
    <LoadingWrapper aria-label="Loading spinner" $absolute={absolute}>
      <Spin />
    </LoadingWrapper>
  )
}

export { LoadingState }
