import styled from "styled-components"
import { Theme } from "src/ui/themes/theme"
import { ReactNode } from "react"
import { ImageProps } from "src/ui/Image/Image"
import { Col, Row } from "antd"
import { Heading2 } from "src/ui/typography/Text/Text"

const EmptySection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`

type ImageSize = keyof Theme["spacing"]["scale"]

export interface GetStartedProps {
  title: string
  illustration?: ImageProps["type"]
  imageSize?: ImageSize
  children?: string | ReactNode
  large?: boolean
}

export const GetStarted = ({ title, children, large }: GetStartedProps) => {
  return (
    <EmptySection>
      <Row
        justify="center"
        gutter={{ lg: 32, md: 24, xs: 16 }}
        style={{
          rowGap: "20px",
          maxWidth: large ? "1400px" : "1200px",
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "10px",
            justifyContent: "center",
          }}
        >
          <Heading2>{title}</Heading2>
          {children}
        </Col>
      </Row>
    </EmptySection>
  )
}
