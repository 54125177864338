import { useState } from "react"
import { Button } from "src/ui/Button/Button"
import { NewAssetDrawer } from "src/DataAssets/connect-to-data/NewAssetDrawer"

export function ConnectToDataButton() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <Button
        id="connect-to-data-button"
        type="primary"
        onClick={(e) => {
          e.stopPropagation()
          setIsDrawerOpen(true)
        }}
        icon="plus"
      >
        New Data Asset
      </Button>
      <NewAssetDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
    </>
  )
}
