import { useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { Flex, Space } from "antd"
import { useParams } from "react-router-dom"

import { graphql } from "src/api/graphql/gql"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { MainContent } from "src/layout/MainContent"
import { getSubtitle } from "src/DataAssets/AssetDetails/Expectations/utils"
import { theme } from "src/ui/themes/theme"
import {
  SimpleEditExpectationDrawer,
  EditExpectationFragment,
} from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleEditExpectationDrawer"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { ExpectationsTable } from "src/DataAssets/AssetDetails/Expectations/ExpectationsTable"
import { Image } from "src/ui/Image"
import {
  EmptyFilteredState,
  ExpectationsTableHeader,
  headerContent,
} from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationsHeaders"
import { useIsDemoData } from "src/common/hooks/useIsDemoData"
import { ValidateExpectationsAlert } from "src/Alerts/DemoData/ValidateExpectationsAlert"
import { CreateExpectationsAlert } from "src/Alerts/DemoData/CreateExpectationsAlert"
import { useGetAutogenerateStatus } from "src/common/hooks/useGetAutogenerateStatus"

export const ExpectationsTabDataAssetDocument = graphql(`
  query ExpectationsTabDataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      schedule {
        ...ScheduleSummary
      }
      splitter {
        ...BatchDefinitionDescription_Splitter
        ...EditBatchForm_Splitter
      }
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
        name
        type
        config
      }
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

export const ExpectationsTab_GetExpectationsDocument = graphql(`
  query ExpectationsTab_GetExpectations($input: ExpectationsInput!) {
    expectations(input: $input) {
      geCloudId
      gxManaged
      ...EditExpectationDrawer_Expectation
      ...ExpectationsTable_Expectation
    }
  }
`)

export const SimpleExpectationsTab = () => {
  const { assetId } = useParams<{
    assetId: string
  }>()
  const isEditor = useRequireRole("EDITOR")
  const [editingExpectationFragment, setEditingExpectationFragment] = useState<EditExpectationFragment | null>(null)
  const [isNewExpDrawerOpen, setIsNewExpDrawerOpen] = useState(false)
  const { jobStatus, job, jobStatusPreviousValue } = useGetAutogenerateStatus()

  const autogenLoading = useMemo(
    () => jobStatus === "loading" && Boolean(job?.sourceResources.find(({ entityId }) => entityId === assetId)),
    [assetId, job, jobStatus],
  )

  const { data: dataAssetData, loading: dataAssetLoading } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: {
      id: assetId ?? "", // would never pass empty string, but compilers must be satisfied
    },
    skip: !assetId,
  })

  const {
    data: expectationsFragment,
    loading: expectationsLoading,
    refetch,
  } = useQuery(ExpectationsTab_GetExpectationsDocument, {
    variables: {
      input: { dataAssetId: assetId ?? "" }, // would never pass empty string, but compilers must be satisfied
    },
    skip: !assetId,
  })

  useEffect(() => {
    // This covers the case where a user's camping on the expectations tab
    // while the autogenerate job runs & completes
    if (!autogenLoading && jobStatusPreviousValue === "loading") {
      refetch()
    }
  }, [autogenLoading, jobStatusPreviousValue, refetch])

  const hasExpectations = !expectationsLoading && (expectationsFragment?.expectations?.length ?? 0) > 0
  const dataAssetName = dataAssetData?.dataAsset?.name ?? ""
  const subtitle = getSubtitle(expectationsFragment?.expectations?.length ?? 0, expectationsLoading)
  const datasources = dataAssetData?.dataAsset?.datasourceV2
  const apiManagedExpectations =
    expectationsFragment?.expectations?.filter((expectation) => !expectation.gxManaged) ?? []
  const gxManagedExpectations =
    expectationsFragment?.expectations?.filter((expectation) => !!expectation.gxManaged) ?? []

  const isDemoData = useIsDemoData(dataAssetData?.dataAsset)

  return (
    <PageHeader
      headerContent={headerContent({
        dataAssetName,
        subtitle,
        datasources,
        isEditor,
        assetId,
        isNewExpDrawerOpen,
        setIsNewExpDrawerOpen,
      })}
      loading={expectationsLoading || dataAssetLoading}
    >
      <MainContent $noIllustration>
        {isDemoData && (
          <Space direction="vertical" style={{ marginBottom: theme.spacing.vertical.s }}>
            {hasExpectations ? <ValidateExpectationsAlert /> : <CreateExpectationsAlert />}
          </Space>
        )}
        <ExpectationsTable
          antdTableProps={{
            title: () => <ExpectationsTableHeader dataAssetId={assetId} hasExpectations={hasExpectations} />,
            locale: {
              emptyText: () => (
                <EmptyFilteredState
                  isEditor={isEditor}
                  assetId={assetId}
                  isNewExpDrawerOpen={isNewExpDrawerOpen}
                  setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
                  hasExpectations={gxManagedExpectations.length > 0}
                />
              ),
            },
          }}
          id="simple-expectations-gx-table"
          dataTestid="simple-expectations-gx-table"
          data={gxManagedExpectations}
          loading={expectationsLoading || autogenLoading}
          assetId={assetId ?? ""}
          setEditingExpectationFragment={setEditingExpectationFragment}
        />
        {!!apiManagedExpectations.length && (
          <ExpectationsTable
            antdTableProps={{
              title: () => (
                <Flex gap={theme.spacing.horizontal.xxs}>
                  <Image aria-label="code" type="code" style={{ fontSize: theme.spacing.scale.xxs }} />
                  <span>Cloud API (Schedule unavailable)</span>
                </Flex>
              ),
              style: { marginTop: theme.spacing.vertical.m },
              locale: {
                emptyText: () => (
                  <EmptyFilteredState
                    isEditor={isEditor}
                    assetId={assetId}
                    isNewExpDrawerOpen={isNewExpDrawerOpen}
                    setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
                    hasExpectations={apiManagedExpectations.length > 0}
                  />
                ),
              },
            }}
            id="simple-expectations-api-table"
            dataTestid="simple-expectations-api-table"
            data={apiManagedExpectations}
            loading={expectationsLoading}
            assetId={assetId ?? ""}
            setEditingExpectationFragment={setEditingExpectationFragment}
          />
        )}
        {assetId && editingExpectationFragment && (
          <SimpleEditExpectationDrawer
            dataAssetId={assetId}
            expectation={editingExpectationFragment}
            open={!!editingExpectationFragment}
            close={() => setEditingExpectationFragment(null)}
          />
        )}
      </MainContent>
    </PageHeader>
  )
}
