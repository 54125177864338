import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"

type AnyOf =
  | { type: "number" }
  | { type: "string"; format: "date" }
  | { type: "string"; format: "date-time" }
  | { type: "integer" }

type MaxValueSchema = {
  anyOf: AnyOf[]
  description: string
  title: "Max Value"
}
type MinValueSchema = Omit<MaxValueSchema, "title"> & { title: "Min Value" }

type StrictMaxSchema = {
  description: string
  title: "Strict Max"
  type: "boolean"
}
type StrictMinSchema = Omit<StrictMaxSchema, "title"> & { title: "Strict Min" }

// Conditional type to check if schema has necessary controls
type HasMinMaxStrict<T> = T extends {
  schema: {
    properties: {
      min_value: MinValueSchema
      max_value: MaxValueSchema
      strict_min: StrictMinSchema
      strict_max: StrictMaxSchema
    }
  }
}
  ? T
  : never

// Mapped type to extract keys of objects matching the condition
type KeysWithStrictMinMax<T> = {
  [K in keyof T]: HasMinMaxStrict<T[K]> extends never ? never : K
}[keyof T]

// Type will only include keys that have the necessary controls
type ExpectationNameWithStrictMinMax = KeysWithStrictMinMax<typeof jsonSchemas>

/**
 * @param key - key of expectation in jsonSchemas dict that contains strict min max fields
 * @returns Horzizontal Layout containing min and max elements each of which is in a Vertical Layout containing the normal input and its corresponding strict checkbox.
 */
export function betweenMinMaxStrictUiSchemaGroup(key: ExpectationNameWithStrictMinMax) {
  const { properties: p } = jsonSchemas[key].schema
  return {
    type: "HorizontalLayout",
    elements: [
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/min_value",
            formItemProps: {
              tooltip: p.min_value.description,
            },
          },
          {
            type: "Control",
            scope: "#/properties/strict_min",
            formItemProps: {
              tooltip: p.strict_min.description,
            },
          },
        ],
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/max_value",
            formItemProps: {
              tooltip: p.max_value.description,
            },
          },
          {
            type: "Control",
            scope: "#/properties/strict_max",
            formItemProps: {
              tooltip: p.strict_max.description,
            },
          },
        ],
      },
    ],
  } as const
}
