import { Collapse, Table } from "antd"
import styled, { css } from "styled-components"
import { getTableData } from "src/Expectation/utils/getTableData"
import { TableValueType } from "src/Expectation/ValidationResults"

const StyledTable = styled(Table)`
  width: 100%;

  thead > tr {
    th {
      color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorText};
      font-size: ${({ theme }) => theme.typography.fontSize.small};
      line-height: ${({ theme }) => theme.typography.lineHeight.large};
      font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
    }
  }

  tbody > tr > :nth-child(2) {
    background-color: ${({ theme }) => theme.colors.warning.colorWarningBg};
  }

  tbody > tr > :nth-child(1) {
    ${({ theme }) => css`
      font-size: ${theme.typography.fontSize.small};
      line-height: ${theme.typography.lineHeight.large};
      font-weight: ${theme.typography.fontWeight.semibold};
    `}
  }
`

interface ValidationHistoryTableProps {
  value: TableValueType
  ariaLabel?: string
}

export const ValidationHistoryTable = ({ value, ariaLabel }: ValidationHistoryTableProps) => {
  const headerRow = value.headerRow

  const table = value.table as string[][]
  if (!headerRow || !table) return null
  const [tableColumns, tableDatasource] = getTableData(headerRow, table)
  return (
    <Collapse
      size="small"
      items={[
        {
          key: "1",
          label: "Validation History",
          children: (
            <StyledTable
              aria-label={ariaLabel}
              pagination={false}
              dataSource={tableDatasource}
              columns={tableColumns}
              rowKey={(row, index) => {
                return index + ""
              }}
              scroll={{ x: "100%" }}
              size="small"
            />
          ),
        },
      ]}
    />
  )
}
