import { MemoryRouterProps, RouterProvider, createBrowserRouter, createMemoryRouter } from "react-router-dom"
import { useRoutes } from "src/layout/routes/useRoutes"

function AppRouter() {
  const routes = useRoutes()
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}

function AppRouterForTesting(props: MemoryRouterProps) {
  const routes = useRoutes()
  const router = createMemoryRouter(routes, props)
  return <RouterProvider router={router} />
}

// AppRouterForTestingElement is useful for rendering a single element in the context of the app router.
// Provide the element you want to render as a child.
function AppRouterForTestingElement(props: MemoryRouterProps) {
  // process the app's actual route handlers
  const routes = useRoutes()

  const router = createMemoryRouter(
    [
      {
        // this pathless element is what will render
        element: props.children,
        // these children routes will not render (assuming the input element does not include an Outlet)
        children: routes,
      },
    ],
    props,
  )

  return <RouterProvider router={router} />
}

export { AppRouter, AppRouterForTesting, AppRouterForTestingElement }
