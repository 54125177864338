import { RuleEffect } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { SqliteDataSourceJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/sqlite-data-source-json-schema"

export const SqliteDataSourcePage1UISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/type",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Source name",
    },
    {
      type: "Group",
      elements: [
        {
          type: "Control",
          scope: "#/properties/connection_string",
          label: "Connection string",
          options: {
            type: "singleline",
            required: true,
            placeholderText: `sqlite:///PATH_TO_DB_FILE`,
            rules: [],
          },
          formItemProps: {
            tooltip: "Must start with sqlite:///",
          },
        },
      ],
    },
  ],
} satisfies UISchema<typeof SqliteDataSourceJsonSchema>

export const SqliteDataSourcePage2UISchema = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Data Asset",
      type: "Control",
      scope: "#/properties/assets",
    },
  ],
} satisfies UISchema<typeof SqliteDataSourceJsonSchema>
