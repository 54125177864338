import { GetStarted } from "src/ui/GetStarted/GetStarted"

import { Button } from "src/ui/Button/Button"
import { useState } from "react"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { CreateExpectationForAssetDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetDrawer"
import { CreateExpectationForAssetAndSuiteDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetAndSuiteDrawer"
import { EMPTY_EXPECTATION_SUITE } from "src/DataAssets/AssetDetails/Expectations/words"

interface EmptyExpectationSuiteProps {
  assetId: string
  expectationSuiteId?: string
}

function EmptyExpectationSuite({ assetId, expectationSuiteId }: EmptyExpectationSuiteProps) {
  const [open, setOpen] = useState(false)

  return (
    <GetStarted title={EMPTY_EXPECTATION_SUITE}>
      <>
        <Button icon="plus" size="large" type="primary" onClick={() => setOpen(true)}>
          New Expectation
        </Button>
        {!expectationSuiteId ? (
          <CreateExpectationDrawerContextProvider open={open} onClose={() => setOpen(false)}>
            <CreateExpectationForAssetDrawer assetId={assetId} />
          </CreateExpectationDrawerContextProvider>
        ) : (
          <CreateExpectationDrawerContextProvider open={open} onClose={() => setOpen(false)}>
            <CreateExpectationForAssetAndSuiteDrawer assetId={assetId} expectationSuiteId={expectationSuiteId} />
          </CreateExpectationDrawerContextProvider>
        )}
      </>
    </GetStarted>
  )
}

export { EmptyExpectationSuite }
