import styled from "styled-components"
import { Theme } from "src/ui/themes/theme"
import { ReactNode } from "react"
import { ImageProps } from "src/ui/Image/Image"
import { BodyNormal, Heading1 } from "src/ui/typography/Text/Text"

const EmptySection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`

type ImageSize = keyof Theme["spacing"]["scale"]

const StyledHeading = styled(Heading1)`
  margin-top: 40px;
  margin-bottom: 16px;
`

export interface EmptyStateProps {
  illustration?: ImageProps["type"]
  imageSize?: ImageSize
  title: string
  children?: string | ReactNode
}

export const EmptyState = ({ children, title }: EmptyStateProps) => (
  <EmptySection>
    <StyledHeading>{title}</StyledHeading>
    {!!children && <BodyNormal>{children}</BodyNormal>}
  </EmptySection>
)
