import { cloneDeep } from "lodash-es"
import { WindowedExpectation, WindowedMinMaxExpectations } from "src/common"
import {
  ExpectationConfig,
  MostlyWindow,
  OffsetWindow,
  WindowConfig,
  Window,
} from "src/Expectation/CreateExpectationDrawer/types"
import { SnakeCasedExpectationType } from "src/schemas/expectation-metadata-utils"
import { getParameterSafeUniqueId } from "src/Expectation/CreateExpectationDrawer/utils"

interface CreateWindowProps {
  config: WindowConfig
  minMax: string
  selectedExpectation: string
  unique_id: string
}

export const createWindow = ({ config, minMax, selectedExpectation, unique_id: uuid }: CreateWindowProps): Window => ({
  constraint_fn: "mean",
  parameter_name: `${uuid}_${selectedExpectation}_${minMax}`,
  range: config.range,
  offset: {
    positive: config.percent,
    negative: config.percent,
  },
})

export const handleOffsetWindow = (
  config: ExpectationConfig,
  toBeBetween: OffsetWindow,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  config.min_value = config.max_value = null
  const windows: Window[] = []
  const unique_id = getParameterSafeUniqueId()

  if (toBeBetween.offset.includes("-")) {
    windows.push(createWindow({ config: toBeBetween, minMax: "min", selectedExpectation, unique_id }))
    config.min_value = { $PARAMETER: `${unique_id}_${selectedExpectation}_min` }
  }
  if (toBeBetween.offset.includes("+")) {
    windows.push(createWindow({ config: toBeBetween, minMax: "max", selectedExpectation, unique_id }))
    config.max_value = { $PARAMETER: `${unique_id}_${selectedExpectation}_max` }
  }

  config.windows = windows

  return config
}

export const handleMostlyWindow = (
  config: ExpectationConfig,
  toBeBetween: MostlyWindow,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  if ("percent" in toBeBetween) {
    const id = getParameterSafeUniqueId()
    // "percent" and "mostly" are mutually exclusive in these cases
    return {
      ...config,
      mostly: { $PARAMETER: `${id}_${selectedExpectation}_min` },
      windows: [createWindow({ config: toBeBetween, minMax: "min", selectedExpectation, unique_id: id })],
    }
  }
  return { ...config, ...(toBeBetween as object) }
}

/**
 * If we send an empty string for min/max values, it's possible the backend
 * will interpret it as a parameter string and attempt to parse it as one.
 * To avoid this, `sanitizeMinMax` introspects both top-level and to_be_between
 * min/max values and replaces empty strings with `null`.
 * @param config ExpectationConfig
 * @returns sanitized ExpectationConfig
 */
export const sanitizeMinMax = (config: ExpectationConfig) => {
  const sanitizeValue = (value: unknown) => (value === "" ? null : value)

  const sanitizedConfig = {
    ...config,
    ...("min_value" in config && { min_value: sanitizeValue(config.min_value) }),
    ...("max_value" in config && { max_value: sanitizeValue(config.max_value) }),
    ...(config.to_be_between && {
      to_be_between: {
        ...config.to_be_between,
        ...("min_value" in config.to_be_between && { min_value: sanitizeValue(config.to_be_between.min_value) }),
        ...("max_value" in config.to_be_between && { max_value: sanitizeValue(config.to_be_between.max_value) }),
      },
    }),
  }

  return sanitizedConfig
}

export const handleWindowedPayload = (
  configObj: ExpectationConfig,
  selectedExpectation: SnakeCasedExpectationType,
  windowed?: boolean,
) => {
  const config = cloneDeep(configObj)
  if (!config.to_be_between) {
    // If a standard expectation, we need to make sure the windows prop does not exist
    delete config.windows
    return config
  }

  const { to_be_between } = config
  delete config.to_be_between

  if ("offset" in to_be_between && "range" in to_be_between) {
    return handleOffsetWindow(config, to_be_between as OffsetWindow, selectedExpectation)
  }
  if ("mostly" in to_be_between) {
    return handleMostlyWindow(config, to_be_between as MostlyWindow, selectedExpectation)
  }
  // If we get to this point and still have an "offset" value, delete it
  // because the expectation has none of the markers of a dynamic expectation
  // and the presence of "offset" will break the schema validation
  if ("offset" in to_be_between) {
    delete to_be_between.offset
  }

  // Another instance where we may need to delete config.windows, i.e.
  // A dynamic expectation where the user may have opted to use its fixed variant
  delete config.windows

  // if neither of the above conditions are met, return the object as it was,
  // and if windowedParams are not enabled, don't include to_be_between
  const updatedConfig = { ...config, ...(windowed ? to_be_between : {}) }

  return sanitizeMinMax(updatedConfig)
}

function getOffsetMarker(windows: Window[]) {
  const bounds = {
    positive: false,
    negative: false,
  }

  windows.forEach((w) => {
    if (w.parameter_name.match(/_min$/)) {
      bounds.negative = true
    }
    if (w.parameter_name.match(/_max$/)) {
      bounds.positive = true
    }
  })

  return `${bounds.positive ? "+" : ""}${bounds.positive && bounds.negative ? "/" : ""}${bounds.negative ? "-" : ""}`
}

export const constructToBeBetweenCombinator = (
  expectationConfig: Record<string, unknown>,
  expectationType: string | null | undefined,
) => {
  const constructedConfig = { ...expectationConfig }

  if ("windows" in constructedConfig) {
    const windows = constructedConfig.windows as Window[]
    if (windows.length > 0) {
      constructedConfig.to_be_between = {
        offset: getOffsetMarker(windows),
        percent: windows[0].offset.negative,
        range: windows[0].range,
        strict: false,
      }
    }
    delete constructedConfig.windows
    return constructedConfig
  }

  if (expectationType && WindowedMinMaxExpectations.includes(expectationType as WindowedExpectation)) {
    constructedConfig.to_be_between = {
      min_value: constructedConfig.min_value,
      max_value: constructedConfig.max_value,
      strict_min: constructedConfig.strict_min,
      strict_max: constructedConfig.strict_max,
    }
  }

  return constructedConfig
}

export const handleBaselineConfig = (expectationConfig?: ExpectationConfig) => {
  // In the occasional case we have an expectation config with min_value/max_value and
  // offset both present, we need to delete the offset since it's unrelated to
  // a min/max constraint configuration.
  const config = { ...expectationConfig }
  if ("to_be_between" in config) {
    // spread these to avoid editing original object reference
    const to_be_between = { ...config.to_be_between }
    if (to_be_between && "offset" in to_be_between && ("min_value" in to_be_between || "max_value" in to_be_between)) {
      delete to_be_between.offset
      return { ...config, to_be_between }
    }
  }
  return config
}
