/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Segmented } from "src/ui/Segmented/Segmented"
import { SegmentedValue } from "antd/es/segmented"

export const ViewModeTypes = ["currentView", "changeLog"] as const
export type ViewModes = (typeof ViewModeTypes)[number]
type ViewOption = { label: string; value: ViewModes }

export interface ViewToggleProps {
  onToggleClick: (selection: ViewModes) => void
  defaultValue?: ViewModes
  disabled?: boolean
}

export const viewOptions: ViewOption[] = [
  {
    label: "Current View",
    value: "currentView",
  },
  {
    label: "Change Log",
    value: "changeLog",
  },
]

export const ViewToggle = ({ onToggleClick, defaultValue, disabled = false }: ViewToggleProps) => {
  return (
    <Segmented
      block
      options={viewOptions}
      defaultValue={defaultValue || viewOptions[0].value}
      disabled={disabled}
      onChange={(e: SegmentedValue) => {
        onToggleClick(e as ViewModes)
      }}
    />
  )
}
