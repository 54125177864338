import { useLocation, useMatches } from "react-router-dom"
import { useEffect } from "react"
import posthog from "posthog-js"
import { Handle } from "src/layout/routes/useRoutes"
import { MatchAlias } from "src/ui/AppBreadcrumb/AppBreadcrumb"

interface Match extends MatchAlias {
  handle: Handle
}

export const useTrackAnalyticsPageView = () => {
  const location = useLocation()
  const matches = useMatches() as Match[]

  useEffect(() => {
    const pageviewName = matches.length > 0 && matches[matches.length - 1].handle?.pageviewName
    if (pageviewName) {
      posthog.capture("$pageview", { page: pageviewName })
    }
  }, [location, matches])
}
