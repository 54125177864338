import { useCallback, useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { Alert, Flex, Form, Input } from "antd"
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"
import { copyToClipboard } from "src/common/utils/clipboard"
import { IssueUserApiTokenDocument } from "src/api/graphql/graphql-operations"
import { Button } from "src/ui/Button/Button"
import { ISSUE_TOKEN_ERROR_DESCRIPTION, ISSUE_TOKEN_ERROR_TEXT } from "src/DataAssets/words"
import { useAnalytics } from "src/analytics/useAnalytics"

export function GenerateTokenFormItem() {
  const analytics = useAnalytics()
  // Token related state:
  const [tokenVisible, setTokenVisible] = useState(true)
  const tokenLoadingValue = "Token loading..."
  const [token, setToken] = useState<string>(tokenLoadingValue)
  const [showTokenAnalyticsEventFired, setShowTokenAnalyticsEventFired] = useState(false)
  const defaultTokenName = "Default Access Token"

  const [issueApiToken, { loading: loadingIssueToken, error: errorIssuingToken }] = useMutation(
    IssueUserApiTokenDocument,
    {
      variables: {
        input: {
          organizationRole: "EDITOR",
          name: defaultTokenName,
        },
      },
    },
  )

  const setTokenVisibleWithAnalytics = (visible: boolean) => {
    if (visible && !showTokenAnalyticsEventFired) {
      analytics?.capture("agent_setup.show_access_token_clicked")
      setShowTokenAnalyticsEventFired(true)
    }
    return setTokenVisible(visible)
  }

  const createToken = useCallback(async () => {
    const result = await issueApiToken({ onError: () => {} })
    const tkn = result.data?.issueUserApiToken?.token ?? ""
    setTokenVisible(false)
    setToken(tkn)
  }, [issueApiToken])

  useEffect(() => {
    if (!errorIssuingToken && token === tokenLoadingValue) {
      createToken()
    }
  }, [createToken, errorIssuingToken, token])

  return (
    <>
      <Form.Item label="Access token (copy and store securely):">
        <Flex justify="space-between" gap={10}>
          <Input.Password
            value={token}
            aria-label="Access Token"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            visibilityToggle={{ visible: tokenVisible, onVisibleChange: setTokenVisibleWithAnalytics }}
          />
          <Button
            icon="clipboard"
            aria-label="Copy Token"
            title="Copy Token"
            onClick={() => copyToClipboard(token, "Access token copied to clipboard")}
            disabled={loadingIssueToken || token === tokenLoadingValue}
          />
        </Flex>
      </Form.Item>
      {errorIssuingToken && (
        <Alert message={ISSUE_TOKEN_ERROR_TEXT} type="error" description={ISSUE_TOKEN_ERROR_DESCRIPTION} />
      )}
    </>
  )
}
