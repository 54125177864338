import { Flex, Table, Tooltip } from "antd"
import type { TableProps } from "antd"
import { useState } from "react"
import { capitalize, uniqBy } from "lodash-es"
import { intlFormatDistance } from "date-fns"

import { getDomainKwargs } from "src/common/utils/getExpectationGroupName"
import { graphql } from "src/api/graphql/gql"
import {
  ExpectationValidationResultV2,
  Domain,
  ExpectationsTable_ExpectationFragment,
  RenderedExpectationFragment,
} from "src/api/graphql/graphql"
import { EditExpectationFragment } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleEditExpectationDrawer"
import { getRenderer } from "src/Expectation/utils"
import { Button } from "src/ui/Button/Button"
import { DeleteExpectationModal } from "src/DataAssets/AssetDetails/Expectations/DeleteExpectationModal"
import { FragmentType, unmaskFragment } from "src/api/graphql"
import { Image } from "src/ui/Image"
import { Icon } from "src/ui/Icon"
import { parseMetaNotes } from "src/Expectation/utils/parsing"
import { MetaNotes } from "src/Expectation/MetaNotes"

export const ExpectationsTable_ExpectationFragmentDocument = graphql(`
  fragment ExpectationsTable_Expectation on ExpectationConfiguration {
    geCloudId
    gxManaged
    autogenerated
    kwargs
    expectationType
    domain {
      domainKwargs
      domainType
      id
      domainColumns {
        name
        order
      }
    }
    renderedContent {
      ...RenderedExpectation
    }
    validationResults(input: { limit: 1 }) {
      runTime
      success
    }
  }
`)

type ExpectationTableExpectationFragment = FragmentType<typeof ExpectationsTable_ExpectationFragmentDocument>

interface ExpectationsTableProps {
  antdTableProps: TableProps
  assetId: string
  data: ExpectationTableExpectationFragment[]
  loading: boolean
  id: string
  setEditingExpectationFragment: (fragment: EditExpectationFragment) => void
  dataTestid?: string
}

type DataType = {
  expectation: ExpectationData
  lastValidated: Omit<ExpectationValidationResultV2, "__typename">
  actions: { expectationFragment: EditExpectationFragment; expectationId: string | null }
}

interface ExpectationData extends DomainData {
  content: string | JSX.Element | null
  id: string
  autogenerated: boolean
}

type DomainData = {
  domainName: string
  sortValue: string
  truncateInfo: {
    truncate: boolean
    fullName: string
    restColumns?: string
  }
}

type OnChange = TableProps<DataType>["onChange"]
type SortOrder = "descend" | "ascend" | null

export const ExpectationsTable = (props: ExpectationsTableProps) => {
  const { assetId, data, id, loading, setEditingExpectationFragment, antdTableProps, dataTestid } = props

  const [domainSortOrder, setDomainSortOrder] = useState<SortOrder>(null)
  const unmaskedData = unmaskFragment(ExpectationsTable_ExpectationFragmentDocument, data)

  const handleChange: OnChange = () => {
    switch (domainSortOrder) {
      case null:
        setDomainSortOrder("ascend")
        break
      case "ascend":
        setDomainSortOrder("descend")
        break
      case "descend":
      default:
        setDomainSortOrder(null)
        break
    }
  }

  return (
    <Table
      {...antdTableProps}
      id={id}
      data-testid={dataTestid}
      columns={getColumns(unmaskedData, domainSortOrder, assetId ?? "", setEditingExpectationFragment)}
      dataSource={getExpectationRowData(unmaskedData, domainSortOrder)}
      loading={loading}
      onChange={handleChange}
      pagination={{ hideOnSinglePage: true, position: ["bottomRight"], pageSize: 15 }}
      rowKey={({ expectation }: DataType) => expectation.id}
    />
  )
}

const getColumns = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
  assetId: string,
  onEdit: (expectationFragment: EditExpectationFragment) => void,
): TableProps<DataType>["columns"] => [
  {
    dataIndex: "automaticallyGenerated",
    key: "automaticallyGenerated",
    render: (_, { expectation }) => {
      if (expectation.autogenerated) {
        return (
          <>
            <Tooltip title="Expectation was automatically generated" placement="right">
              <Icon name="bolt" color="#8c8c8c" />
            </Tooltip>
          </>
        )
      }
    },
  },
  {
    title: "Expectation",
    dataIndex: "expectation",
    key: "exp",
    filters: getDomainFilter(data),
    onFilter: (value, record) => record.expectation.domainName === value,
    filterMultiple: true,
    sorter: (a, b) => sorter(a.expectation.sortValue, b.expectation.sortValue),
    sortOrder: domainSortOrder,
    render: (_, { expectation }) => expectation.content,
  },
  {
    title: "Last validated",
    dataIndex: "lastValidated",
    key: "lastValidated",
    filters: [
      { text: "Pass", value: true },
      { text: "Fail", value: false },
    ],
    onFilter: (value, record) => record.lastValidated.success === value,
    filterMultiple: false,
    width: "250px",
    render: (_, { lastValidated }) => {
      const { success, runTime } = lastValidated
      if (runTime === null && success === null) {
        return null
      }
      const icon = success ? "success" : "failure"
      return (
        <Flex gap="small">
          <Image aria-label={icon} type={icon} /> {runTime}
        </Flex>
      )
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "100px",
    render: (_, { actions }) => {
      const { expectationId, expectationFragment } = actions
      return (
        <>
          <Button
            type="text"
            aria-label="Edit Expectation"
            icon="edit"
            disabled={!expectationFragment}
            onClick={() => (expectationFragment ? onEdit(expectationFragment) : undefined)}
          />
          <DeleteExpectationModal expectationId={expectationId} assetId={assetId} />
        </>
      )
    },
  },
]

const sorter = (a: string, b: string) => a.localeCompare(b, undefined, { numeric: true })
const noDomainPlaceholder = { domainName: "", sortValue: "", truncateInfo: { truncate: false, fullName: "" } }

const getExpectationRowData = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
): DataType[] => {
  if (!data) {
    return []
  }

  return data.map((exp) => {
    const { domainName, sortValue, truncateInfo } = exp?.domain
      ? getDomainData(exp.domain, domainSortOrder)
      : noDomainPlaceholder
    const metaNotes = exp?.renderedContent ? getMetaNotes(exp.renderedContent) : null

    const content = (
      <Flex vertical gap="middle">
        {getRenderer({
          renderedValue: exp?.renderedContent?.[0],
          fallback: exp?.expectationType ?? undefined,
          kwargs: exp?.kwargs,
        }) ?? null}
        {metaNotes ? <MetaNotes metaNotes={metaNotes} /> : null}
      </Flex>
    )

    return {
      expectation: {
        content,
        id: exp?.geCloudId ?? "",
        autogenerated: exp?.autogenerated ?? false,
        sortValue,
        domainName,
        truncateInfo,
      },
      lastValidated: {
        success: exp?.validationResults?.[0]?.success ?? null,
        runTime: exp?.validationResults?.[0]?.runTime ? fmtApprox(exp?.validationResults?.[0]?.runTime) : null,
      },
      actions: { expectationId: exp?.geCloudId ?? null, expectationFragment: exp as EditExpectationFragment },
    }
  })
}

const getMultiColSortValue = (domainSortOrder: SortOrder, columns?: Domain["domainColumns"]) => {
  if (!columns || !domainSortOrder) {
    return ""
  }
  if (columns.length === 1) {
    return columns[0]?.name ?? ""
  }
  const ascSortedColumns = [...columns].sort((a, b) => {
    return sorter(a?.name ?? "", b?.name ?? "")
  })
  const lastElement = ascSortedColumns[ascSortedColumns.length - 1]
  return domainSortOrder === "ascend" ? (ascSortedColumns[0]?.name ?? "") : (lastElement?.name ?? "")
}

const getDomainData = (domain: Domain, domainSortOrder: SortOrder): DomainData => {
  const MAXCOLNR = 4
  const { column: columnName = "" } = getDomainKwargs({ __typename: "ExpectationConfiguration", domain })

  switch (domain.domainType) {
    case "COLUMN": {
      return {
        domainName: columnName,
        sortValue: columnName,
        truncateInfo: { truncate: false, fullName: columnName },
      }
    }
    case "COLUMN_PAIR":
    case "MULTICOLUMN": {
      const shouldTruncate = (domain.domainColumns?.length ?? 0) > MAXCOLNR
      const domainColumnsCopy = domain.domainColumns ? [...domain.domainColumns] : []
      const showCols = shouldTruncate ? domainColumnsCopy.splice(0, MAXCOLNR) : domain.domainColumns
      const joinColNames = (cols: Domain["domainColumns"] | undefined) =>
        cols ? cols.map((col) => col?.name).join(", ") : ""
      return {
        domainName: joinColNames(showCols),
        sortValue: getMultiColSortValue(domainSortOrder, domain.domainColumns),
        truncateInfo: {
          truncate: shouldTruncate,
          restColumns: joinColNames(domainColumnsCopy),
          fullName: joinColNames(domain.domainColumns),
        },
      }
    }
    case "TABLE": {
      const name = "Asset"
      return { domainName: name, sortValue: name, truncateInfo: { truncate: false, fullName: name } }
    }
    default: {
      const name = domain?.domainColumns?.[0]?.name ?? domain.domainType
      return {
        domainName: name ?? "",
        sortValue: name ?? "",
        truncateInfo: { truncate: false, fullName: name ?? "" },
      }
    }
  }
}

const getDomainFilter = (data: ExpectationsTable_ExpectationFragment[]) => {
  if (!data) {
    return []
  }

  const domainCol = data.map((exp) => {
    return exp?.domain ? getDomainData(exp?.domain, "ascend") : noDomainPlaceholder
  })

  const sortedFilterList = domainCol.sort((a, b) => sorter(a.sortValue, b.sortValue))
  const uniqSortedFilterList = uniqBy(sortedFilterList, "truncateInfo.fullName")
  return uniqSortedFilterList.map(({ domainName, truncateInfo }) => ({
    text: <DomainFilterItem domainName={domainName} truncateInfo={truncateInfo} />,
    value: domainName,
  }))
}

function DomainFilterItem({ domainName, truncateInfo }: Omit<DomainData, "sortValue">) {
  return truncateInfo.truncate ? (
    <Tooltip placement="topRight" title={`...${truncateInfo.restColumns}`}>
      <span>{`${domainName}...`}</span>
    </Tooltip>
  ) : (
    <span>{domainName}</span>
  )
}

function fmtApprox(iso: string) {
  return capitalize(intlFormatDistance(new Date(iso), new Date()))
}

export function getMetaNotes(renderedContent: (RenderedExpectationFragment | null)[]) {
  try {
    // assume it's a string, type errors will be caught by the try/catch
    const metanotes = renderedContent[0]?.value?.metaNotes as string
    return parseMetaNotes(metanotes)
  } catch {
    console.error("Parsing meta notes from expectations table failed.")
    return null
  }
}
