import type { ControlUISchema, UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { JSONFormData } from "@great-expectations/jsonforms-antd-renderers"
import { JsonFormsUISchemaRegistryEntry, UISchemaElement } from "@jsonforms/core"
import { DatasourceTypeV2 } from "src/api/graphql/graphql"
import { FormData } from "src/DataAssets/connect-to-data/JsonForm"
import {
  DatabricksSQLDataSourceJsonSchema,
  DatabricksSQLNoQueryAssetsDataSourceJsonSchema,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/databricks-sql-data-source-json-schema"
import {
  PostgresDataSourceJsonSchema,
  PostgresNoQueryAssetsDataSourceJsonSchema,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/postgres-data-source-json-schema"
import {
  SnowflakeDataSourceJsonSchema,
  SnowflakeNoQueryAssetsDataSourceJsonSchema,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/snowflake-data-source-json-schema"
import { SqlDataSourceJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/sql-data-source-json-schema"
import { SqliteDataSourceJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/sqlite-data-source-json-schema"
import { DatabricksSQLTableAssetUISchemaRegistryEntry } from "src/DataAssets/connect-to-data/schemas/ui-schemas/databricks-sql-data-asset-ui-schema"
import {
  DatabricksSQLDataSourcePage1UISchema,
  DatabricksSQLDataSourcePage2UISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/databricks-sql-data-source-ui-schema"
import {
  PostgresQueryAssetUISchemaRegistryEntry,
  PostgresTableAssetUISchemaRegistryEntry,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/postgres-data-asset-ui-schema"
import {
  PostgresDataSourcePage1UISchema,
  PostgresDataSourcePage2UISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/postgres-data-source-ui-schema"
import {
  SnowflakeQueryAssetUISchemaRegistryEntry,
  SnowflakeTableAssetUISchemaRegistryEntry,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/snowflake-data-asset-ui-schema"
import {
  ConnectionDetailsUISchemaRegistryEntry,
  ConnectionStringUISchemaRegistryEntry,
  SnowflakeDataSourcePage1UISchema,
  SnowflakeDataSourcePage2UISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/snowflake-data-source-ui-schema"
import {
  SqlQueryAssetUISchemaRegistryEntry,
  SqlTableAssetUISchemaRegistryEntry,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/sql-data-asset-ui-schema"
import {
  SqlDataSourcePage1UISchema,
  SqlDataSourcePage2UISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/sql-data-source-ui-schema"
import {
  SqliteQueryAssetUISchemaRegistryEntry,
  SqliteTableAssetUISchemaRegistryEntry,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/sqlite-data-asset-ui-schema"
import {
  SqliteDataSourcePage1UISchema,
  SqliteDataSourcePage2UISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/sqlite-data-source-ui-schema"
import { assertNever } from "src/DataAssets/utils"

export type SupportedDataSource = Extract<
  DatasourceTypeV2,
  "DATABRICKS_SQL" | "SNOWFLAKE" | "SQL" | "POSTGRES" | "SQLITE"
>

// there's a better way to do this but I'm tired
export function isSupportedDataSource(type: DatasourceTypeV2) {
  switch (type) {
    case "DATABRICKS_SQL":
    case "POSTGRES":
    case "SNOWFLAKE":
    case "SQL":
    case "SQLITE":
      return true
    case "PANDAS":
    case "SPARK":
      return false
    default:
      try {
        assertNever(type)
      } catch (e) {
        return false
      }
  }
}

export type SupportedDataSourceJsonMap = {
  [key in SupportedDataSource]: JSONFormData<(typeof SupportedDataSourceToJsonSchemaMap)[key]>
}
export type SupportedDataSourceJson = SupportedDataSourceJsonMap[SupportedDataSource]

const SupportedDataSourceToJsonSchemaMap = {
  DATABRICKS_SQL: DatabricksSQLDataSourceJsonSchema,
  POSTGRES: PostgresDataSourceJsonSchema,
  SNOWFLAKE: SnowflakeDataSourceJsonSchema,
  SQL: SqlDataSourceJsonSchema,
  SQLITE: SqliteDataSourceJsonSchema,
}

export const getDataSourceJsonSchemaMap = () =>
  ({
    DATABRICKS_SQL: DatabricksSQLNoQueryAssetsDataSourceJsonSchema,
    POSTGRES: PostgresNoQueryAssetsDataSourceJsonSchema,
    SNOWFLAKE: SnowflakeNoQueryAssetsDataSourceJsonSchema,
    SQL: SqlDataSourceJsonSchema,
    SQLITE: SqliteDataSourceJsonSchema,
  }) satisfies Record<SupportedDataSource, unknown>

type DataSourceNameToJsonSchemaMap = ReturnType<typeof getDataSourceJsonSchemaMap>

export const DataSourceUISchemaMap: Record<SupportedDataSource, [UISchema<unknown>, UISchema<unknown>]> = {
  DATABRICKS_SQL: [DatabricksSQLDataSourcePage1UISchema, DatabricksSQLDataSourcePage2UISchema],
  POSTGRES: [PostgresDataSourcePage1UISchema, PostgresDataSourcePage2UISchema],
  SNOWFLAKE: [SnowflakeDataSourcePage1UISchema, SnowflakeDataSourcePage2UISchema],
  SQL: [SqlDataSourcePage1UISchema, SqlDataSourcePage2UISchema],
  SQLITE: [SqliteDataSourcePage1UISchema, SqliteDataSourcePage2UISchema],
}

export function getUISchemaRegistryEntries(type: SupportedDataSource) {
  const map: Record<SupportedDataSource, Array<JsonFormsUISchemaRegistryEntry>> = {
    DATABRICKS_SQL: [DatabricksSQLTableAssetUISchemaRegistryEntry(), OneOfNestedInArrayUISchemaRegistryEntry],
    POSTGRES: [
      PostgresTableAssetUISchemaRegistryEntry(),
      PostgresQueryAssetUISchemaRegistryEntry,
      OneOfNestedInArrayUISchemaRegistryEntry,
    ],
    SNOWFLAKE: [
      SnowflakeTableAssetUISchemaRegistryEntry(),
      SnowflakeQueryAssetUISchemaRegistryEntry(),
      ConnectionDetailsUISchemaRegistryEntry,
      ConnectionStringUISchemaRegistryEntry,
      OneOfNestedInArrayUISchemaRegistryEntry,
    ],
    SQL: [
      SqlTableAssetUISchemaRegistryEntry(),
      SqlQueryAssetUISchemaRegistryEntry(),
      OneOfNestedInArrayUISchemaRegistryEntry,
    ],
    SQLITE: [
      SqliteTableAssetUISchemaRegistryEntry,
      SqliteQueryAssetUISchemaRegistryEntry,
      OneOfNestedInArrayUISchemaRegistryEntry,
    ],
  }
  return [...map[type]]
}

type DefaultValues = {
  [K in keyof DataSourceNameToJsonSchemaMap]: FormData<DataSourceNameToJsonSchemaMap[K]>
}

export const defaultValues: DefaultValues = {
  SNOWFLAKE: {
    connection_string: {
      user: "gx_user",
      warehouse: "gx_wh",
      role: "gx_role",
    },
  },
  SQL: {},
  POSTGRES: {},
  SQLITE: {},
  DATABRICKS_SQL: {},
}

export const demoDataSourceConfig = {
  type: "postgres",
  name: "GX demo data",
  connectionString:
    "postgresql+psycopg2://readonly:gxpassword@sample-data-gx-cloud.cwbweqtoi6rb.us-east-1.rds.amazonaws.com/gx_demo_database",
} as const

const OneOfNestedInArrayUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath, path) => {
    const pathElements = path.split(".")
    const length = pathElements.length
    const lastElement = pathElements[length - 1]
    if (typeof Number(lastElement) === "number" && schema.oneOf) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "Control",
    scope: "#",
    options: {
      optionType: "segmented",
      subschemaTitleToLabelMap: { TableAsset: "Table Asset", QueryAsset: "Query Asset" },
    },
  } satisfies ControlUISchema<{ oneOf: [] }> as UISchemaElement,
}
