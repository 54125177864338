import React from "react"
import styled, { css } from "styled-components"
import { theme, Theme } from "src/ui/themes/theme"
import { DownOutlined } from "@ant-design/icons"
import { DescriptionNormal, Heading3 } from "src/ui/typography/Text/Text"

const outerContainerCommonStyles = css`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
    border-radius: ${theme.spacing.cornerRadius.medium};
  `};
`

const Header = styled.header<{
  $isExpanded: boolean
  $background?: keyof Theme["colors"]["neutralColorPalette"]["whites"]
  $minWidth?: string | undefined
  $headerBottomBorder?: boolean
  $titleStrikethrough?: boolean
}>`
  ${outerContainerCommonStyles};
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : "250px")};
  padding: ${({ theme }) => theme.spacing.scale.xxs};
  ${({ $background, theme }) =>
    $background ? { background: theme.colors.neutralColorPalette.whites[$background] } : ""};
  ${({ $isExpanded }) => ($isExpanded ? `border-bottom-left-radius: 0; border-bottom-right-radius: 0;` : "")}
  ${({ $headerBottomBorder, $isExpanded }) => !$headerBottomBorder && $isExpanded && `border-bottom: none;`}
  ${({ $titleStrikethrough }) => ($titleStrikethrough ? `text-decoration: line-through;` : "")}
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ theme }) => css`
    border-radius: ${theme.spacing.cornerRadius.medium} ${theme.spacing.cornerRadius.medium} 0 0;
  `};
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (width <= 490px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: ${({ theme }) => theme.spacing.horizontal.xxs};
  }

  @media screen and (width <= 920px) {
    justify-content: flex-start;
  }

  flex-wrap: nowrap;
`
const BaseFlexContainer = styled.div<{ $gap?: keyof Theme["spacing"]["scale"] }>`
  display: flex;
  ${({ $gap, theme }) => $gap && `gap: ${theme.spacing.scale[$gap]};`};
  align-items: center;
`

const FlexContainer = styled(BaseFlexContainer)`
  justify-content: center;
  flex-wrap: wrap;
`

const Content = styled.div<{
  $contentSpacing: boolean
  $background?: keyof Theme["colors"]["neutralColorPalette"]["whites"]
  $minWidth: string | undefined
}>`
  ${outerContainerCommonStyles};
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : "250px")};
  ${({ $background }) => ($background ? { background: $background } : "")};
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  ${({ $contentSpacing, theme }) =>
    $contentSpacing
      ? `
    padding: 0 ${theme.spacing.scale.xs};
  `
      : null}

  &:empty {
    border: 0;
  }
`

const Title = styled(Heading3)`
  @media screen and (width <= 729px) {
    word-break: break-word;
  }

  display: flex;
  margin-right: ${({ theme }) => theme.spacing.horizontal.xxs};
`

const Btn = styled.button`
  background-color: transparent;
  outline: 0;
  border: 0;
  padding: 0;
`

const HeaderExtraContainer = styled.div`
  flex-basis: 100%;
`

export interface ExpandableContainerProps {
  title: string | React.ReactNode
  headerExtra?: string | React.ReactNode
  label?: string
  subtitle?: string
  actionMenu?: React.ReactNode
  children?: React.ReactNode
  contentSpacing?: boolean
  collapsible?: boolean
  expanded?: boolean
  onToggle?: () => void
  background?: keyof Theme["colors"]["neutralColorPalette"]["whites"]
  headerBottomBorder?: boolean
  titleStrikethrough?: boolean
  headerId?: string
  className?: string
  minWidth?: string
}

function ExpandableContainer(props: ExpandableContainerProps) {
  const {
    title,
    headerExtra = "",
    label = "",
    subtitle,
    children,
    actionMenu,
    collapsible = true,
    contentSpacing = true,
    expanded = false,
    onToggle,
    background,
    headerBottomBorder = true,
    titleStrikethrough = false,
    headerId,
    className,
    minWidth,
  } = props
  const descriptiveTitle = typeof title === "string" ? title : false
  const targetLabel = `Toggle ${descriptiveTitle || label}`
  return (
    <div className={className}>
      <Header
        aria-label={`${descriptiveTitle || label} collapsible header`}
        $background={background}
        $isExpanded={expanded}
        $minWidth={minWidth}
        $headerBottomBorder={headerBottomBorder}
        $titleStrikethrough={titleStrikethrough}
        id={headerId ? encodeURIComponent(headerId) : undefined}
      >
        <TextContainer>
          {collapsible ? (
            <Btn
              title={targetLabel}
              aria-label={targetLabel}
              aria-expanded={expanded}
              onClick={() => {
                if (onToggle) {
                  onToggle()
                }
              }}
            >
              <DownOutlined
                rotate={expanded ? 0 : -90}
                style={{
                  color: theme.colors.neutralColorPalette.blacks.colorTextTertiary,
                  fontSize: "12px",
                  marginRight: theme.spacing.vertical.xxs,
                }}
              />
            </Btn>
          ) : null}
          <BaseFlexContainer $gap="xxxs">
            <Title as="span">
              <Heading3>{title}</Heading3>
            </Title>
            <DescriptionNormal>{subtitle}</DescriptionNormal>
          </BaseFlexContainer>
        </TextContainer>

        <FlexContainer>{actionMenu}</FlexContainer>
        <HeaderExtraContainer> {headerExtra}</HeaderExtraContainer>
      </Header>
      <Content $background={background} $minWidth={minWidth} $contentSpacing={contentSpacing}>
        {children}
      </Content>
    </div>
  )
}

export default ExpandableContainer
