import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Flex, Form, Radio, Space, Input } from "antd"
import { useState } from "react"
import { useAnalytics } from "src/analytics/useAnalytics"
import { AgentSetupMockModal } from "src/agentSetup/AgentSetupMockModal"
import { useTheme } from "styled-components"
import { useOrganizations } from "src/organizations/useOrganizations"
import { RadioGroup } from "src/ui/Radio/RadioGroup.tsx"

interface AgentSetupFeedbackProps {
  onContinue: () => void
  onSubmit: () => void
}

const { TextArea } = Input

const buttonStyle = {
  width: "180px",
}

export function AgentSetupFeedback({ onContinue, onSubmit }: AgentSetupFeedbackProps) {
  const [form] = Form.useForm()
  const theme = useTheme()
  const analytics = useAnalytics()
  const currentOrg = useOrganizations().currentOrg
  const [formValues, setFormValues] = useState({ help: undefined, feedback: undefined })

  const additionalDetailsRequired = formValues.help === "other"
  const submittable = formValues.help && (!additionalDetailsRequired || formValues.feedback)

  const handleFinish = () => {
    if (!submittable) {
      return
    }

    analytics?.capture("agent_setup.feedback_submitted", {
      help: formValues.help,
      feedback: formValues.feedback,
      organization_name: currentOrg?.name,
    })
    onSubmit()
  }
  const modalContent = (
    <Flex align="left" vertical wrap="wrap" style={{ margin: "36px 60px 36px 60px", width: "600px" }}>
      <Flex align="center" vertical wrap="wrap">
        <h2>Please share your feedback</h2>
      </Flex>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        labelAlign="left"
        onChange={() => setFormValues(form.getFieldsValue())}
      >
        <Form.Item name="help" label="How can we help you?" required={true}>
          <RadioGroup>
            <Space direction="vertical">
              <Radio value="troubleshooting">I need help troubleshooting</Radio>
              <Radio value="no_want_agent">I don&apos;t want to use an agent</Radio>
              <Radio value="other">Other</Radio>
            </Space>
          </RadioGroup>
        </Form.Item>

        <Form.Item
          name="feedback"
          label={additionalDetailsRequired ? "Additional details" : "Additional details (optional)"}
          required={additionalDetailsRequired}
        >
          <TextArea rows={3} maxLength={1000} autoSize={{ minRows: 3, maxRows: 10 }} />
        </Form.Item>

        <Flex align="center" vertical wrap="wrap">
          <Form.Item style={{ marginBottom: theme.spacing.vertical.xxs }}>
            <Button type="primary" htmlType="submit" size="large" style={buttonStyle} disabled={!submittable}>
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={<ArrowLeftOutlined />} onClick={onContinue} style={buttonStyle}>
              Back to instructions
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  )

  return <AgentSetupMockModal content={modalContent} />
}
