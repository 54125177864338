/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Drawer } from "src/ui/Drawer/Drawer"
import { Heading2 } from "src/ui/typography/Text/Text"
import { AddDemoDataSourceWithAssets } from "src/DataAssets/connect-to-data/demo-data/AddDemoDataSourceWithAssets"
import { DataSourceCrudDrawerStyles } from "src/DataAssets/connect-to-data/NewAssetDrawer"

interface NewDemoAssetDrawerProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const title = "Add a Data Asset"

export function NewDemoAssetDrawer({ isOpen, setIsOpen }: NewDemoAssetDrawerProps) {
  return (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={<Heading2>{title}</Heading2>}
    >
      <AddDemoDataSourceWithAssets onFinish={() => setIsOpen(false)} />
    </Drawer>
  )
}
