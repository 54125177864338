import { copyToClipboard } from "src/common/utils/clipboard"
import { Button } from "src/ui/Button/Button"

export const ShareButton = () => {
  const url = window.location.href
  const copyUrlToClipboard = () => copyToClipboard(url, "Link copied")

  return (
    <Button icon="link" onClick={copyUrlToClipboard}>
      Share
    </Button>
  )
}
