/*
 * Utils for easily getting to graphiql with a valid token.
 * Replaces some of the manual steps from https://greatexpectations.atlassian.net/wiki/spaces/SUP/pages/393183377/How+to+Onboard+Users+and+Organizations.
 *
 * To access, run these in the dev console when logged into GX.
 *
 * Use cases:
 *  - `graphiql()`
 *  - `graphiql(addOrganization())`
 *  - `graphiql(addOrganization({name: <NAME>, title: <TITLE>}))`
 *  - `graphiql(addUser())`
 *  - `graphiql(addUser({orgId: <B64_ENCODED_ORG_ID>, email: <EMAIL>}))`
 */

import { REACT_APP_API_URL, REACT_APP_APP_URL } from "src/common/env"

type TemplateParameters = {
  dataPlaneUrl: string
}

type Template = (_: Partial<TemplateParameters>) => string
const blankTemplate: Template = () => ""

type AddOrganizationParameters = { orgId: string; name: string; title: string }
export const addOrganization =
  ({
    name = "<SOME NEW ORG NAME>",
    title = "<SOME NEW ORG TITLE>",
  }: Partial<AddOrganizationParameters> = {}): Template =>
  ({ dataPlaneUrl = "<DATA PLANE URL>" }) =>
    encodeURIComponent(`
mutation {
   addOrganization(input: {
     name: "${name}",
     title: "${title}",
     dataPlaneUrl: "${dataPlaneUrl}"
   }) {
     id
     name
     title
     dataPlaneUrl
   }
}
`)

type AddUserParameters = { orgId: string; email: string; role: string }
export const addUser = ({
  orgId,
  email = "<USER EMAIL ADDRESS>",
  role = "ORGANIZATION_ADMIN",
}: Partial<AddUserParameters> = {}): Template => {
  const decodedOrgId = orgId ? atob(orgId).split(":")[1] : "<ID FROM NEW ORG>"

  return () =>
    encodeURIComponent(`
mutation {
  addUserToOrganization(input: 
    {
      email: "${email}",
      organizationId: "${decodedOrgId}",
      organizationRole: ${role}
    }) {
    organizationId
    organizationRole
    isDefaultOrganization
  }
}
`)
}

export const graphiql = (template = blankTemplate) => {
  const appUrl = REACT_APP_APP_URL

  if (!appUrl || !REACT_APP_API_URL) {
    throw Error("Missing environment variables")
  }

  const jwt = localStorage.getItem("auth0Token")
  if (!jwt) {
    throw Error("No JWT found")
  }

  const orgId = localStorage.getItem("currentOrgId")
  const url = `${REACT_APP_API_URL}/organizations/${orgId}/graphql?token=${jwt}`

  const fullUrl = `${url}&query=${template({ dataPlaneUrl: REACT_APP_API_URL })}`

  window.open(fullUrl, "_blank")?.focus()
}
