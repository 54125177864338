import { Checkbox, Flex } from "antd"
import { Icon } from "src/ui/Icon"
import { Heading3 } from "src/ui/typography/Text/Text.tsx"
import { CheckboxChangeEvent } from "antd/es/checkbox"

type SelectAutogenerateExpectationsProps = {
  value: boolean
  setValue: (value: boolean) => void
}

export const SelectAutogenerateExpectations = ({ value, setValue }: SelectAutogenerateExpectationsProps) => {
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setValue(e.target.checked)
  }

  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <Icon name="bolt" />
        <Heading3>Detect schema changes</Heading3>
      </Flex>
      <Checkbox defaultChecked={true} checked={value} onChange={onCheckboxChange}>
        Generate Expectations that detect column changes in selected Data Assets
      </Checkbox>
    </Flex>
  )
}
