import styled, { css } from "styled-components"
import { List, Space, Tag } from "antd"
import { ExpectationGroup } from "src/DataAssets/AssetDetails/Expectations/ExpectationGroup"

export const LeftPanelWrapper = styled(Space)<{ $lg?: boolean }>`
  display: flex;
  width: 100%;
`

export const ListContainer = styled.div<{ $lg?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: ${({ theme }) => theme.spacing.scale.xs};
  padding: ${({ theme }) => theme.spacing.scale.xs};
  background-color: ${(props) => props.theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary};
  border: 1px solid ${(props) => props.theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  border-radius: ${({ theme }) => theme.spacing.scale.xxxs};
`

export const StyledList = styled(List)`
  padding: 0 ${({ theme }) => theme.spacing.scale.xxs};

  &.ant-list-split .ant-list-item {
    border-bottom: none;
  }
`

export const SkeletonContainer = styled.div<{ $column?: boolean }>`
  display: flex;
  ${({ $column }) => $column && `flex-direction: column;`}
  gap: ${({ theme }) => theme.spacing.scale.xxl};
  ${({ theme, $column }) =>
    $column &&
    `flex-direction: column;
    gap: ${theme.spacing.scale.xxs};
  `}
  background-color: ${(props) => props.theme.colors.neutralColorPalette.whites.white};
  padding: ${({ theme }) => theme.spacing.scale.xxs} ${({ theme }) => theme.spacing.scale.xs};
  border: 1px solid ${(props) => props.theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  border-radius: ${({ theme }) => theme.spacing.scale.xxxs};
`

export const StyledTag = styled(Tag)`
  border-radius: ${({ theme }) => theme.spacing.scale.xxs};
`

export const StyledExpectationGroup = styled(ExpectationGroup)<{
  $isSelected?: boolean
}>`
  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      outline: 2px solid ${theme.colors.primaryColors.gxPrimaryLight};
      outline-offset: -2px;
      border-radius: ${theme.spacing.cornerRadius.medium};
    `};
`
