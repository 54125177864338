import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { copyToClipboard } from "src/common/utils/clipboard"
import styled, { useTheme } from "styled-components"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"
import { Alert, Select, Skeleton, Tabs } from "antd"
import { Icon } from "src/ui/Icon"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { GetAllCheckpointsDocument } from "src/api/graphql/graphql-operations"
import { graphql } from "src/api/graphql"
import { exists } from "src/common/utils/typeguards.ts"
import { SplitterOptionsInput } from "src/api/graphql/graphql.ts"
import { CheckpointListOption } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetDrawer.tsx"
import { BodyStrong } from "src/ui/typography/Text/Text"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DropdownContainer = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.vertical.xxs};
`

export const GetRunV1CheckpointSnippetDocument = graphql(`
  query GetRunV1CheckpointSnippet($name: String!, $splitter_options: SplitterOptionsInput) {
    snippets {
      runV1Checkpoint(name: $name, splitter_options: $splitter_options)
    }
  }
`)

const GetRunCheckpointSnippetDocument = graphql(`
  query GetRunCheckpointSnippet(
    $expectationSuiteID: UUID!
    $assetRefID: UUID!
    $checkpointID: UUID
    $splitterOptions: SplitterOptionsInput
  ) {
    snippets {
      runCheckpoint(
        asset_ref_id: $assetRefID
        expectation_suite_id: $expectationSuiteID
        checkpoint_id: $checkpointID
        splitter_options: $splitterOptions
      )
    }
  }
`)

interface ValidateExpectationsModalProps {
  open: boolean
  onCancel: () => void
  onOk: () => void
  expectationSuiteID: string
  assetRefID: string
  splitterOptions?: SplitterOptionsInput
  batchType?: string
  selectedCheckpointFromSplitterDrawer?: CheckpointListOption
}
export function ValidateExpectationsModal({
  open,
  onCancel,
  onOk,
  expectationSuiteID,
  assetRefID,
  splitterOptions,
  batchType,
  selectedCheckpointFromSplitterDrawer,
}: ValidateExpectationsModalProps) {
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<CheckpointListOption | undefined>()
  const defaultTabKey = "0"
  const [activeTab, setActiveTab] = useState(defaultTabKey)
  const theme = useTheme()

  const { data: checkpointData, loading: allCheckpointsQueryLoading } = useQuery(GetAllCheckpointsDocument, {
    variables: {
      expectationSuiteID: expectationSuiteID,
      assetRefID: assetRefID,
    },
    skip: !assetRefID || !expectationSuiteID || exists(selectedCheckpointFromSplitterDrawer),
  })

  const checkpointList: CheckpointListOption[] = useMemo(() => {
    return (
      checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId?.map((item) => ({
        label: item?.name,
        value: item?.id,
      })) ?? []
    )
  }, [checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId])

  const {
    data: datav1,
    error: errorV1,
    loading: loadingV1,
  } = useQuery(GetRunV1CheckpointSnippetDocument, {
    variables: {
      name: selectedCheckpoint?.label || "",
      splitter_options: batchType === "custom" && splitterOptions ? splitterOptions : undefined, // not required
    },
    skip: !open || !selectedCheckpoint?.label,
  })

  const {
    data: data018,
    error: error018,
    loading: loading018,
  } = useQuery(GetRunCheckpointSnippetDocument, {
    variables: {
      expectationSuiteID: expectationSuiteID,
      assetRefID: assetRefID,
      checkpointID: selectedCheckpoint?.value, // not required
      ...(batchType === "custom" && splitterOptions ? { splitterOptions } : undefined), // not required
    },
    skip: !open || !assetRefID || !expectationSuiteID,
  })

  const loadingMsg = "Fetching snippet"
  const codeSnippetV1 = datav1?.snippets?.runV1Checkpoint || undefined
  const codeSnippet018 = data018?.snippets?.runCheckpoint || undefined

  /**
   ** Set selected Checkpoint when list length is 1
   */
  useEffect(() => {
    if (selectedCheckpointFromSplitterDrawer) {
      setSelectedCheckpoint(selectedCheckpointFromSplitterDrawer)
    }
    if (!selectedCheckpoint && checkpointList.length === 1) {
      setSelectedCheckpoint(checkpointList[0])
    }
  }, [checkpointList, selectedCheckpoint, selectedCheckpointFromSplitterDrawer])

  const snippetTabs = [
    {
      key: defaultTabKey,
      label: "GX Core 1.0.x",
      children: <SnippetEditor snippetValue={loadingV1 ? loadingMsg : codeSnippetV1} />,
    },
    {
      key: "1",
      label: "GX Core 0.18.x",
      children: <SnippetEditor snippetValue={loading018 ? loadingMsg : codeSnippet018} />,
    },
  ]

  const handleTabClick = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  const onModalCancel = () => {
    onCancel()
    setSelectedCheckpoint(undefined)
    setActiveTab(defaultTabKey)
  }

  const getSnippetDataForActiveTab = () => {
    if (activeTab === defaultTabKey) {
      return {
        snippet: codeSnippetV1,
        error: errorV1,
      }
    }
    if (activeTab === "1") {
      return {
        snippet: codeSnippet018,
        error: error018,
      }
    }
  }

  const displaySnippet = (
    <>
      <>
        <p>Select your current GX Cloud version and run the following code snippet:</p>
        <Tabs defaultActiveKey={activeTab} items={snippetTabs} onChange={handleTabClick} />
      </>
    </>
  )

  const copySnippetButton = (
    <>
      <StyledCopyButton
        icon="clipboard"
        aria-label="Copy"
        disabled={!exists(getSnippetDataForActiveTab()?.snippet)}
        onClick={() => {
          if (exists(getSnippetDataForActiveTab()?.snippet)) {
            copyToClipboard(getSnippetDataForActiveTab()?.snippet || "")
          } else {
            codeSnippet018 && copyToClipboard(codeSnippet018)
          }
        }}
      >
        Copy snippet
      </StyledCopyButton>
    </>
  )

  const errorMessage = "An error occurred when trying to get the snippet."
  const errorAlert = (
    <>
      {() => {
        if (getSnippetDataForActiveTab()?.error) {
          return <Alert style={{ marginTop: theme.spacing.vertical.xs }} type="error" message={errorMessage} />
        }

        if (error018) {
          return <Alert style={{ marginTop: theme.spacing.vertical.xs }} type="error" message={errorMessage} />
        }
      }}
    </>
  )

  const checkpointDropdown = (
    <DropdownContainer>
      <BodyStrong>
        <BodyStrong $color="gxError">*</BodyStrong> Select a Checkpoint to validate
      </BodyStrong>
      <Select
        placeholder="- Select One -"
        suffixIcon={<Icon name="chevronDown" />}
        defaultValue={undefined}
        style={{ width: 300 }}
        onChange={(value: { value: string; label: string }) => {
          setSelectedCheckpoint(value)
        }}
        options={checkpointList}
        labelInValue
      />
    </DropdownContainer>
  )

  return (
    <AppModal
      open={open}
      title="Validate Expectations"
      width={750}
      onCancel={() => onModalCancel()}
      onOk={() => onOk()}
      cancelText="Done"
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <FlexContainer>
        {Boolean(selectedCheckpoint) && <CodeSnippetAlert />}
        {allCheckpointsQueryLoading && <Skeleton.Input active={true} />}
        {checkpointList.length > 1 && checkpointDropdown}
        {Boolean(selectedCheckpoint) && (
          <>
            {displaySnippet}
            {copySnippetButton}
            {errorAlert}
          </>
        )}
      </FlexContainer>
    </AppModal>
  )
}

const SnippetEditor = ({ snippetValue }: { snippetValue?: string }) => (
  <CodeSnippetEditor
    value={!snippetValue ? "Validate code snippet not found" : snippetValue}
    language="python"
    readOnly
    fontSize={12}
    showLineNumbers={false}
    height="250px"
  />
)
