import { Col, Grid, Row } from "antd"
import styled, { css } from "styled-components"

interface ListAndFilterLayoutProps extends React.PropsWithChildren<{ filter: React.ReactNode }> {
  // stickyFilter determines whether the filter should stop when scrolling vertically
  stickyFilter?: boolean
  // stickyOffset is the number of pixels from the top of the page where the sticky filter should stop when scrolling
  stickyOffset?: number
}

const ContainerRow = styled(Row)`
  row-gap: 20px;
  width: 100%;
  height: 100%;
`

const LeftCol = styled(Col).attrs(() => ({ lg: { span: 6 }, xs: { span: 24 } }))<{
  $sticky: boolean
  $stickyOffset: number
}>`
  ${({ $sticky, $stickyOffset }) =>
    $sticky
      ? css`
          position: sticky;
          left: 0;

          /* required within flex https://stackoverflow.com/questions/44446671/my-position-sticky-element-isnt-sticky-when-using-flexbox */
          align-self: flex-start;

          /* TODO revisit - try to programatically determine this value instead of relying on a prop */
          top: ${$stickyOffset}px;
        `
      : ""};
`
const RightCol = styled(Col).attrs(() => ({ lg: { span: 18 }, xs: { span: 24 } }))<{ $lg: boolean }>`
  ${({ theme, $lg }) =>
    $lg
      ? css`
          padding-left: ${theme.spacing.horizontal.s};
        `
      : ""};
`

export function ListAndFilterLayout({
  filter,
  children,
  stickyFilter = true,
  stickyOffset = 120,
}: ListAndFilterLayoutProps) {
  const { lg } = Grid.useBreakpoint()
  return (
    <ContainerRow>
      <LeftCol $sticky={stickyFilter && Boolean(lg)} $stickyOffset={stickyOffset}>
        {filter}
      </LeftCol>
      <RightCol $lg={Boolean(lg)}>{children}</RightCol>
    </ContainerRow>
  )
}
