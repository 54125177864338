import { Dropdown } from "antd"
import styled from "styled-components"

export const BifoldButton = styled(Dropdown.Button)`
  button {
    box-shadow: none;

    span {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  button:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  button:last-child::before {
    background-color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white} !important;
  }
`
