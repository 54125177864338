import { useFlags } from "launchdarkly-react-client-sdk"

// this hook should be used for feature flags that have variations - data that is added to the FF.
// for example: maintenanceBannerEnabled flag that has date of maintenance completions as {data: timestamp}

export type FeatureFlag = "maintenanceEnabled" | "maintenanceBannerEnabled"

type MaintenanceVariations = { startTime: string; endTime: string }
export interface FeatureFlagVariationsTypes {
  enabled: boolean
  // data types for new feature flags with variations should be added here
  data: MaintenanceVariations
}
export function useGetFeatureFlagVariations(flagName: FeatureFlag): FeatureFlagVariationsTypes {
  const flags = useFlags()
  return flags?.[flagName]
}
