import React from "react"
import { Checkbox, Col, Form, Row } from "antd"
import { StyledCol, StyledInput } from "src/ui/OnboardingSurvey/styles"
import { ColProps } from "antd/lib/col"

interface DataSourceItemProps {
  label: string
  value: string
  colProps?: ColProps
}

interface DataSourceItemsProps {
  showOther: boolean
}

const dataSourceOptions: DataSourceItemProps[] = [
  { label: "Snowflake", value: "Snowflake" },
  { label: "PostgreSQL", value: "PostgreSQL" },
  { label: "Google BigQuery", value: "Google BigQuery" },
  { label: "Databricks SQL", value: "Databricks SQL" },
  { label: "Databricks Spark", value: "Databricks Spark" },
  { label: "Amazon Redshift", value: "Amazon Redshift" },
  { label: "Other", value: "Other", colProps: { span: 4 } },
]

const DataSourceCheckboxItem: React.FC<DataSourceItemProps> = ({ label, value, colProps }) => (
  <StyledCol span={12} {...colProps}>
    <Checkbox value={value}>{label}</Checkbox>
  </StyledCol>
)

export const DataSourceItems: React.FC<DataSourceItemsProps> = ({ showOther }) => {
  return (
    <Row justify="start">
      {dataSourceOptions.map((option) => (
        <DataSourceCheckboxItem key={option.value} {...option} />
      ))}
      {showOther && (
        <Col style={{ marginTop: "2px" }} span={10}>
          <Form.Item
            style={{ height: "0px" }}
            name="otherDatasourceType"
            rules={[{ required: showOther, message: "Data Source is required" }]}
          >
            <StyledInput autoComplete="off" aria-label="Datasource input" />
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}
