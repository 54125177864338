import { Alert, Space, AlertProps } from "antd"
import { useEffect, useRef } from "react"

export const AlertBanner = ({ message, description, type }: AlertProps) => {
  const alertRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <Space ref={alertRef} direction="vertical" style={{ width: "100%", margin: "24px 0" }} size="large">
      <Alert
        style={{ borderWidth: 0, padding: "12px" }}
        message={message}
        description={description}
        type={type ?? "error"}
        showIcon
      />
    </Space>
  )
}
