import { Dropdown, DropDownProps, MenuProps } from "antd"
import React, { useRef } from "react"
import { Button, ButtonProps } from "src/ui/Button/Button"
import { Icon, IconTypes } from "src/ui/Icon"

import styled, { css } from "styled-components"
import { DropdownProps } from "src/ui/types"
import { ItemType } from "antd/lib/menu/hooks/useItems"

const commmonDropdownStyle = css`
  max-width: 320px;
`

const StyledDropdown = styled(Dropdown)`
  ${commmonDropdownStyle}
`
const StyledDropdownButton = styled(Dropdown.Button)`
  ${commmonDropdownStyle}
`

const IconContainer = styled.div`
  padding-top: 3px;
`

interface DropComponentSwitchProps extends DropDownProps {
  showButton?: boolean
  iconButton?: React.ReactNode
  children: React.ReactNode
  label?: string
}

const DropdownComponentSwitch = ({
  showButton = false,
  children,
  iconButton,
  getPopupContainer,
  label,
  ...props
}: DropComponentSwitchProps) =>
  showButton ? (
    <StyledDropdownButton getPopupContainer={getPopupContainer} icon={iconButton} aria-label={label} {...props}>
      {children}
    </StyledDropdownButton>
  ) : (
    <StyledDropdown getPopupContainer={getPopupContainer} {...props}>
      {children}
    </StyledDropdown>
  )

interface DropdownButtonProps extends Partial<DropdownProps> {
  disabled?: boolean
  ariaLabel?: string
  buttonConfig: Partial<ButtonProps> & {
    title?: string
    showDropdownButton?: boolean
    dropdownButtonIcon?: IconTypes
  }
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}
function DropdownButton({
  dropdownList,
  disabledValues,
  buttonConfig,
  disabled,
  ariaLabel = "dropdown selection",
  ...props
}: DropdownButtonProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const baseButton = (
    <Button type="default" aria-label={ariaLabel} {...buttonConfig} disabled={disabled}>
      {buttonConfig.title}
    </Button>
  )
  if (!dropdownList) return baseButton

  const getItems = (): ItemType[] => {
    return dropdownList.map((dropdownOption) => {
      return {
        danger: !dropdownOption.isDisabled && dropdownOption.danger,
        disabled: dropdownOption.isDisabled,
        key: dropdownOption.key,
        label: dropdownOption.value,
      } satisfies ItemType
    })
  }

  const onClick: MenuProps["onClick"] = (item) => {
    const selectedItem = dropdownList.find((listItem) => listItem.key === item?.key)
    selectedItem?.onSelect?.()
  }

  return (
    <DropdownComponentSwitch
      aria-label={ariaLabel}
      showButton={buttonConfig.showDropdownButton}
      trigger={["click"]}
      iconButton={
        buttonConfig.dropdownButtonIcon && (
          <IconContainer ref={ref}>
            <Icon size="14px" name={buttonConfig.dropdownButtonIcon} />
          </IconContainer>
        )
      }
      getPopupContainer={() => ref.current as HTMLElement}
      {...props}
      menu={{ items: getItems(), onClick: onClick }}
      placement="bottom"
      disabled={disabled}
    >
      {buttonConfig.showDropdownButton ? buttonConfig.title : <div ref={ref}>{baseButton}</div>}
    </DropdownComponentSwitch>
  )
}

export { DropdownButton }
