/* eslint-disable react-refresh/only-export-components */ // FIXME
import { withJsonFormsControlProps } from "@jsonforms/react"
import { ControlProps, JsonFormsRendererRegistryEntry } from "@jsonforms/core"
import { Form, Select } from "antd"
import { Metric } from "src/api/graphql/graphql"
import { useEffect, useMemo } from "react"

export const BatchDefinitionColumnNameRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: (uischema) => {
    if (!("scope" in uischema)) {
      return -1
    }
    if (uischema?.scope === "#/properties/column_name") {
      return 10
    }
    return -1
  },
  renderer: withJsonFormsControlProps(BatchDefinitionColumnNameControl),
}

export function BatchDefinitionColumnNameControl({ handleChange, config, path, data, required }: ControlProps) {
  const options = useMemo(
    () =>
      config.dataAssetWithLatestMetricRun?.latestMetricRun?.metrics.map((metric: Metric) => {
        return {
          value: metric.columnName,
          label: metric.columnName,
        }
      }),
    [config],
  )

  const form = config.form

  // using this mostly to set the initial value https://github.com/ant-design/ant-design/issues/22372
  useEffect(() => {
    form.setFieldsValue({ [path]: data })
  }, [data, path, form])

  const label = "Batch column"
  return (
    <Form.Item
      name={path}
      label={label}
      rules={[
        {
          required: required,
          message: "Column name is required",
        },
      ]}
      validateTrigger="onBlur"
    >
      {options && (
        <Select
          placeholder="Select a DATE or DATETIME column"
          onChange={(option) => {
            handleChange(path, option)
          }}
          options={options}
          filterOption={(inputValue, option) => {
            const optionValue = option?.value?.toString() || ""
            return optionValue.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }}
        />
      )}
    </Form.Item>
  )
}
