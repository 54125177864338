import { ReactNode } from "react"
import { IconTypes } from "src/ui/Icon"
import { BreadcrumbProps } from "src/ui/AppBreadcrumb/AppBreadcrumb"
import { ButtonProps } from "src/ui/Button/Button"

export interface RoutesTypes {
  path: string
  displayName: string
}

export enum ValidationStatus {
  passed = "passed",
  failed = "failed",
}

type IconScoutButtonsOnly = Omit<ButtonProps, "icon"> & { icon?: IconTypes }

type RightActions =
  | {
      ctaButton?: IconScoutButtonsOnly
      iconButtons?: IconScoutButtonsOnly[]
      secondaryButton?: IconScoutButtonsOnly
    }
  | { reactNode: ReactNode }

export interface HeaderTypes {
  title: string | JSX.Element
  subtitle?: string
  rootPath?: string
  titleIcon?: ReactNode
  description?: string
  validationStatus?: ValidationStatus
  rightActions?: RightActions
  backIcon?: IconTypes
  noBreadCrumb?: boolean
  customBreadcrumbs?: BreadcrumbProps["items"]
  footer?: ReactNode
  navigateBackTo?: string
}
