import { useQuery } from "@apollo/client"
import { GetDataSourceIdsDocument } from "src/api/graphql/graphql-operations"
import { REACT_APP_DATA_SOURCE_IDS_POLL_INTERVAL } from "src/common/env"

// Check if Data Sources exist for this organization
export function PollForDataSourceIds() {
  useQuery(GetDataSourceIdsDocument, {
    pollInterval: REACT_APP_DATA_SOURCE_IDS_POLL_INTERVAL,
    canonizeResults: true,
    fetchPolicy: "network-only",
  })
  return null
}
