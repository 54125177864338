/* eslint-disable react-refresh/only-export-components */ // FIXME
import {
  Modal, // eslint-disable-line no-restricted-imports
  ModalFuncProps,
  ModalProps,
} from "antd"
import { Heading1 } from "src/ui/typography/Text/Text"
import styled from "styled-components"
import { Button } from "src/ui/Button/Button"
import { CloseOutlined } from "@ant-design/icons"

// NOTE: any new props introduced here are going to receive scrutiny; be prepared to justify their worth.
export type AppModalProps = React.PropsWithChildren<ModalProps>

// TODO this does not belong in this file.
export const StyledCopyButton = styled(Button)`
  margin-top: ${({ theme }) => `${theme.spacing.vertical.xs}`};
  margin-right: 0;
  margin-left: auto;
`

export const AppModalButtonProps = {
  style: {
    boxShadow: "none",
  },
} satisfies AppModalProps["okButtonProps"]

export const AppModalConfig: ModalFuncProps = {
  centered: true,
  icon: null,
  closable: true,
  closeIcon: <CloseOutlined />,
}

// AppModal is a thin wrapper that provides some sensible defaults.
export function AppModal(props: AppModalProps) {
  const { title, visible, okButtonProps, cancelButtonProps, ...rest } = props

  return (
    // NOTE: this prevents click events from bubbling to the parent element which is default behavior for modals.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
      role="presentation"
    >
      <Modal
        cancelButtonProps={{
          ...cancelButtonProps,
          ...AppModalButtonProps,
          style: {
            ...cancelButtonProps?.style,
            ...AppModalButtonProps.style,
          },
        }}
        okButtonProps={{
          ...okButtonProps,
          ...AppModalButtonProps,
          style: {
            ...okButtonProps?.style,
            ...AppModalButtonProps.style,
          },
        }}
        destroyOnClose
        title={typeof title === "string" ? <Heading1>{title}</Heading1> : title}
        open={visible} // avoid deprecated prop warning
        {...rest}
      />
    </div>
  )
}

export const FormModal = styled(AppModal)`
  .ant-modal-content {
    padding: 0;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  .ant-modal-header {
    padding: ${({ theme }) => theme.spacing.vertical.xs} ${({ theme }) => theme.spacing.horizontal.s};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  }
  .ant-modal-body {
    padding: ${({ theme }) => theme.spacing.vertical.xs} ${({ theme }) => theme.spacing.horizontal.s};
  }
  .ant-modal-footer {
    padding: ${({ theme }) => theme.spacing.vertical.xs} ${({ theme }) => theme.spacing.horizontal.s};
    border-top: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  }
`
