import { DatasourceTypeV2 } from "src/api/graphql/graphql"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { isAssetEditable } from "src/DataAssets/connect-to-data/schemas/data-asset-schemas"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"

export function useIsAssetEditable(dataSourceType: DatasourceTypeV2, assetType: string) {
  const isUserEditorOrHigher = useRequireRole("EDITOR")
  const isSqlSupportEnabled = useIsFeatureEnabled("genericSqlDataSourceSupport")
  const isPostgresSupportEnabled = useIsFeatureEnabled("postgreSqlDataSourceSupport")
  const isSqliteSupportEnabled = useIsFeatureEnabled("sqliteDataSourceSupport")
  const isDatabricksSupportEnabled = useIsFeatureEnabled("databricksDataSourceSupport")
  if (!isUserEditorOrHigher) {
    return false
  }
  if (dataSourceType === "SQL") {
    return isSqlSupportEnabled
  }
  if (dataSourceType === "POSTGRES") {
    return isPostgresSupportEnabled
  }
  if (dataSourceType === "SQLITE") {
    return isSqliteSupportEnabled
  }
  if (dataSourceType === "DATABRICKS_SQL") {
    return isDatabricksSupportEnabled
  }
  return isAssetEditable(dataSourceType as SupportedDataSource, assetType)
}
