import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"

type ExpectationJsonSchemaObject = typeof jsonSchemas
type ExpectationsWithoutCategory = {
  [P in keyof ExpectationJsonSchemaObject]: ExpectationJsonSchemaObject[P]["schema"]["properties"]["metadata"]["properties"] extends Readonly<{
    data_quality_issues: infer U
  }>
    ? U extends Readonly<{ const: ReadonlyArray<never> }>
      ? P
      : never
    : P
}[keyof ExpectationJsonSchemaObject]

type ExpectationJsonSchemasWithCategories = Omit<typeof jsonSchemas, ExpectationsWithoutCategory>

export type ExpectationCategory = {
  [P in keyof ExpectationJsonSchemasWithCategories]: ExpectationJsonSchemasWithCategories[P]["schema"]["properties"]["metadata"]["properties"]["data_quality_issues"]["const"][0]
}[keyof ExpectationJsonSchemasWithCategories]

export const ExpectationCategories = [
  "Cardinality",
  "Data integrity",
  "Distribution",
  "Missingness",
  "Numerical data",
  "Pattern matching",
  "Schema",
  "Sets",
  "Volume",
] as const satisfies ExpectationCategory[]

type MissingCategory =
  Exclude<ExpectationCategory, (typeof ExpectationCategories)[number]> extends never
    ? never
    : true | Exclude<ExpectationCategory, (typeof ExpectationCategories)[number]>

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-expect-error make sure our array has all the categories in it
const missingCategory: MissingCategory = true
/* eslint-enable @typescript-eslint/no-unused-vars */
