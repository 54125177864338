type JSONString = string
import getAuth0Config from "src/authentication/getAuth0Config"

// this is probably brittle since we're relying on client internals but it
// should fix the immediate issue and we can determine a more robust solution later
function isDecodedToken(clientId: string, item: [string, unknown]): boolean {
  const KEY_PREFIX = "@@auth0spajs@@::" + clientId
  const KEY_ID_TOKEN_SUFFIX = "::@@user@@"
  // storage items are tuples with first element serving as a key and second as value
  const key = item[0]

  // auth0 stores 2 items in local storage, the ID token, and the decoded token
  // we're looking for the decoded token, NOT the ID token
  return key.startsWith(KEY_PREFIX) && !key.endsWith(KEY_ID_TOKEN_SUFFIX)
}

// getAuth0RefreshToken returns the auth0 refresh token from local storage
// if it exists, otherwise it returns an empty string
export function getAuth0RefreshToken(storage = localStorage): string {
  // getting auth0 token with clientId for more specificity
  const { clientId } = getAuth0Config()
  const storageItems = Object.entries(storage).filter((item) => isDecodedToken(clientId, item))
  try {
    if (storageItems.length === 0) {
      throw new Error("refresh token not found")
    }
    const storageValue: JSONString = storageItems[0][1]

    return JSON.parse(storageValue)["body"]["refresh_token"]
  } catch (error) {
    console.error("failed to find refresh token", error)
    return ""
  }
}
