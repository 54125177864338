import { Flex, Form, Input, Layout, message, Select, Switch, Tooltip } from "antd"
import { ApolloError, useMutation } from "@apollo/client"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  CreateDefaultCheckpointDocument,
  CreateExpectationSuiteDocument,
  CreateScheduleDocument,
} from "src/api/graphql/graphql-operations"
import { Content, Footer } from "antd/es/layout/layout"
import { Drawer } from "src/ui/Drawer/Drawer"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { theme } from "src/ui/themes/theme"
import { Icon } from "src/ui/Icon"
import {
  DEFAULT_SCHEDULE_FREQUENCY,
  generateCronWithCyclicHours,
  getHourlyIntervalFromCron,
  getNextHour,
  getNextRunFromCron,
} from "src/common/utils/cron"
import { formatLocalCalendarDateWithTime } from "src/common/utils/formatTime"
import { Schedule, UpdateExpectationSuiteNameDocument, UpdateScheduleDocument } from "src/api/graphql/graphql"
import { useParams } from "react-router-dom"
import { AlertInfo } from "src/ui/Alert"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import {
  CREATE_EXPECTATION_SUITE_MANUAL,
  EDITOR_DRAWER_TITLE_CREATE,
  EDITOR_DRAWER_TITLE_EDIT,
} from "src/DataAssets/AssetDetails/Expectations/words"
import { ExpectationSuiteDrawerAlert } from "src/DataAssets/AssetDetails/Expectations/ExpectationSuiteDrawerAlert"
import { Heading3 } from "src/ui/typography/Text/Text"

interface ExpectationSuiteDrawerProps {
  dataAssetId?: string
  dataAssetName?: string
  isVisible: boolean
  onClose: (expectationSuiteId: string | undefined) => void
  editable?: boolean
  schedule?: Schedule
  suiteTitle?: string
  setIsEnabled?: (checked: boolean) => void
}

// if `editable` is true, require `suiteTitle` and `setIsEnabled`
type RequireSuiteIdOnEdit<T extends ExpectationSuiteDrawerProps> = T extends { editable: boolean }
  ? T & { schedule?: Schedule; suiteTitle: string; setIsEnabled: (checked: boolean) => void }
  : T

// if `schedule` is missing, `editable` must be false
// therefore, require `dataAssetId` and `dataAssetName`
type RequireDataAssetOnCreate<T extends ExpectationSuiteDrawerProps> = T extends { schedule?: Schedule }
  ? T
  : T & { dataAssetId: string; dataAssetName: string }

type CompoundExpectationSuiteDrawerProps<T extends ExpectationSuiteDrawerProps> = RequireSuiteIdOnEdit<
  RequireDataAssetOnCreate<T>
>

interface FooterProps {
  onSave: () => void
  isLoading?: boolean
  edit: boolean
  disabled: boolean
}

interface GenerateSuiteForm {
  expectationSuiteName: string
  scheduledStart: number
  scheduledFrequency: number
  isEnabled: boolean
}

type SelectOption = {
  value: number
  label: string
}

function GenerateExpectationSuiteFooter({ onSave, isLoading, edit, disabled }: FooterProps) {
  return (
    <Drawer.Footer>
      <Drawer.FooterButton type="primary" onClick={onSave} loading={isLoading} disabled={disabled}>
        {edit ? "Save" : "Create"}
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}

export const frequencyOptions: SelectOption[] = [
  { value: 1, label: "Every 1 hour" },
  { value: 3, label: "Every 3 hours" },
  { value: 6, label: "Every 6 hours" },
  { value: 12, label: "Every 12 hours" },
  { value: 24, label: "Every 24 hours" },
]

// generate an array of value/label objects in format
// { value: 3, label: "03:00" }
// left-pad hour labels less than 10 to maintain 24-hr formatting
export const startTimeOptions = Array.from({ length: 24 }, (_, i) => i).reduce(
  (acc, v) => [...acc, { value: v, label: `${v < 10 ? "0" : ""}${v}:00` }],
  [] as SelectOption[],
)

export function ExpectationSuiteDrawer<T extends ExpectationSuiteDrawerProps>({
  editable,
  dataAssetId,
  dataAssetName,
  isVisible,
  onClose,
  suiteTitle,
  schedule,
  setIsEnabled,
}: CompoundExpectationSuiteDrawerProps<T>) {
  const [createSuiteForm] = Form.useForm<GenerateSuiteForm>()
  const suiteName = Form.useWatch("expectationSuiteName", createSuiteForm)
  const scheduledStart = Form.useWatch("scheduledStart", createSuiteForm)
  const scheduledFrequency = Form.useWatch("scheduledFrequency", createSuiteForm)
  const isEnabled = Form.useWatch("isEnabled", createSuiteForm)
  const { expectationSuiteId: expectationSuiteIdParam = "" } = useParams<{ expectationSuiteId?: string }>()
  const isEditorRole = useRequireRole("EDITOR")

  const [jobId, setJobId] = useState<string | undefined>()
  const [mutationError, setMutationError] = useState<ApolloError | undefined>()

  const [expectationSuiteId, setExpectationSuiteId] = useState<string | undefined>()

  const [loading, setLoading] = useState(false)

  const defaultSuiteName = `${dataAssetName} Suite`

  const shouldEnable = useMemo(() => {
    if (schedule?.id) {
      return schedule.isEnabled
    }

    if (!editable && !schedule?.id) {
      return false
    }
  }, [editable, schedule])

  // sync state between parent component & drawer's internal form
  useEffect(() => {
    if (schedule?.id) {
      createSuiteForm.setFieldsValue({ isEnabled: schedule?.isEnabled })
    }
  }, [createSuiteForm, schedule])

  const nextHour = useMemo(() => {
    if (editable && schedule?.startTime) {
      return schedule.startTime
    }
    return startTimeOptions.find((v) => v.value === getNextHour(new Date().getHours()))?.value
  }, [editable, schedule])

  const selectedFrequencyOption = useMemo(() => {
    if (editable && schedule?.schedule) {
      return getHourlyIntervalFromCron(schedule.schedule)
    }
    return DEFAULT_SCHEDULE_FREQUENCY
  }, [editable, schedule])

  const cronExpression = useMemo(() => {
    return generateCronWithCyclicHours({ start: scheduledStart, freq: scheduledFrequency })
  }, [scheduledStart, scheduledFrequency])

  const nextRun = useMemo(() => getNextRunFromCron(cronExpression), [cronExpression])

  // Mutation hooks
  const [createExpectationSuite, { reset: resetCreateExpectationSuiteMutation }] = useMutation(
    CreateExpectationSuiteDocument,
    {
      onCompleted: (data) => {
        if (data.createExpectationSuite.id && dataAssetId) {
          setExpectationSuiteId(data.createExpectationSuite.id)
          createDefaultCheckpoint({
            variables: { expectationSuiteId: data.createExpectationSuite.id, assetId: dataAssetId },
          })
        }
      },
      onError: (error: ApolloError) => {
        setMutationError(error)
      },
    },
  )

  const [editExpectationSuite] = useMutation(UpdateExpectationSuiteNameDocument, {
    onCompleted: (data) => {
      if (data.updateExpectationSuite?.expectationSuite.name && schedule && editable) {
        updateSchedule({
          variables: {
            id: schedule.id,
            schedule: cronExpression,
            startTime: scheduledStart,
          },
        })
        setIsEnabled && setIsEnabled(isEnabled)
      }
    },
    onError(error) {
      setMutationError(error)
      setLoading(false)
    },
  })

  const [createDefaultCheckpoint, { reset: resetDefaultCheckpointMutation }] = useMutation(
    CreateDefaultCheckpointDocument,
    {
      onCompleted: (data) => {
        const checkpointId = data.createDefaultCheckpoint?.checkpoint.id
        if (checkpointId) {
          createCheckpointJobSchedule({
            variables: {
              input: {
                schedule: cronExpression,
                isEnabled: isEnabled,
                startTime: scheduledStart,
                sourceResources: [{ entityId: checkpointId, entityType: "Checkpoint" }],
              },
            },
          })
        }
      },
      onError: (error: ApolloError) => {
        setMutationError(error)
      },
    },
  )

  const [createCheckpointJobSchedule, { reset: resetCheckpointJobScheduleMutation }] = useMutation(
    CreateScheduleDocument,
    {
      onCompleted: () => {
        message.success(CREATE_EXPECTATION_SUITE_MANUAL, MESSAGE_DURATION_SECONDS)
        closeDrawer(expectationSuiteId)
      },
      onError: (error: ApolloError) => {
        setMutationError(error)
        setLoading(false)
      },
    },
  )

  const [updateSchedule] = useMutation(UpdateScheduleDocument, {
    onCompleted: () => {
      closeDrawer(expectationSuiteId)
    },
    onError: (error) => {
      setMutationError(error)
      setLoading(false)
    },
  })

  const handleCloseDrawer = () => {
    // This is from a user clicking the X button
    onClose(undefined)
    resetDrawer()
  }

  const closeDrawer = (expectationSuiteId: string | undefined) => {
    // This is from the job completing and is called within the alert
    onClose(expectationSuiteId)
    resetDrawer()
  }

  const resetDrawer = () => {
    resetCreateExpectationSuiteMutation()
    resetDefaultCheckpointMutation()
    resetCheckpointJobScheduleMutation()

    setJobId(undefined)
    setLoading(false)
    setMutationError(undefined)
  }

  const handleFormSubmit = useCallback(() => {
    const values = createSuiteForm.getFieldsValue()
    if (editable && expectationSuiteIdParam) {
      editExpectationSuite({
        variables: {
          input: {
            id: expectationSuiteIdParam,
            name: suiteName,
          },
        },
      })
    } else {
      createExpectationSuite({
        variables: { expectationSuiteName: values.expectationSuiteName || defaultSuiteName },
      })
    }
  }, [
    createExpectationSuite,
    createSuiteForm,
    defaultSuiteName,
    editExpectationSuite,
    editable,
    expectationSuiteIdParam,
    suiteName,
  ])

  const handleSave = () => {
    // Clear jobId so that we aren't monitoring the wrong job
    // Otherwise Alert would continue to setLoading based on the old jobId
    // Until the new jobId is set (aka after the graphQL mutation completes)
    setJobId(undefined)
    setLoading(true)
    handleFormSubmit()
  }

  const suiteCreation = (
    <Form
      key="manual"
      layout="vertical"
      form={createSuiteForm}
      disabled={loading || !isEditorRole}
      onKeyUp={(e) => e.key === "Enter" && handleSave()}
      onFinish={() => handleSave()}
      initialValues={{
        expectationSuiteName: suiteTitle ?? defaultSuiteName,
        scheduledStart: nextHour,
        scheduledFrequency: selectedFrequencyOption,
        isEnabled: shouldEnable,
      }}
    >
      <Form.Item name="expectationSuiteName" label="Expectation Suite name" required>
        <Input />
      </Form.Item>

      <div style={{ marginTop: theme.spacing.vertical.l }}>
        <Flex justify="space-between" align="start">
          <Flex align="center">
            <Icon
              name="schedule"
              height={theme.spacing.scale.xxs}
              width={theme.spacing.scale.xxs}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: theme.spacing.horizontal.xxs,
              }}
            />
            <Heading3>Validation schedule</Heading3>
          </Flex>
          <Form.Item name="isEnabled" valuePropName="checked">
            {!isEnabled && !editable ? (
              <Tooltip title="Add an Expectation to the Expectation Suite to activate the schedule.">
                <Switch checkedChildren="ON" unCheckedChildren="OFF" disabled />
              </Tooltip>
            ) : (
              <Switch checkedChildren="ON" unCheckedChildren="OFF" />
            )}
          </Form.Item>
        </Flex>
        <Flex justify="space-between" gap="large">
          <Flex style={{ width: "50%" }} vertical>
            <Form.Item name="scheduledFrequency" label="Frequency" required>
              <Select
                options={frequencyOptions}
                defaultValue={frequencyOptions[frequencyOptions.length - 1]}
                disabled={!editable}
              />
            </Form.Item>
          </Flex>
          <Flex style={{ width: "50%" }} vertical>
            <Form.Item name="scheduledStart" label="Start time (local timezone)" required>
              <Select options={startTimeOptions} defaultValue={nextHour} disabled={!editable} />
            </Form.Item>
          </Flex>
        </Flex>
        {isEnabled && (
          <AlertInfo
            message={`Next run ${formatLocalCalendarDateWithTime(nextRun.toISOString())}`}
            tooltip="Due to the cyclic nature of schedule definitions, the next run might be sooner than the given start time."
          />
        )}
      </div>
    </Form>
  )

  return (
    <Drawer
      title={editable ? EDITOR_DRAWER_TITLE_EDIT : EDITOR_DRAWER_TITLE_CREATE}
      onClose={handleCloseDrawer}
      open={isVisible}
      footer={
        <GenerateExpectationSuiteFooter
          onSave={handleSave}
          isLoading={loading}
          edit={Boolean(editable)}
          disabled={!isEditorRole}
        />
      }
    >
      <Layout style={{ height: "100%" }}>
        <Content>{suiteCreation}</Content>
        <Footer style={{ width: "100%", padding: 0 }}>
          <ExpectationSuiteDrawerAlert
            key={jobId}
            jobId={jobId}
            mutationError={mutationError}
            setIsJobRunning={setLoading}
            closeDrawer={closeDrawer}
          />
        </Footer>
      </Layout>
    </Drawer>
  )
}
