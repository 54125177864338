import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom"

// these are the types supported values for url serialization / deserialization
export type Value = string | string[] | number | boolean

// sanitizeUrlParam serializes a value to be ready to store in a URL.
// If a value cannot or should not be stored, an empty string is returned.
export function sanitizeUrlParam(value: Value): string | string[] {
  if (Array.isArray(value)) {
    const filtered = value.map((v) => v.trim()).filter(Boolean)
    if (filtered.length === 0) return ""
    return filtered
  }

  switch (typeof value) {
    case "string":
      return value.trim()
    case "boolean":
      if (value === true) return "1"
      return ""
    case "number":
      return value.toString()
    default:
      return ""
  }
}

// getParamValues converts each URL search param into the
// desired type based on its respective default value.
export function getParamValues<T extends Record<string, Value>>(
  location: Location,
  params: T, // <name, default> pairs
): T {
  const result = {} as Record<string, Value>
  const query = new URLSearchParams(location.search)

  for (const [name, defaultVal] of Object.entries(params)) {
    if (!query.has(name)) {
      result[name] = defaultVal
    } else if (Array.isArray(defaultVal)) {
      result[name] = query.getAll(name)
    } else if (typeof defaultVal === "boolean") {
      result[name] = query.get(name) === "1"
    } else if (typeof defaultVal === "string") {
      result[name] = query.get(name) || ""
    } else if (typeof defaultVal === "number") {
      result[name] = +(query.get(name) as string)
    } else {
      result[name] = defaultVal
    }
  }
  return result as T
}

// setUrlParams will replace the latest browser history entry with the provided params.
function setUrlParams(navigate: NavigateFunction, location: Location, params: URLSearchParams): void {
  if (params.sort) params.sort()
  let newSearch = params.toString()
  newSearch = newSearch ? "?" + newSearch : ""

  navigate(location.pathname + newSearch + location.hash)
}

// useUrlParams returns the values for the given URL params if present, else the given defaults.
// It also returns a setter function that, when called, updates the URL param values.
// The native history stack will not push a new entry; instead, its
// latest entry will be replaced.
export function useUrlParams<T extends Record<string, Value>>(
  params: T, // <name, default> pairs
): [T, (newValues: Partial<T>) => void] {
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)
  let called = false

  function setParams(newParams: Partial<T>): void {
    if (called) {
      // TODO we could batch updates to avoid this issue
      console.error("useUrlParams: setParams was called multiple times in one render, aborting")
      return
    }
    called = true

    for (const [name, _v] of Object.entries(newParams)) {
      const value = name === "search" ? (_v as string) : sanitizeUrlParam(_v)

      query.delete(name)

      if (Array.isArray(value)) {
        value.forEach((v) => query.append(name, v))
      } else if (value) {
        query.set(name, value)
      }
    }

    setUrlParams(navigate, location, query)
  }

  const values = getParamValues<T>(location, params)

  return [values, setParams]
}

// useResetUrlParams returns a function that, when called, removes URL parameters for the given list
// of param names in the URL.
// To remove all the params call resetUrlParams without any arguments.  Otherwise, include
// an array with the param name(s) to be removed from the URL.
// The native history stack will not push a new entry; instead, its
// latest entry will be replaced.
export function useResetUrlParams(): (paramsToDelete?: string[]) => void {
  const location = useLocation()
  const navigate = useNavigate()
  let called = false

  return function resetUrlParams(paramsToDelete: string[] = []) {
    if (called) {
      // TODO we could batch updates to avoid this issue
      console.error("useResetUrlParams: resetUrlParams was called multiple times in one render, aborting")
      return
    }
    called = true

    const newParams = new URLSearchParams(location.search)
    // Remove params in paramsToDelete array
    if (paramsToDelete.length) {
      paramsToDelete.forEach((dp) => {
        newParams.delete(dp)
      })
    } else {
      // Remove all params
      for (const key of newParams.keys()) {
        newParams.delete(key)
      }
    }

    setUrlParams(navigate, location, newParams)
  }
}
