import styled, { css } from "styled-components"

export const InlinePillContainer = styled.span<{ $border?: boolean }>`
  background-color: ${({ theme }) => theme.colors.primaryColors.gxAccentBackground};
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.horizontal.xxs};
    border-radius: ${theme.spacing.cornerRadius.small};
    background-color: ${theme.colors.primaryColors.gxAccentBackground};
    margin: 0 ${theme.spacing.horizontal.xxxs};
  `};
  ${({ theme, $border }) =>
    $border &&
    css`
      border: 1px solid ${theme.colors.primaryColors.gxAccentMedium};
    `}
  display: inline-flex;
`
