import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export function useHeaderResize(initialHeight?: number) {
  const location = useLocation()
  const headerElement = document.getElementById("main-header")
  const elHeight = headerElement?.clientHeight
  const [headerHeight, setHeaderHeight] = useState<number>(initialHeight ?? elHeight ?? 0)

  const handleHeaderResize = useCallback(() => {
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight || headerHeight)
    }
  }, [elHeight, headerHeight])

  useEffect(() => {
    const headerElement = document.getElementById("main-header")
    setHeaderHeight(headerElement?.clientHeight || headerHeight)
    window.addEventListener("resize", handleHeaderResize)
    return () => {
      window.removeEventListener("resize", handleHeaderResize)
    }
  }, [handleHeaderResize, headerHeight, location.pathname])
  // also check header size on page change
  return [headerHeight]
}
