import { Drawer } from "src/ui/Drawer/Drawer"
import { DataSourceCrudDrawerStyles } from "src/DataAssets/connect-to-data/NewAssetDrawer"
import { useQuery } from "@apollo/client"
import { GetDataSourceWithConfigDocument } from "src/api/graphql/graphql-operations"
import { Skeleton } from "antd"
import { EditDataSource } from "src/DataAssets/manage-datasources/EditDataSourceForm"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"

type Props = {
  isOpen: boolean
  onClose: () => void
  afterClose: () => void
  dataSource?: {
    id: string
    name: string
    type: SupportedDataSource
  }
}

export function EditDataSourceDrawer({ isOpen, onClose, afterClose, dataSource }: Props) {
  const { data, loading } = useQuery(GetDataSourceWithConfigDocument, {
    variables: { id: dataSource?.id ?? "" },
    skip: !dataSource?.id,
  })
  return (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      size="large"
      open={isOpen}
      title="Edit Data Source"
      onClose={onClose}
      afterOpenChange={(open) => {
        if (!open) {
          afterClose()
        }
      }}
    >
      <Skeleton style={{ padding: "24px" }} active loading={loading} paragraph={{ rows: 5 }} />
      {!loading && data?.datasourceV2?.config && dataSource?.type && (
        <EditDataSource
          data={JSON.parse(data.datasourceV2.config)}
          dataSource={{ id: data.datasourceV2.id, name: data.datasourceV2.name }}
          onBack={onClose}
          dataSourceType={dataSource.type}
        />
      )}
    </Drawer>
  )
}
