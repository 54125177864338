/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useTheme } from "styled-components"
import { Config } from "vega-lite"

// This file contains global config options for Vega Lite charts

export const vegaLiteSchema = "https://vega.github.io/schema/vega-lite/v5.json"

export const tooltipOptions = { theme: "dark" }

/*
These paths are taken from:
  - src/ui/Image/assets/image/success.svg
  - src/ui/Image/assets/image/failure.svg
*/
export const successIconPath =
  "M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM16.2 10.3L11.4 15.1C11 15.5 10.4 15.5 10 15.1L7.8 12.9C7.4 12.5 7.4 11.9 7.8 11.5C8.2 11.1 8.8 11.1 9.2 11.5L10.7 13L14.8 8.9C15.2 8.5 15.8 8.5 16.2 8.9C16.6 9.3 16.6 9.9 16.2 10.3Z"
export const failureIconPath =
  "M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM15.7 14.3C16.1 14.7 16.1 15.3 15.7 15.7C15.3 16.1 14.7 16.1 14.3 15.7L12 13.4L9.7 15.7C9.3 16.1 8.7 16.1 8.3 15.7C7.9 15.3 7.9 14.7 8.3 14.3L10.6 12L8.3 9.7C7.9 9.3 7.9 8.7 8.3 8.3C8.7 7.9 9.3 7.9 9.7 8.3L12 10.6L14.3 8.3C14.7 7.9 15.3 7.9 15.7 8.3C16.1 8.7 16.1 9.3 15.7 9.7L13.4 12L15.7 14.3Z"

export const ChartConfig = (): Config => {
  const theme = useTheme()

  const config: Config = {
    view: { stroke: null },
    font: "Open Sans",
    axis: {
      labelFontSize: 10,
      titleFontSize: 12,
      titleFontWeight: "bold",
    },
    axisX: {
      grid: false,
      labelAngle: 0,
      labelSeparation: 5,
    },
    area: {
      interpolate: "step",
      line: {
        color: theme.colors.neutralColorPalette.blacks.black,
        interpolate: "step",
        strokeWidth: 1,
        strokeDash: [8, 8],
        strokeCap: "round",
      },
    },
    text: {
      align: "right",
      dx: -2,
      fontSize: 10,
    },
    point: {
      filled: true,
      strokeWidth: 0,
      stroke: theme.colors.neutralColorPalette.whites.white,
      size: 100,
      fillOpacity: 1,
    },
  }
  return config
}
