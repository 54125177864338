import type { SearchProps } from "antd/es/input/Search"
import { Search } from "src/ui/Search/Search"
import { EXPECTATION_PICKER_SEARCH_PLACEHOLDER } from "src/Expectation/CreateExpectationDrawer/words"

type SearchExpectationsProps = {
  value: string
  onChange: SearchProps["onChange"]
}

export const SearchExpectations = ({ value, onChange }: SearchExpectationsProps) => {
  return <Search allowClear placeholder={EXPECTATION_PICKER_SEARCH_PLACEHOLDER} value={value} onChange={onChange} />
}
