import { Alert, message } from "antd"
import { useEffect, useState } from "react"
import { useJobByID } from "src/common/hooks/useJobByID"
import { ApolloError } from "@apollo/client"
import {
  GX_AGENT_INACTIVE,
  GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE,
  RETURNED_GX_AGENT_ERROR_MESSAGE_NO_AGENT,
  RETURNED_GX_AGENT_ERROR_MESSAGE_TIMEOUT,
} from "src/GXAgent/words"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import {
  EDITOR_DRAWER_IN_PROGRESS_MESSAGE,
  EDITOR_DRAWER_UNKNOWN_ERROR_MESSAGE,
  EDITOR_SUCCESS_MESSAGE,
} from "src/DataAssets/AssetDetails/Expectations/words"

interface AlertProps {
  type: "success" | "error" | "warning" | "info"
  message: string | JSX.Element
  description?: string | JSX.Element
  closable?: boolean
}

interface ExpectationSuiteDrawerAlertProps {
  jobId: string | undefined
  mutationError: ApolloError | undefined
  setIsJobRunning: (isJobRunning: boolean) => void
  closeDrawer: (expectationSuiteId: string | undefined) => void
}

export function ExpectationSuiteDrawerAlert({
  jobId,
  mutationError,
  setIsJobRunning,
  closeDrawer,
}: ExpectationSuiteDrawerAlertProps) {
  const jobData = useJobByID(jobId)
  const [alert, setAlert] = useState<AlertProps>({} as AlertProps)

  useEffect(() => {
    if (mutationError) {
      setIsJobRunning(false)
      setAlert({
        type: "error",
        message: EDITOR_DRAWER_UNKNOWN_ERROR_MESSAGE,
        description: mutationError.message,
        closable: true,
      })
    } else if (jobData?.status && ["inProgress", "queued"].includes(jobData.status)) {
      // if its been queued for more than 5 seconds, show a warning
      setTimeout(() => {
        if (["inProgress", "queued"].includes(jobData.status)) {
          setAlert({
            type: "info",
            message: EDITOR_DRAWER_IN_PROGRESS_MESSAGE,
            closable: false,
          })
        }
      }, 5000)
    } else if (jobData?.status === "complete") {
      setIsJobRunning(false)
      let expectationSuiteId
      jobData.createdResources?.forEach((resource) => {
        if (resource.entityType === "ExpectationSuite") {
          expectationSuiteId = resource.entityId
        }
      })
      closeDrawer(expectationSuiteId)
      message.success(EDITOR_SUCCESS_MESSAGE, MESSAGE_DURATION_SECONDS)
    } else if (jobData?.status === "error") {
      setIsJobRunning(false)
      if (
        jobData.errorMessage === RETURNED_GX_AGENT_ERROR_MESSAGE_TIMEOUT ||
        jobData.errorMessage === RETURNED_GX_AGENT_ERROR_MESSAGE_NO_AGENT
      ) {
        setAlert({
          type: "warning",
          message: GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE,
          description: GX_AGENT_INACTIVE,
          closable: true,
        })
      } else {
        setAlert({
          type: "error",
          message: EDITOR_DRAWER_UNKNOWN_ERROR_MESSAGE,
          description: jobData.errorMessage ?? "",
          closable: true,
        })
      }
    } else {
      // This happens when first rendering the alert after new jobId is set
      setAlert({} as AlertProps)
    }
  }, [jobData, mutationError, setIsJobRunning, closeDrawer])

  return (
    <>
      {alert.message && (
        <Alert
          key={jobId}
          type={alert.type}
          message={alert.message}
          description={alert.description}
          closable={alert.closable}
          onClose={() => setAlert({} as AlertProps)}
          showIcon
        />
      )}
    </>
  )
}
