import { UilSort } from "@iconscout/react-unicons"
import { Select, Space } from "antd"
import { EXPECTATION_PICKER_FILTER_PLACEHOLDER } from "src/Expectation/CreateExpectationDrawer/words"
import { ExpectationCategories, ExpectationCategory } from "src/schemas/expectation-metadata-category"

type FilterExpectationsProps = {
  value?: ExpectationCategory
  onSelect: (value: ExpectationCategory) => void
}

const options: Array<{ label: ExpectationCategory; value: ExpectationCategory }> = ExpectationCategories.map(
  (category) => ({
    label: category,
    value: category,
  }),
)

export const FilterExpectations = ({ value, onSelect }: FilterExpectationsProps) => {
  return (
    <Space align="center" size="small">
      <span>Filter by:</span>
      <Select
        allowClear
        value={value}
        suffixIcon={<UilSort style={{ width: 16 }} />}
        style={{ minWidth: 210 }}
        placeholder={EXPECTATION_PICKER_FILTER_PLACEHOLDER}
        options={options}
        onChange={onSelect}
      />
    </Space>
  )
}
