import { Space, Radio, DatePicker, Form, Select, Alert, Flex, notification } from "antd"
import { JobFragment } from "src/api/graphql/graphql-operations"

import { ValidateSubsetDrawerFooter } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetDrawerFooter"
import { useCallback, useEffect, useState } from "react"
import { Icon } from "src/ui/Icon"
import styled from "styled-components"
import { useRunCheckpointJob } from "src/DataAssets/AssetDetails/Expectations/useRunCheckpointJob"
import { SplitterUnion } from "src/api/graphql/graphql"
import { NOTIFICATION_INFINITE_DURATION_SECONDS, NOTIFICATION_WITH_LINK_DURATION_SECONDS } from "src/common/config"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { useAnalytics } from "src/analytics/useAnalytics"
import {
  SplitterDateConfig,
  SplitterError,
  getFormatString,
  getSplitterError,
  parseDate,
} from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { useCheckpointList } from "src/DataAssets/AssetDetails/Splitters/useCheckpointList"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { JobStateObserver } from "src/common/components/JobStateObserver"
import { VALIDATE_ERROR_DESCRIPTION, VALIDATE_ERROR_TEXT, VALIDATE_SUCCESS_TEXT } from "src/DataAssets/words"
import { Drawer } from "src/ui/Drawer/Drawer"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { BatchDefinitionDescription } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionDescription"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { AlertInfo } from "src/ui/Alert"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { RadioGroup } from "src/ui/Radio/RadioGroup.tsx"
import { ValidateExpectationsModal } from "src/DataAssets/AssetDetails/ValidateExpectationsModal.tsx"
import { BodyNormal, BodyStrong, Link } from "src/ui/typography/Text/Text"

// Interfaces and Types
interface ValidateSubsetDrawerProps {
  expectationSuiteId: string
  assetId: string
  open: boolean
  close: () => void
}

export type SplitterType = SplitterUnion["__typename"]

export type CheckpointListOption = {
  label: string | undefined
  value: string | undefined
}

enum BatchType {
  latest = "latest",
  custom = "custom",
}

// Styled Components
const SplitterInfoMessage = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    padding: 2px;
  }
`

// Main Component
export const ValidateSubsetDrawer = ({ expectationSuiteId, assetId, open, close }: ValidateSubsetDrawerProps) => {
  const [batchType, setBatchType] = useState<BatchType>(BatchType.latest)
  const [selectedSplitterConfig, setSelectedSplitterConfig] = useState<SplitterDateConfig>({
    year: undefined,
    month: undefined,
    day: undefined,
  })

  const [form] = Form.useForm()
  const checkpointList = useCheckpointList(expectationSuiteId, assetId)
  const [openValidateSplitterModal, setOpenValidateSplitterModal] = useState(false)
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<CheckpointListOption | undefined>()
  const [jobId, setJobId] = useState<string | null>()
  const { runJob, error: createJobError } = useRunCheckpointJob({
    onCompleted: (data) => setJobId(data.createRunCheckpointJob.jobId),
  })
  const [isLoadingCurrentJobRequest, setIsLoadingCurrentJobRequest] = useState(false)
  const analytics = useAnalytics()
  const [splitterError, setSplitterError] = useState<SplitterError | null>()

  const [notificationApi, notificationContextHolder] = notification.useNotification()

  const { splitterColumns, isSplitterSupported, dateFormat, picker, splitterMethod, splitterOptionsString } =
    useGetSplitterData({ isVisible: open })

  const splitterOptions =
    isSplitterSupported && splitterMethod !== "" && splitterOptionsString
      ? { [splitterOptionsString]: selectedSplitterConfig }
      : undefined

  /**
   ** Get agent status to show error modal if agent is not active
   */
  const { executeFunction: loadAgentStatus, loading: isLoadingAgentStatus } = useLazyAgentStatus()
  const agentEnabled = useIsGXAgentEnabled()

  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  const reset = useCallback(() => {
    // Reset states when job is finished
    setJobId(null)
    setSplitterError(null)
    setIsLoadingCurrentJobRequest(false)
    if (checkpointList.length > 1) {
      setSelectedCheckpoint(undefined)
    }
  }, [checkpointList.length])

  const successMessage = (validationResultUrl: string) => {
    return (
      <Link to={validationResultUrl} strong>
        See results
      </Link>
    )
  }

  /**
   ** Show success or error messages when job is completed
   */
  const displayError = useCallback(() => {
    const errorMsg = VALIDATE_ERROR_TEXT
    notificationApi.error({
      message: errorMsg,
      description: VALIDATE_ERROR_DESCRIPTION,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
    reset()
  }, [notificationApi, reset])

  useEffect(() => {
    if (createJobError) {
      displayError()
    }
  }, [displayError, createJobError])
  const onJobComplete = (job: JobFragment) => {
    const validationResultId = job.createdResources?.find(
      ({ entityType }) => entityType === "SuiteValidationResult",
    )?.entityId

    const valResultUrl = `/data-assets/${assetId}/validations/expectation-suites/${expectationSuiteId}/results/${validationResultId}`

    notificationApi.success({
      message: VALIDATE_SUCCESS_TEXT,
      description: successMessage(valResultUrl),
      duration: NOTIFICATION_WITH_LINK_DURATION_SECONDS,
      placement: "top",
    })
    reset()
  }

  const onJobError = (job: JobFragment) => {
    displayError()
    setSplitterError(getSplitterError(job?.errorMessage || ""))
  }

  // Event Handlers
  const onCheckpointChange = (value: { value: string; label: string }) => {
    setSelectedCheckpoint(value)
  }

  /**
   ** Set selected Checkpoint when list length is 1
   */
  useEffect(() => {
    if (!selectedCheckpoint && checkpointList.length === 1 && checkpointList[0]?.key) {
      setSelectedCheckpoint(checkpointList[0])
    }
  }, [selectedCheckpoint, checkpointList])

  const runJobWithAgent = async function (checkpointId: string) {
    const res = await loadAgentStatus()

    const isAgentConnected = res.data?.agentStatus.active

    if (isAgentConnected) {
      // run job with splitter options if custom batch is selected
      batchType === BatchType.latest ? runJob(checkpointId) : runJob(checkpointId, splitterOptions)
      setIsLoadingCurrentJobRequest(true)
      analytics?.capture("checkpoint.run_from_agent")
    } else {
      setIsAgentErrorModalVisible(true)
      reset()
    }
  }

  const runJobWithRunner = async function (checkpointId: string) {
    // run job with splitter options if custom batch is selected
    batchType === BatchType.latest ? runJob(checkpointId) : runJob(checkpointId, splitterOptions)
    setIsLoadingCurrentJobRequest(true)
    analytics?.capture("checkpoint.run_from_runner")
  }

  // run this on Validation
  const tryToRunJobUsing = async function () {
    const runJobFn = agentEnabled ? runJobWithAgent : runJobWithRunner
    runJobFn(selectedCheckpoint?.value || "")
  }

  const setDateOptions = (dateString: string) => {
    const selectedSplitter = parseDate(dateString)
    setSelectedSplitterConfig(selectedSplitter)
  }
  const onValidateRun = () => {
    tryToRunJobUsing()
  }

  const onValidateSnippet = () => {
    setOpenValidateSplitterModal(true)
  }

  const getButtonLabel = () => (isLoadingCurrentJobRequest ? "Validating" : "Validate")

  const footer = (
    <ValidateSubsetDrawerFooter
      onValidate={onValidateRun}
      onValidateSnippet={onValidateSnippet}
      validationLoading={isLoadingCurrentJobRequest}
      buttonLabel={getButtonLabel()}
      validationButtonsDisabled={
        (batchType === BatchType.custom && !selectedSplitterConfig.year) ||
        isLoadingCurrentJobRequest ||
        isLoadingAgentStatus ||
        !selectedCheckpoint
      }
    />
  )

  const batchDefinitionEnabled = useIsFeatureEnabled("batchDefinitionEnabled")

  const formatStr = getFormatString(dateFormat)

  return (
    <>
      {notificationContextHolder}
      <Drawer title="Validate Data Asset" open={open} onClose={close} footer={footer} size="large">
        {batchDefinitionEnabled ? (
          <BatchDefinitionDescription />
        ) : (
          <AlertInfo
            message={
              <SplitterInfoMessage>
                <span>
                  {"This Data Asset is split by "}
                  <BodyStrong>{splitterMethod}</BodyStrong> on column <BodyStrong>{splitterColumns}</BodyStrong>
                </span>
              </SplitterInfoMessage>
            }
          />
        )}

        <Form
          style={{ marginTop: 40 }}
          form={form}
          name="selectCheckpointValidationDrawer"
          layout="vertical"
          validateTrigger={["onBlur"]}
          preserve={false}
        >
          {checkpointList.length > 1 && (
            <Form.Item name="checkpoint" label="Select a checkpoint:" id="checkpoint" rules={[{ required: true }]}>
              <Select
                placeholder="Select a checkpoint"
                onChange={onCheckpointChange}
                style={{ width: "50%" }}
                defaultValue={undefined}
                options={checkpointList}
                labelInValue
              />
            </Form.Item>
          )}

          <div style={{ marginBottom: 20, marginTop: 20 }}>
            <BodyNormal>Specify a single Batch to validate:</BodyNormal>
          </div>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <RadioGroup onChange={(e) => setBatchType(e.target.value)} value={batchType}>
              <Space direction="vertical">
                <Radio key="latest" value="latest">
                  Latest Batch
                </Radio>
                <Radio disabled={!isSplitterSupported && splitterMethod !== ""} key="custom" value="custom">
                  Custom Batch
                </Radio>
              </Space>
            </RadioGroup>
            {batchType === BatchType.latest ? null : (
              <Form.Item
                name="datepicker"
                label={`${splitterMethod}:`}
                id="datepicker"
                rules={[{ required: true, message: `Use the format ${formatStr}` }]}
              >
                <DatePicker
                  picker={picker}
                  style={{ width: "50%" }}
                  placeholder={formatStr}
                  format={dateFormat}
                  onChange={(_date, dateString) =>
                    setDateOptions(Array.isArray(dateString) ? dateString[0] : dateString)
                  }
                />
              </Form.Item>
            )}
          </Space>
        </Form>
        <ValidateExpectationsModal
          splitterOptions={splitterOptions}
          batchType={batchType}
          expectationSuiteID={expectationSuiteId}
          assetRefID={assetId}
          open={openValidateSplitterModal}
          onCancel={() => setOpenValidateSplitterModal(false)}
          onOk={() => setOpenValidateSplitterModal(false)}
          selectedCheckpointFromSplitterDrawer={selectedCheckpoint}
        />
        {splitterError && (
          <Alert
            style={{ position: "absolute", bottom: 100, width: "95%" }}
            message={<SplitterInfoMessage>{splitterError?.errorMessage}</SplitterInfoMessage>}
            type={splitterError?.alertLevel}
            showIcon
            closable
            icon={<Icon small name="closeCircleSolid" />}
          />
        )}
        <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
        {jobId && <JobStateObserver jobId={jobId} onComplete={onJobComplete} onError={onJobError} />}
      </Drawer>
    </>
  )
}
