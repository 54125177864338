import { Input, Select } from "antd"

type ColumnNameSelectorProps = {
  onChange: (value: string) => void
  label: string
  path: string
  options?: { value: string; label: string }[]
}

export const ColumnNameSelector = ({ options, onChange, label, path, ...rest }: ColumnNameSelectorProps) => {
  return (
    <>
      {options && (
        <Select
          {...rest}
          onChange={(option) => {
            onChange(option)
          }}
          showSearch
          placeholder="Select a column"
          aria-label={`Select ${label}`}
          options={options}
          filterOption={(inputValue, option) => {
            const optionValue = option?.value?.toString() || ""
            return optionValue.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }}
        />
      )}
      {!options && (
        <Input
          {...rest}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter a column name"
          aria-label={label}
          autoComplete="off"
        />
      )}
    </>
  )
}
