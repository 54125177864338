import EvaluationParameters from "src/Expectation/EvaluationParameters"
import { ParamHistory, ParamTypes } from "src/Expectation/Expectation"
import { findParamListMarker } from "src/Expectation/utils/findParamListMarker"
import React from "react"
import { RowCondition } from "src/Expectation/RowCondition"
import { END_OF_CONDITION_STRING } from "src/common/config"
import { ARRAY_BASED_EXPECTATION_DIAGNOSTIC_RENDER_KEYS_STARTS_WITH } from "src/Expectation/StringRenderComponent"

const ROW_CONDITION_PARAM_STARTS_WITH = "$row_condition__"

export interface WindowTooltips {
  min_value?: string
  max_value?: string
}

interface AddDynamicStyledTextProps {
  template: string
  params: ParamTypes
  getDynamicStyleTag: GetDynamicStyleTagType
  limit?: number
  truncate?: boolean | null
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  windowTooltips?: WindowTooltips
}

type EvaluationParam = {
  value: {
    $PARAMETER: string
  }
}

export type GetDynamicStyleTagType = (
  dynamicString: string | number | string[],
  id?: string,
  history?: ParamHistory,
  tooltip?: string,
  danger?: boolean,
  strikethrough?: boolean,
) => React.ReactNode

function styleExpectationString(
  stringValue: string,
  params: ParamTypes,
  getDynamicStyleTag: GetDynamicStyleTagType,
  windowTooltips?: WindowTooltips,
): string | React.ReactNode {
  if (stringValue.startsWith("$")) {
    const { dynamicValue, evaluation_parameter, history, tooltip, renderState } = findParamValue(
      stringValue.substring(1),
      params,
      windowTooltips,
    )

    switch (typeof dynamicValue) {
      case "object": {
        if ("$PARAMETER" in dynamicValue) {
          return <EvaluationParameters key={stringValue} param={`${dynamicValue["$PARAMETER"]}`} /> // Evaluation Parameter for Expectation Suite View
        }
        return JSON.stringify(dynamicValue) // fallback unknown objects to string
      }
      // eslint-disable-next-line no-fallthrough
      case "number":
      case "string": {
        const isConditionalParam = stringValue.startsWith(ROW_CONDITION_PARAM_STARTS_WITH)
        if (isConditionalParam) {
          return dynamicValue
        }
        if (evaluation_parameter) {
          const evaluationParameterValue = evaluation_parameter?.["value"]?.["$PARAMETER"] ?? ""
          return <EvaluationParameters key={stringValue} param={`${dynamicValue}`} tooltip={evaluationParameterValue} /> // Evaluation Parameter for Validation Result View
        }
        const isUnexpected = renderState === "unexpected"
        const isMissing = renderState === "missing"
        const danger = isUnexpected || isMissing
        return getDynamicStyleTag(dynamicValue, stringValue, history, tooltip, danger, isMissing)
      }
    }
  }
  // Special cases for stringValues
  if (stringValue === "(**$kwargs)") {
    if (params.kwargs?.value) {
      return ` ( ${JSON.stringify(params.kwargs.value)} )`
    }
    return ""
  }
  if (stringValue === " ") {
    return ""
  }
  if (stringValue === ", ") {
    return ""
  }
  return stringValue
}

export function addDynamicStyledText({
  template,
  params,
  getDynamicStyleTag,
  limit,
  truncate,
  windowTooltips,
}: AddDynamicStyledTextProps) {
  const hasRowConditions = template.includes(ROW_CONDITION_PARAM_STARTS_WITH)
  const brokenString = template.split(/(\$\w+)|(\(\*\*\$kwargs\))/).filter((value) => value)

  if (truncate && brokenString.find(findParamListMarker())) {
    /*
        We know that there are over 10 params initially, and we now account for the dashes in between.
        There could be at least one param not related to the list, we estimate there are at least 7 params in a list.
        This not an exact estimation.
      */
    const expectationSummary = brokenString
      .filter(findParamListMarker(true))
      .map((stringValue) => styleExpectationString(stringValue, params, getDynamicStyleTag))
    const paramsList = brokenString
      .filter(findParamListMarker())
      .slice(0, 13)
      .map((stringValue) => styleExpectationString(stringValue, params, getDynamicStyleTag))
    return [...expectationSummary, ...paramsList]
  }

  // we slice with limit * 2 due to spaces being included in brokenString items
  const limitedBrokenString = limit !== undefined ? brokenString.slice(0, limit * 2) : brokenString
  const dynamicStyledText = limitedBrokenString.map((stringValue) => {
    return styleExpectationString(stringValue, params, getDynamicStyleTag, windowTooltips)
  })
  if (hasRowConditions) {
    const endOfConditionArrayIndex = dynamicStyledText.findIndex(
      (item) => typeof item === "string" && item.includes(END_OF_CONDITION_STRING),
    )
    const endOfCondition = dynamicStyledText[endOfConditionArrayIndex]
    const extra: string[] = []
    // after row condition may be additional text that needs to
    // be captured and rendered
    if (typeof endOfCondition === "string" && endOfCondition !== END_OF_CONDITION_STRING) {
      const [, text] = endOfCondition.split(END_OF_CONDITION_STRING)
      extra.push(text)
    }
    const restOfDynamicStyledText = [...extra, ...dynamicStyledText.slice(endOfConditionArrayIndex + 1)]
    const rowConditionDynamicStyledText: React.ReactNode[] = [
      <RowCondition key="rowCondition" dynamicStyledText={dynamicStyledText} getDynamicStyleTag={getDynamicStyleTag} />,
    ]
    return rowConditionDynamicStyledText.concat(restOfDynamicStyledText)
  }
  return dynamicStyledText
}

export function findParamValue(rawDynamicValue: string, params: ParamTypes, windowTooltips?: WindowTooltips) {
  let param, columnListArrayParams, tooltip
  if (rawDynamicValue.startsWith("removed_item")) {
    param = params[rawDynamicValue] ?? {}
    columnListArrayParams = params[rawDynamicValue]?.value
  } else {
    const trimmedDynamicValue = rawDynamicValue.substring(0, 11)
    param = params[trimmedDynamicValue] ?? {}
    columnListArrayParams = params[trimmedDynamicValue]?.value
  }

  if (rawDynamicValue.includes("column_list_") && Array.isArray(columnListArrayParams)) {
    const digitRegexp = /\d+/
    const matchingIndex = rawDynamicValue.match(digitRegexp)
    if (matchingIndex) {
      const arrIndex = parseInt(matchingIndex[0], 10)
      return { dynamicValue: columnListArrayParams[arrIndex] }
    }
  }
  let evaluation_parameter
  if ("evaluation_parameter" in param) {
    evaluation_parameter = (param as { evaluation_parameter: EvaluationParam })?.["evaluation_parameter"]
  }
  const history = ("history" in param && (param.history as ParamHistory)) || undefined

  if (windowTooltips && (rawDynamicValue === "min_value" || rawDynamicValue === "max_value")) {
    tooltip = windowTooltips[rawDynamicValue]
  }

  const renderState = params[rawDynamicValue]?.["render_state"]
  if (
    ARRAY_BASED_EXPECTATION_DIAGNOSTIC_RENDER_KEYS_STARTS_WITH.some((subString) =>
      rawDynamicValue.includes(subString),
    ) &&
    (renderState === "unexpected" || renderState === "missing")
  ) {
    tooltip = renderState.charAt(0).toUpperCase() + renderState.slice(1) + " value"
  }

  return { dynamicValue: params[rawDynamicValue]?.value, evaluation_parameter, history, tooltip, renderState }
}
