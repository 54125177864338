import { Flex, Space } from "antd"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { Button } from "src/ui/Button/Button"
import { AlertNotificationsButton } from "src/Alerts/AlertNotificationsButton"
import { ShareButton } from "src/ui/ShareButton"
import { ConditionalTooltip } from "src/ui/Tooltip/ConditionalTooltip"
import { NO_EXPECTATIONS_TOOLTIP_TEXT } from "src/Expectation/words"
import { RunCheckpointJobButton } from "src/DataAssets/AssetDetails/Expectations/RunCheckpointJobButton"
import { ValidateSubsetButton } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetButton"
import { CREATE_EXPECTATION_DRAWER_TITLE } from "src/Expectation/CreateExpectationDrawer/words"

type DataAssetHeaderActionsProps = {
  assetId: string
  showValidateSubsetButton: boolean
  noExpectations: boolean
  onNewExpectationButtonClick?: () => void
  expectationSuiteId?: string
}

export const DataAssetHeaderActions = ({
  assetId,
  showValidateSubsetButton,
  noExpectations,
  expectationSuiteId = "",
  onNewExpectationButtonClick,
}: DataAssetHeaderActionsProps) => {
  const isEditor = useRequireRole("EDITOR")
  const newExpectationOrShareButton =
    isEditor && onNewExpectationButtonClick ? (
      <Button key="plus" type="default" icon="plus" onClick={onNewExpectationButtonClick}>
        {CREATE_EXPECTATION_DRAWER_TITLE}
      </Button>
    ) : (
      <ShareButton key="share" />
    )

  const editorValidateButton = showValidateSubsetButton ? (
    <ValidateSubsetButton
      key="validateSubset"
      expectationSuiteId={expectationSuiteId}
      assetId={assetId}
      disabled={noExpectations}
    />
  ) : (
    <RunCheckpointJobButton
      key="runCheckpointJob"
      expectationSuiteId={expectationSuiteId}
      assetId={assetId}
      disabled={noExpectations}
    />
  )

  const validateButton = isEditor ? editorValidateButton : null

  return (
    <Flex justify="flex-end" align="center">
      <Space>
        {[
          <AlertNotificationsButton
            key="alerts"
            assetFlowIds={{ assetId: assetId, expectationSuiteId: expectationSuiteId }}
          />,
          newExpectationOrShareButton,
          <ConditionalTooltip
            key="runCheckpointJob"
            showTooltip={noExpectations}
            tooltipText={NO_EXPECTATIONS_TOOLTIP_TEXT}
          >
            {validateButton}
          </ConditionalTooltip>,
        ]}
      </Space>
    </Flex>
  )
}
