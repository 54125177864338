import React, { PropsWithChildren, useState } from "react"
import { ExpandableContainer } from "src/ui/ExpandableContainer"
import { LinkableSection } from "src/ui/LinkableSection"
import { removeInvalidHtmlCharacters } from "src/common/utils/removeInvalidHtmlCharacters"

type ExpectationGroupProps = {
  groupName: string
  headerBottomBorder?: boolean
  actionMenu?: React.ReactNode
  className?: string
  isGroupDeleted?: boolean
}

export const ExpectationGroup: React.FC<PropsWithChildren<ExpectationGroupProps>> = ({
  groupName,
  children,
  headerBottomBorder,
  actionMenu,
  className,
  isGroupDeleted,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const background = isGroupDeleted ? "colorFillQuarternaryDark" : "white"

  return (
    <ExpandableContainer
      className={className}
      actionMenu={actionMenu}
      label={groupName}
      background={background}
      title={<LinkableSection title={groupName} id={groupName} />}
      headerId={removeInvalidHtmlCharacters(groupName.toLowerCase())}
      expanded={isOpen}
      onToggle={() => {
        setIsOpen(!isOpen)
      }}
      contentSpacing={false}
      collapsible
      headerBottomBorder={headerBottomBorder}
      titleStrikethrough={isGroupDeleted}
    >
      {isOpen && children}
    </ExpandableContainer>
  )
}
