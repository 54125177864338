/* eslint-disable react-refresh/only-export-components */ // FIXME
// eslint-disable-next-line no-restricted-imports
import { Button, Flex, Modal, ModalFuncProps } from "antd"
import { Icon } from "src/ui/Icon"
import { AppModalButtonProps, AppModalConfig, AppModalProps } from "src/ui/AppModal/AppModal"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { DISCOURSE_URL } from "src/common/config"
import discourse from "src/assets/images/discourse.png"
import { useState } from "react"
import { REACT_APP_ONBOARDING_CALENDLY_LINK } from "src/common/env"
import { LoadingState } from "src/ui/LoadingState"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"
import { BodyStrong, Heading1, Link } from "src/ui/typography/Text/Text"

export const SupportModalConfig: ModalFuncProps = {
  ...AppModalConfig,
  width: 1100,
  // providing a function (instead of a react node) impacts the layout; if you provide a react node instead, you're responsible for formatting the footer yourself
  footer: () => <FooterButton />,
  title: <Title />,
  content: <Content />,
}

function Title() {
  return (
    <Flex align="center" justify="center">
      <Heading1>Schedule an onboarding call</Heading1>
    </Flex>
  )
}

function Content() {
  return (
    <Flex vertical justify="space-between">
      <InlineWidget // the loading state renders weird in StrictMode, so if developing this, be sure to disable StrictMode
        LoadingSpinner={() => <LoadingState loading absolute={false} />}
        url={`${REACT_APP_ONBOARDING_CALENDLY_LINK}?hide_gdpr_banner=1&primary_color=ff6310`}
      />
      <Flex vertical justify="center" align="center" style={{ paddingTop: "16px" }}>
        <BodyStrong>For more support</BodyStrong>
        <Flex wrap="wrap" justify="center" gap="large">
          <div>
            <Icon style={{ verticalAlign: "sub", marginRight: "8px" }} size="16px" name="mail" />
            <SupportEmailLink />
          </div>
          <div>
            <img
              style={{ verticalAlign: "text-top", marginRight: "8px" }}
              src={discourse}
              height={16}
              width={16}
              alt="Discourse logo"
            />
            <Link to={DISCOURSE_URL}>Get help from our community</Link>
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}

function FooterButton() {
  const [buttonProps, setButtonProps] = useState<AppModalProps["cancelButtonProps"]>({ title: "Cancel" })
  useCalendlyEventListener({
    onEventScheduled: () => setButtonProps({ type: "primary", title: "Done" }),
  })
  return (
    <Button
      {...AppModalButtonProps}
      style={{ ...AppModalButtonProps.style }}
      type={buttonProps?.type}
      onClick={() => Modal.destroyAll()}
    >
      {buttonProps?.title}
    </Button>
  )
}
