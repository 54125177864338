import { useQuery } from "@apollo/client"
import { GetAllCheckpointsDocument } from "src/api/graphql/graphql-operations"
import { useMemo } from "react"

export function useCheckpointList(expectationSuiteId: string, assetId: string) {
  const { data: checkpointData } = useQuery(GetAllCheckpointsDocument, {
    variables: { expectationSuiteID: expectationSuiteId, assetRefID: assetId },
    skip: !expectationSuiteId || !assetId,
  })

  return useMemo(
    () =>
      checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId?.map((item) => ({
        label: item?.name,
        value: item?.id,
        key: item?.id,
      })) ?? [],
    [checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId],
  )
}
