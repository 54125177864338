import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useAnchorScroll = (loading: boolean, list?: string[]) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedAnchor, setSelectedAnchor] = useState("")
  // Not the most explicit behavoir, but in order to make scrolling case insensitive,
  // make sure to ALWAYS pass `setSelectedAnchor` lowercased value
  const hash = location.hash
  const scrollToAnchor = useCallback((anchor: string) => {
    document.getElementById(anchor.toLowerCase())?.scrollIntoView({ behavior: "smooth", block: "center" })
  }, [])

  // Scroll to Anchor after loading is completed
  useEffect(() => {
    if (!loading && hash) {
      const setAnchor = (hash: string) => {
        setSelectedAnchor(decodeURIComponent(hash).toLowerCase())
        scrollToAnchor(hash) // Using Antd's anchor component was causing a re-render of the page, the vanilla approach solves this issue
      }
      const trimmedHash = hash.replace(/^#/, "")
      const removeHash = () => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
        setSelectedAnchor("")
      }

      if (!selectedAnchor) {
        setAnchor(trimmedHash)
      } else {
        if (list && list?.length !== 0) {
          // Checking if anchor that we want to scroll to wasn't filtered out
          const isSelectedInList = list.includes(decodeURIComponent(trimmedHash))
          !isSelectedInList && removeHash()
        }
        setAnchor(trimmedHash)
      }
    }
  }, [loading, hash, navigate, selectedAnchor, list, location.pathname, location.search, scrollToAnchor])

  const onAnchorSelection = (item: string) => {
    navigate(`${location.pathname}${location.search}#${encodeURIComponent(item)}`, { replace: true })
    setSelectedAnchor(item.toLowerCase())
  }

  return { onAnchorSelection, selectedAnchor }
}
