import { useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"
import { useEffect, useState } from "react"
import { JobType, mapJobtoUnionType } from "src/GXAgent/JobUnion"

export const GetJobsUseRefetchOnJobCompleteDocument = graphql(`
  query GetJobs_useRefetchOnJobComplete {
    jobs {
      id
      __typename
      status
      sourceResources {
        entityId
        entityType
      }
      timeQueued
    }
  }
`)

export const useRefetchOnJobComplete = ({ jobType, refetch }: { jobType: JobType; refetch: () => void }) => {
  // Trigger a refetch and query when the latest job of a given type completes
  const [lastJobIDThatTriggeredRefetch, setLastJobIDThatTriggeredRefetch] = useState<string | null>(null)
  const { data } = useQuery(GetJobsUseRefetchOnJobCompleteDocument)
  const latestCompletedJob = data?.jobs
    .map(mapJobtoUnionType)
    ?.sort((a, b) => new Date(b.timeQueued).getTime() - new Date(a.timeQueued).getTime())
    ?.find((job) => job.jobType === jobType && job.status === "complete")
  useEffect(() => {
    if (latestCompletedJob && latestCompletedJob.id !== lastJobIDThatTriggeredRefetch) {
      refetch()
      setLastJobIDThatTriggeredRefetch(latestCompletedJob.id)
    }
  }, [latestCompletedJob, lastJobIDThatTriggeredRefetch, refetch])
}
