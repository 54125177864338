import styled from "styled-components"
import { Table } from "antd"
import { Theme } from "src/ui/themes/theme"
import { Icon } from "src/ui/Icon"

const BodyContainer = styled.section`
  max-width: 900px;
`

const StyledTable = styled(Table)`
  .ant-table-tbody > tr {
    display: table-row;
  }

  .ant-pagination {
    padding: 0 16px;
  }
`

const StyledColumnHeader = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  line-height: ${({ theme }) => theme.typography.lineHeight.large};
`

const StyledUserActionsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`

const FlexContainer = styled.div<{
  $alignsItemsCenter?: boolean
  $gap?: keyof Theme["spacing"]["scale"]
}>`
  display: flex;
  ${({ $alignsItemsCenter }) => $alignsItemsCenter && `align-items: center;`}
  ${({ $gap, theme }) => $gap && `gap: ${theme.spacing.scale[$gap]}`}
`

const ButtonWrapper = styled(FlexContainer)`
  justify-content: flex-end;
`
const BodyWrapper = styled(FlexContainer)`
  padding-left: ${({ theme }) => theme.spacing.scale.m};
`

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.warning.colorWarning};
`

export {
  BodyContainer,
  StyledTable,
  StyledColumnHeader,
  StyledUserActionsWrapper,
  FlexContainer,
  WarningIcon,
  ButtonWrapper,
  BodyWrapper,
}
