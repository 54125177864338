import { ReactNode } from "react"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import styled from "styled-components"
import { getScreenSize } from "src/common/utils/get-screen-size"
import { isEmpty } from "lodash-es"
import { MaintenanceBanner } from "src/banners/MaintenanceBanner"

import { useGetFeatureFlagVariations } from "src/common/hooks/useGetFeatureFlagVariations"

const StyledDiv = styled.div<{ $breakpoint: string; $noIllustration: boolean | undefined }>`
  padding: ${({ theme, $breakpoint }) => ($breakpoint === "xs" ? theme.spacing.scale.xxxs : theme.spacing.scale.xs)};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${(props) => (props.$noIllustration ? " 1300px" : "1200px")};
`

interface MainContentProps {
  children: ReactNode
  $noIllustration?: boolean
}

/**
 * This component should only be used once per page.
 */
function MainContent(props: MainContentProps) {
  const bp = useBreakpoint()
  // AntD returns an empty object sometimes ??? so we need this to prevent jank
  const breakpoint = isEmpty(bp) ? "" : getScreenSize(bp)
  const maintenanceFlagData = useGetFeatureFlagVariations("maintenanceBannerEnabled")

  return (
    <StyledDiv $noIllustration={props.$noIllustration} $breakpoint={breakpoint}>
      {!!maintenanceFlagData?.enabled && <MaintenanceBanner />}
      {props.children}
    </StyledDiv>
  )
}

export { MainContent }
