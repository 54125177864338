import { useEffect } from "react"
import { parseRowConditionStringToObject } from "src/Expectation/uiForms/customRenderers/RowConditionControl/rowConditionParser"
import { GetDynamicStyleTagType } from "src/Expectation/utils/addDynamicStyledText"
import { BodyNormal } from "src/ui/typography/Text/Text"
import { useAnalytics } from "src/analytics/useAnalytics"
import { END_OF_CONDITION_STRING } from "src/common/config"

export const getFullRowCondition = (dynamicStyledText: React.ReactNode[]): string => {
  const endOfConditionIndex = dynamicStyledText.findIndex(
    (item: React.ReactNode) => typeof item === "string" && item.includes(END_OF_CONDITION_STRING),
  )
  // split is required here to due to inconsistency in how Core parametrizes
  // row condition rendering variables for different Expectations :(
  // notice text before or after ", then"
  // e.g.
  // [..., "==date", "(", '"2024-11-06"', "), then ", ...]
  // versus
  // [..., ">0", ", then Must have greater than or equal to ", ...]
  const rowConditionString = dynamicStyledText
    .slice(0, endOfConditionIndex + 1)
    .join("")
    .split(END_OF_CONDITION_STRING)[0]
  const rowConditionObject = parseRowConditionStringToObject(rowConditionString)
  return rowConditionObject
    ? `${rowConditionObject.column} ${rowConditionObject.operator} ${rowConditionObject.parameter ?? ""}`.trim()
    : rowConditionString // if parsing fails, render Core row condition string e.g. col("created_at")>date("2024-11-06")
}

export const RowCondition = ({
  dynamicStyledText,
  getDynamicStyleTag,
}: {
  dynamicStyledText: React.ReactNode[]
  getDynamicStyleTag: GetDynamicStyleTagType
}) => {
  const posthog = useAnalytics()

  useEffect(() => {
    posthog?.capture("Expectation with Conditional Param")
  }, [posthog])

  const fullRowCondition = getFullRowCondition(dynamicStyledText)

  return (
    <>
      <BodyNormal>{"If "}</BodyNormal>
      {getDynamicStyleTag(fullRowCondition)}
      <br />
    </>
  )
}
