import { ApolloError, useMutation } from "@apollo/client"
import { PropsWithChildren, useState } from "react"
import { message, DrawerProps, Form, Flex, Skeleton } from "antd"
import {
  ALERT_CREATE_SUCCESS_MESSAGE,
  SLACK_ALERT_NAME_FIELD,
  SLACK_ALERT_TYPE_FIELD,
  CHECKPOINT_DROPDOWN_FIELD,
  SLACK_ALERT_FORM,
  SLACK_ALERT_WEBHOOK_FIELD,
  EDIT_ALERT_ERROR_TEXT,
  ALERT_UPDATE_SUCCESS_MESSAGE,
  FAILED_TO_SAVE_ALERT,
} from "src/Alerts/words"
import { Drawer } from "src/ui/Drawer/Drawer"

import { CreateSlackNotificationDocument } from "src/api/graphql/graphql-operations"
import { QUICK_MESSAGE_DURATION } from "src/common/config"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { SlackAlertForm } from "src/Alerts/SlackAlertForm"
import { NotifyType } from "src/api/graphql/graphql"
import { SlackActionsList } from "src/Alerts/SlackActionsList"
import { useQueryCheckpoints } from "src/Alerts/useQueryCheckpoints"
import { getSlackActionsForSelectedCheckpoint } from "src/Alerts/utils"
import { graphql } from "src/api/graphql"
import { EmailAlertCard } from "src/Alerts/EmailAlertCard"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

export type AssetFlowIds = {
  assetId: string
  expectationSuiteId: string
}

export interface SlackAction {
  name: string
  action: {
    class_name: "SlackNotificationAction"
    notify_on: NotifyType
    slack_webhook: string
  }
}

interface AlertsDrawerProps extends DrawerProps {
  id?: string
  assetFlowIds?: AssetFlowIds
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export interface Error {
  title: string
  description: string
}

type SlackActionStatus = "edit" | "create" | "readOnly"

export const EditSlackActionDocument = graphql(`
  mutation EditSlackNotificationAction($input: EditSlackActionInput!) {
    editSlackNotification(input: $input) {
      checkpoint {
        id
        actionList
      }
    }
  }
`)

export const CheckSlackConnectionDocument = graphql(`
  mutation CheckSlackConnection($checkpointId: UUID!, $webhookUrl: String!) {
    checkSlackConnection(checkpointId: $checkpointId, webhookUrl: $webhookUrl) {
      status
    }
  }
`)

export function AlertsDrawer({ id = "", assetFlowIds, isVisible, setIsVisible }: AlertsDrawerProps) {
  const hideAlerts = useIsFeatureEnabled("hideAlertsEnabled")
  const formID = `edit-checkpoint-form-${id}`
  const [form] = Form.useForm()
  const checkpointSelectedValue = Form.useWatch(CHECKPOINT_DROPDOWN_FIELD, form)

  const [error, setError] = useState<Error | null>(null)
  const [slackActionStatus, setSlackActionStatus] = useState<SlackActionStatus>("readOnly")

  const [checkpoints, checkpointQueriesLoading, refetch] = useQueryCheckpoints({
    id,
    assetFlowIds,
    isVisible,
    onError: handleErrorForCheckpoint,
  })
  const singleCheckpoint = checkpoints?.length === 1 ? checkpoints[0] : undefined
  const slackActions = checkpoints
    ? getSlackActionsForSelectedCheckpoint(singleCheckpoint?.id || checkpointSelectedValue, checkpoints)
    : []

  const checkpointId = id || checkpointSelectedValue || checkpoints?.[0]?.id

  const handleErrorForAlert = (e: ApolloError) => {
    setError({
      title: FAILED_TO_SAVE_ALERT,
      description: e.message || "An error occurred",
    })
  }

  function handleErrorForCheckpoint(e: ApolloError) {
    setError({
      title: EDIT_ALERT_ERROR_TEXT.UNEXPECTED_ERROR,
      description: e.message,
    })
  }

  const saveOrUpdateSlackAction = () => {
    const formValues = form.getFieldsValue([SLACK_ALERT_WEBHOOK_FIELD, SLACK_ALERT_NAME_FIELD, SLACK_ALERT_TYPE_FIELD])

    switch (slackActionStatus) {
      case "create":
        createSlackNotification({
          variables: {
            input: {
              checkpointId,
              name: formValues[SLACK_ALERT_NAME_FIELD],
              webhook: formValues[SLACK_ALERT_WEBHOOK_FIELD],
              notifyType: formValues[SLACK_ALERT_TYPE_FIELD],
            },
          },
        })
        break
      case "edit":
        editSlackAction({
          variables: {
            input: {
              checkpointId,
              name: formValues[SLACK_ALERT_NAME_FIELD],
              webhook: formValues[SLACK_ALERT_WEBHOOK_FIELD],
              notifyType: formValues[SLACK_ALERT_TYPE_FIELD],
            },
          },
        })
        break
      default:
        break
    }
  }

  const handleSlackActionOperations = () => {
    const formValues = form.getFieldsValue([SLACK_ALERT_WEBHOOK_FIELD])
    if (form.isFieldTouched(SLACK_ALERT_WEBHOOK_FIELD)) {
      checkSlackConnection({
        variables: {
          checkpointId,
          webhookUrl: formValues[SLACK_ALERT_WEBHOOK_FIELD],
        },
        onCompleted: (data) => {
          if (data.checkSlackConnection?.status) {
            saveOrUpdateSlackAction()
          } else {
            setError({
              title: FAILED_TO_SAVE_ALERT,
              description: EDIT_ALERT_ERROR_TEXT.CONNECTION_TEST_FAILURE,
            })
          }
        },
        onError: handleErrorForAlert,
      })
    } else {
      saveOrUpdateSlackAction()
    }
  }

  const [createSlackNotification, { loading: createSlackNotificationLoading }] = useMutation(
    CreateSlackNotificationDocument,
    {
      onCompleted: () => {
        message.success(ALERT_CREATE_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
        resetCardFieldsOnly()
        setSlackActionStatus("readOnly")
        refetch()
      },
      onError: handleErrorForAlert,
    },
  )

  const resetCardFieldsOnly = () => {
    form.resetFields([SLACK_ALERT_NAME_FIELD, SLACK_ALERT_WEBHOOK_FIELD, SLACK_ALERT_TYPE_FIELD])
  }

  const handleReset = () => {
    error && setError(null)
    form.resetFields()
    setSlackActionStatus("readOnly")
  }

  const [editSlackAction, { loading: editSlackActionLoading }] = useMutation(EditSlackActionDocument, {
    onCompleted: () => {
      message.success(ALERT_UPDATE_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
      resetCardFieldsOnly()
      setSlackActionStatus("readOnly")
      refetch()
    },
    onError: handleErrorForAlert,
  })

  const [checkSlackConnection, { loading: slackConnectionValidating }] = useMutation(CheckSlackConnectionDocument)

  const loading: boolean = checkpointQueriesLoading || createSlackNotificationLoading || editSlackActionLoading

  const footer = (
    <Drawer.Footer gap="8px">
      {Boolean(slackActionStatus !== "readOnly") && (
        <Drawer.FooterButton
          onClick={() => {
            error && setError(null)
            resetCardFieldsOnly()
            setSlackActionStatus("readOnly")
          }}
        >
          Back
        </Drawer.FooterButton>
      )}
      <Drawer.FooterButton type="primary" htmlType="submit" disabled={loading} loading={loading} form={formID}>
        {/* Only show "Done" in ReadOnly when opening drawer from headers. */}
        {slackActionStatus === "readOnly" && !id ? "Done" : "Save"}
      </Drawer.FooterButton>
    </Drawer.Footer>
  )

  const handleSave = () => {
    error && setError(null)
    if (form.getFieldValue(SLACK_ALERT_NAME_FIELD)) {
      return handleSlackActionOperations()
    }
    setIsVisible(false)
    handleReset()
  }

  const FormAndAlerts = (
    <>
      {slackActionStatus !== "readOnly" ? (
        <SlackAlertForm
          readOnlyAlertName={slackActionStatus === "edit" && form.getFieldValue(SLACK_ALERT_NAME_FIELD)}
          slackConnectionValidating={slackConnectionValidating}
        />
      ) : (
        <SlackActionsList
          checkpointId={checkpointId}
          refetch={refetch}
          slackActions={slackActions}
          onEdit={(slackAction: SlackAction) => {
            form.setFieldsValue({
              [SLACK_ALERT_NAME_FIELD]: slackAction.name,
              [SLACK_ALERT_WEBHOOK_FIELD]: slackAction.action.slack_webhook,
              [SLACK_ALERT_TYPE_FIELD]: slackAction.action.notify_on.toUpperCase(),
            })
            setSlackActionStatus("edit")
          }}
          onAdd={() => {
            resetCardFieldsOnly()
            setSlackActionStatus("create")
          }}
        />
      )}
    </>
  )

  return (
    <Drawer
      title="Alerts"
      onClose={() => {
        setIsVisible(false)
        handleReset()
      }}
      open={isVisible}
      onClick={(e) => e.stopPropagation()}
      footer={footer}
    >
      <Flex vertical justify="space-between" style={{ paddingBottom: "50px" }}>
        <ShowLoadingState loading={loading}>
          <Form
            id={formID}
            form={form}
            name={SLACK_ALERT_FORM}
            layout="horizontal"
            onValuesChange={() => setError(null)}
            onFinish={handleSave}
          >
            <Flex gap="large" vertical>
              {assetFlowIds?.assetId && <EmailAlertCard assetId={assetFlowIds?.assetId} onEmailAlertError={setError} />}
              {hideAlerts ? null : FormAndAlerts}
            </Flex>
          </Form>
          {error && <AlertBanner message={error.title} description={error.description} />}
        </ShowLoadingState>
      </Flex>
    </Drawer>
  )
}

const ShowLoadingState = ({ loading, children }: PropsWithChildren<{ loading: boolean }>) => {
  if (!loading) return <>{children}</>
  return (
    <div aria-label="Loading animation">
      <Skeleton active paragraph={{ rows: 4 }} title={false} />
    </div>
  )
}
