import styled, { css } from "styled-components"

const Param = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceSecondary};
    margin: 0 ${theme.spacing.vertical.xxxs} ${theme.spacing.vertical.xxxs} 0;
    padding: 0 ${theme.spacing.vertical.xxs};
    border-radius: ${theme.spacing.vertical.xxxs};
    font-weight: 600;
  `}
`

interface UpdatedParamProps {
  $isParamDeleted?: boolean
  $isParamAdded?: boolean
  $isOnDarkBackground?: boolean
  $isDeletionAction?: boolean
}

const UpdatedParam = styled(Param)<UpdatedParamProps>`
  ${({ theme, $isParamDeleted, $isParamAdded, $isOnDarkBackground, $isDeletionAction }) => css`
    ${($isParamDeleted || $isDeletionAction) &&
    css`
      text-decoration: line-through;
    `}
    ${$isParamAdded &&
    css`
      border: 1.5px solid ${theme.colors.primaryColors.gxPrimaryMedium};
    `}
    ${$isOnDarkBackground &&
    css`
      background-color: ${theme.colors.neutralColorPalette.whites.white};
    `}
  `}
`

export { Param, UpdatedParam }
