import { Grid } from "antd"

type ScreenMap = ReturnType<typeof Grid.useBreakpoint>
export type Breakpoint = keyof ScreenMap

// getScreenSize returns the maximum size recognized by AntD
// Note: beware the useBreakpoint sometimes returns an empty object!
export function getScreenSize(screenMap: ReturnType<typeof Grid.useBreakpoint>): Breakpoint {
  if (screenMap.xxl) {
    return "xxl"
  }
  if (screenMap.xl) {
    return "xl"
  }
  if (screenMap.lg) {
    return "lg"
  }
  if (screenMap.md) {
    return "md"
  }
  if (screenMap.sm) {
    return "sm"
  }

  return "xs"
}
