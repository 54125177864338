import { Button } from "src/ui/Button/Button"

type Props = {
  disabled: boolean
  onClick: () => void
}

export function EditDataAssetButton({ disabled, onClick }: Props) {
  return <Button key="edit" type="text" title="Edit Data Asset" icon="edit" onClick={onClick} disabled={disabled} />
}
