type LocalStorageItemName = "auth0Token" | "currentOrgId"

export function setLocalStorageItem(name: LocalStorageItemName, value: string) {
  localStorage.setItem(name, value)
}

export function getLocalStorageItem(name: LocalStorageItemName) {
  return localStorage.getItem(name)
}

export function removeLocalStorageItem(name: LocalStorageItemName) {
  localStorage.removeItem(name)
}
