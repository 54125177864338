/* eslint-disable react-refresh/only-export-components */ // FIXME
import styled, { DefaultTheme, css } from "styled-components"
import { StringRenderComponent } from "src/Expectation/StringRenderComponent"
import { parseMetaNotes } from "src/Expectation/utils/parsing"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { RenderedExpectationFragment, InteractiveExpectationFragment } from "src/api/graphql/graphql-operations"
import { BodyNormal } from "src/ui/typography/Text/Text"
import { JSONSchema } from "json-schema-to-ts"
import defaults from "json-schema-defaults"
import React from "react"
import { ExpectationConfiguration } from "src/api/graphql/graphql"

export enum ValueTypes {
  "atomicStringContent" = "StringValueType",
  "atomicTableContent" = "TableType",
  "atomicGraphContent" = "GraphType",
}

const getStatusColor = ({ theme, $success }: { theme: DefaultTheme; $success?: boolean | null }) => {
  switch ($success) {
    case true:
      return theme.colors.success.gxSuccess
    case false:
      return theme.colors.error.gxError
    default:
      return theme.colors.neutralColorPalette.blacks.colorFillTertiary
  }
}

export function getMetaNotes(renderedContent: RenderedExpectationFragment) {
  try {
    // assume it's a string, type errors will be caught by the try/catch
    const metanotes = renderedContent.value?.metaNotes as string
    return parseMetaNotes(metanotes)
  } catch {
    return null
  }
}

export function getMetaNotesFromExpectationConfiguration(expectationConfiguration: ExpectationConfiguration) {
  try {
    // assume it's a string, type errors will be caught by the try/catch
    const metaNotes = expectationConfiguration?.renderedContent?.[0]?.value?.metaNotes as string
    return metaNotes ? parseMetaNotes(metaNotes) : null
  } catch {
    console.error("Parsing meta notes from renderedContent failed.")
  }
}

export const ExpectationStatus = styled.div<{ $success?: boolean | null }>`
  background-color: ${getStatusColor};
  border: 2px solid ${getStatusColor};
  height: ${({ theme }) => theme.spacing.vertical.s};
  width: 0;
  border-radius: ${({ theme }) => theme.spacing.cornerRadius.small};
  margin-right: ${({ theme }) => theme.spacing.horizontal.xs};
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: ${({ theme }) => `${theme.spacing.vertical.xs}`};
`

export const StatusAndContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => `${theme.spacing.vertical.xs}`};

  /*
   * These next 2 lines are tricky, and aimed at making the Validation History
   * the correct width. Without flex-grow, the collapsed version is takes up
   * less than the full width. Without min-width, the expanded version grows
   * outside of the parent element. See https://stackoverflow.com/a/66689926.
   */
  flex-grow: 1;
  min-width: 0;
`

export const SpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface ExpectationContainerProps {
  success?: boolean | null
  children: React.ReactNode
}

export const ExpectationContainer = ({ success, children }: ExpectationContainerProps) => (
  <OuterContainer>
    <StatusAndContentContainer>
      <ExpectationStatus $success={success} />
      <FlexColumn>{children}</FlexColumn>
    </StatusAndContentContainer>
  </OuterContainer>
)

export function getRenderer({
  renderedValue,
  isValidationResult,
  fallback,
  isExpectationDeleted,
  isDeletionAction,
  kwargs,
  isPreview,
}: ExpectationRenderer) {
  switch (renderedValue?.value?.__typename) {
    case ValueTypes.atomicStringContent: {
      return (
        <StringRenderComponent
          value={renderedValue.value}
          evrConfig={isValidationResult ? { danger: false } : undefined}
          isExpectationDeleted={isExpectationDeleted}
          isDeletionAction={isDeletionAction}
          kwargs={kwargs}
          isPreview={isPreview}
          expectationType={fallback}
          isValidationResult={isValidationResult}
        />
      )
    }
    case ValueTypes.atomicTableContent:
      return fallback

    case ValueTypes.atomicGraphContent:
      return fallback
    default:
      return null
  }
}

export const ValidationResultCaptionRegular = styled(BodyNormal)`
  white-space: nowrap;
  ${({ theme }) => css`
    padding-right: ${theme.spacing.horizontal.xxs};
  `}
`

// parseColumnName attempts to parse the column name from an Expectation's domainKwargs.
// If it fails, it returns undefined.
export function parseColumnName(expectationConfig?: InteractiveExpectationFragment | null): string | undefined {
  try {
    if (typeof expectationConfig?.domain?.domainKwargs !== "string") {
      throw new Error(
        "type error: unable to parse domainKwargs. expected string, got " +
          typeof expectationConfig?.domain?.domainKwargs,
      )
    }
    const domainKwargs = JSON.parse(expectationConfig?.domain?.domainKwargs)
    if (!domainKwargs.column) {
      throw new Error(`no column name found in domain kwargs for expectation: ${expectationConfig?.expectationType}`)
    }
    return domainKwargs.column
  } catch {
    return undefined
  }
}

export function getJsonTemplateFromJsonSchema(schema: JSONSchema | undefined): string {
  const defaultTemplate = defaults(schema || {})

  const template = defaultTemplate
  delete template.metadata
  delete template.rendered_content

  return JSON.stringify(removeAdvancedKwargs(template), null, 4)
}

export function getJsonTemplateFromJsonSchemaSimplified(schema: JSONSchema | undefined) {
  const { metadata, rendered_content, ...template } = defaults(schema || {})

  return removeAdvancedKwargs(template)
}

export function removeAdvancedKwargs(expectationConfig: Record<string, Record<string, unknown>>) {
  if ("result_format" in expectationConfig) {
    delete expectationConfig.result_format
  }
  if ("catch_exceptions" in expectationConfig) {
    delete expectationConfig.catch_exceptions
  }
  // in GX Cloud this will always be set to string `great_expectations`
  if ("condition_parser" in expectationConfig) {
    delete expectationConfig.condition_parser
  }
  if ("meta" in expectationConfig) {
    delete expectationConfig.meta
  }
  if ("rendered_content" in expectationConfig) {
    delete expectationConfig.rendered_content
  }
  return expectationConfig
}
