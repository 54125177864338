import { Drawer } from "src/ui/Drawer/Drawer"
import { Button } from "antd"
import { CodeIcon } from "src/ui/Icon/CodeIcon"

interface DrawerFooterProps {
  onValidate: () => void
  onValidateSnippet: () => void
  validationLoading: boolean
  saveDisabled?: boolean
  validationButtonsDisabled: boolean
  buttonLabel: string
}

function ValidateSubsetDrawerFooter({
  onValidateSnippet,
  onValidate,
  validationLoading,
  saveDisabled = false,
  validationButtonsDisabled = true,
  buttonLabel,
}: DrawerFooterProps) {
  return (
    <Drawer.Footer>
      <Button
        size="large"
        style={{ display: "flex", alignItems: "center" }}
        icon={<CodeIcon />}
        onClick={() => onValidateSnippet()}
        disabled={validationLoading || validationButtonsDisabled}
      >
        Code snippet
      </Button>
      <Drawer.FooterButton
        icon="play"
        type="primary"
        onClick={() => onValidate()}
        loading={validationLoading}
        disabled={saveDisabled || validationButtonsDisabled}
      >
        {buttonLabel}
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}

export { ValidateSubsetDrawerFooter }
