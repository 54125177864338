/* eslint-disable react-refresh/only-export-components */ // FIXME
import {
  CellProps,
  ControlProps,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  rankWith,
} from "@jsonforms/core"
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react"

export function UnknownRenderer(props: ControlProps | CellProps) {
  // console.log({ schema: props.schema, path: props.path, schemaPath: props.schemaPath, props })
  return (
    <div style={{ color: "red" }}>
      No applicable renderer found for {props.schema?.type} type field &quot;{props.path}&quot;.
    </div>
  )
}

export const UnknownRendererRegistryEntry: JsonFormsRendererRegistryEntry = {
  renderer: withJsonFormsControlProps(UnknownRenderer),
  tester: rankWith(1, () => true),
}
export const UnknownCellRegistryEntry: JsonFormsCellRendererRegistryEntry = {
  cell: withJsonFormsCellProps(UnknownRenderer),
  tester: rankWith(1, () => true),
}
