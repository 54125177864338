/**
 * Modified version of code shared here: https://github.com/ant-design/ant-design/issues/19096
 * Example usage:
 *    <SkeletonTable
 *      loading={true}
 *      columns={columns as SkeletonTableColumnsType[]}
 *      caption={<Header latestMetricRun={dataAssetQueryResult.dataAsset.latestMetricRun} dataAssetId={assetId} />}
 *    >
 *      <Table
 *        caption={<Header latestMetricRun={dataAssetQueryResult.dataAsset.latestMetricRun} dataAssetId={assetId} />}
 *        dataSource={dataSource}
 *        columns={columns}
 *        pagination={false}
 *        scroll={{ x: "10px" }} // this ensures that a horizontal scrollbar is not always shown -- see https://github.com/ant-design/ant-design/issues/15794
 *       />
 *     </SkeletonTable>
 */

import { Skeleton, SkeletonProps, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { ReactNode } from "react"

export type SkeletonTableColumnsType = {
  key: string
}

type SkeletonTableProps = SkeletonProps & {
  columns: ColumnsType<SkeletonTableColumnsType>
  rowCount?: number
  caption?: ReactNode
}

export function SkeletonTable({
  loading = false,
  rowCount = 5,
  round = false,
  columns,
  children,
  className,
  caption,
}: SkeletonTableProps): JSX.Element {
  return loading ? (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((data, index) => ({
        key: data?.key || `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton key={column.key} title active={loading} paragraph={false} className={className} round={round} />
            )
          },
        }
      })}
      caption={caption}
    />
  ) : (
    <>{children}</>
  )
}
