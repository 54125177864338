import { Popover, Flex, Form, Select, Alert } from "antd"
import { useCallback, useState } from "react"
import styled, { useTheme } from "styled-components"
import { useMutation, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"

import { Segmented } from "src/ui/Segmented/Segmented"
import { Button } from "src/ui/Button/Button"
import { Metric, SplitterType, SplitterUnion } from "src/api/graphql/graphql"
import { Heading3 } from "src/ui/typography/Text/Text"
import { Image } from "src/ui/Image"
import { EditBatchForm_SplitterFragmentDocument } from "src/DataAssets/AssetDetails/Expectations/EditBatchFormGQL"
import { isWholeAssetSplitterType } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"

export const EditBatchDataAssetWithLatestMetricRunDocument = graphql(`
  query EditBatchDataAssetWithLatestMetricRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      splitter {
        __typename
        ...EditBatchForm_Splitter
      }
      latestMetricRun {
        metrics {
          columnDataType
          columnName
        }
      }
    }
  }
`)

export function EditBatchPopover({ dataAssetId }: { dataAssetId: string }) {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const { data, loading } = useQuery(EditBatchDataAssetWithLatestMetricRunDocument, {
    variables: {
      id: dataAssetId ?? "",
    },
    skip: !dataAssetId,
  })

  const dateTimeColumns =
    !loading && data?.dataAsset?.latestMetricRun?.metrics
      ? getDateTimeColumns(data?.dataAsset?.latestMetricRun?.metrics ?? [])
      : []

  return (
    <Popover
      overlayInnerStyle={{ padding: theme.spacing.vertical.s }}
      placement="bottomLeft"
      arrow={false}
      destroyTooltipOnHide
      title={
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <Flex gap={theme.spacing.horizontal.xxs}>
            <Image aria-label="batch" type="batch" />
            <Heading3>Define batch</Heading3>
          </Flex>
          <Flex justify="flex-end">
            <Button
              icon="close"
              type="text"
              iconSize="18px"
              aria-label="Close batch edit window"
              onClick={() => setIsOpen(false)}
            />
          </Flex>
        </Flex>
      }
      content={
        data?.dataAsset?.splitter && (
          <EditBatchForm
            onClose={() => setIsOpen(false)}
            splitter={data.dataAsset.splitter}
            dateTimeColumns={dateTimeColumns}
            dataAssetId={dataAssetId}
            setIsOpen={setIsOpen}
          />
        )
      }
      trigger="click"
      open={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
    >
      <Button
        aria-label="Edit batch"
        disabled={!data?.dataAsset?.splitter}
        onClick={() => setIsOpen(!isOpen)}
        style={{ marginRight: theme.spacing.horizontal.xs }}
      >
        Edit batch
      </Button>
    </Popover>
  )
}

const getDateTimeColumns = (metrics: Metric[]) => {
  const isTimeBasedTypeRegex = "(TIME|DATE)"
  return metrics.filter((metric) => metric.columnDataType?.match(isTimeBasedTypeRegex))
}

export const EditBatchDefinitionMutationDocument = graphql(`
  mutation UpdateSplitter($input: UpdateSplitterInput!) {
    updateSplitter(input: $input) {
      splitter {
        ...BatchDefinitionDescription_Splitter
      }
    }
  }
`)

type BatchForm = { splitterType: SplitterType; columnName?: string }
type EditBatchFormProps = {
  onClose: () => void
  dateTimeColumns: Metric[]
  dataAssetId: string
  setIsOpen: (isOpen: boolean) => void
  splitter: FragmentType<typeof EditBatchForm_SplitterFragmentDocument>
}

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.vertical.xs};
`

export function EditBatchForm({
  onClose,
  dateTimeColumns,
  splitter: maskedSplitter,
  dataAssetId,
  setIsOpen,
}: EditBatchFormProps) {
  const splitter = unmaskFragment(EditBatchForm_SplitterFragmentDocument, maskedSplitter)
  const theme = useTheme()
  const [form] = Form.useForm()
  const hasDateTimeColumns = dateTimeColumns.length > 0
  const description = hasDateTimeColumns
    ? "Validate all records by selecting Entire Asset. Validate your Asset incrementally by selecting a time-based batch interval."
    : "Your Asset needs a column of datetime type to enable a time-based batch interval."
  const [editBatchDefinition, { loading, error }] = useMutation(EditBatchDefinitionMutationDocument)
  const [showColItem, setShowColItem] = useState(!isWholeAssetSplitterType(splitter.__typename) && hasDateTimeColumns)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const handleFinish = useCallback(
    async (fields: BatchForm) => {
      if (!dataAssetId) {
        return // not a possibility; just assuring the compiler here
      }
      const { columnName, splitterType } = fields

      await editBatchDefinition({
        variables: {
          input: {
            id: splitter?.id,
            splitterConfig: {
              splitterType,
              ...(columnName ? { columnName } : {}),
            },
          },
        },
      })
      onClose()
    },
    [dataAssetId, editBatchDefinition, onClose, splitter?.id],
  )

  const handleIntervalChange = (value: SplitterType) => {
    value === "wholeAsset" ? setShowColItem(false) : setShowColItem(hasDateTimeColumns)
  }

  return (
    <>
      <Alert style={{ marginBottom: theme.spacing.vertical.s, width: "410px" }} description={description} />
      <Form<BatchForm> layout="vertical" onFinish={handleFinish} onFieldsChange={() => setIsFormTouched(true)}>
        <Form.Item
          name="splitterType"
          label="Batch interval"
          rules={[{ required: true, message: "Batch interval is required" }]}
          initialValue={splitterTypeNameToSplitterType(splitter.__typename)}
        >
          <Segmented
            options={(["day", "month", "year", "wholeAsset"] satisfies Array<SplitterType>).map((value) => ({
              value,
              label: splitterTypeToDisplayLabel(value),
            }))}
            onChange={handleIntervalChange}
            disabled={!hasDateTimeColumns}
          />
        </Form.Item>
        {showColItem && (
          <Form.Item
            name="columnName"
            label="Validate by"
            rules={[{ required: true, message: "Validate by is required" }]}
            initialValue={"columnName" in splitter ? splitter.columnName : undefined}
          >
            <Select
              options={dateTimeColumns.map((metric) => ({
                value: metric.columnName,
                label: metric.columnName,
              }))}
              placeholder="Select a DATE or DATETIME column"
            />
          </Form.Item>
        )}
        <Form.Item {...(error && { validateStatus: "error", help: error.message })}>
          <Flex justify="flex-end" gap={theme.spacing.horizontal.xxs}>
            <StyledButton
              aria-label="cancel"
              onClick={() => {
                setIsOpen(false)
                form.resetFields()
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="primary"
              loading={loading}
              disabled={!isFormTouched}
              htmlType="submit"
              aria-label="Update Batch"
            >
              Save
            </StyledButton>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
}

function splitterTypeNameToSplitterType(typename: SplitterUnion["__typename"]): SplitterType | null {
  switch (typename) {
    case "SplitterYear":
      return "year"
    case "SplitterYearAndMonth":
      return "month"
    case "SplitterYearAndMonthAndDay":
      return "day"
    case "SplitterWholeAsset":
      return "wholeAsset"
  }
  return null
}

function splitterTypeToDisplayLabel(splitterType: SplitterType): string {
  switch (splitterType) {
    case "year":
      return "Year"
    case "month":
      return "Month"
    case "day":
      return "Day"
    case "wholeAsset":
      return "Entire Asset"
  }
}
