import { AppLink } from "src/ui/AppLink/AppLink"
import { Icon, IconTypes } from "src/ui/Icon"
import styled from "styled-components"

interface BreadcrumbTitleProps {
  icon?: IconTypes
  text?: string | null
  href?: string
}

const StyledAppLink = styled(AppLink)`
  &&:hover,
  &&:focus,
  &&:active,
  &&:visited,
  &&:link,
  &&:any-link {
    color: currentcolor;
  }
`

// BreadcrumbTitle provides styling for the Breadcrumb ItemType['title'] value.
// e.g. <Breadcrumb items={[{title: <BreadcrumbTitle text="hello" />}]} />
export function BreadcrumbTitle(props: BreadcrumbTitleProps): JSX.Element {
  const { icon: iconName, text, href } = props

  const icon = iconName ? <Icon name={iconName} size="14px" paddingRight="8px" /> : null

  const contents: JSX.Element = (
    <>
      {icon}
      {text}
    </>
  )

  if (href) {
    return <StyledAppLink to={href}>{contents}</StyledAppLink>
  }

  return contents
}
