import { List } from "antd"
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations"
import { Button } from "src/ui/Button/Button"
import { getImageLogo } from "src/DataAssets/getImageLogo"
import { StyledLogoContainer } from "src/ui/List/MainContentList"
import { useIsDataSourceEditable } from "src/common/hooks/useIsDataSourceEditable"
import { BodyStrong, DescriptionNormal } from "src/ui/typography/Text/Text"

interface Props {
  dataSource: DatasourceWithRunsFragment
  onDelete: () => void
  onEdit: () => void
}

export function ManageDatasourceListItem({ dataSource, onDelete, onEdit }: Props) {
  const isEditable = useIsDataSourceEditable(dataSource.type)
  return (
    <List.Item
      actions={[
        <Button type="text" title="Edit Data Source" key="edit" icon="edit" onClick={onEdit} disabled={!isEditable} />,
        <Button title="Delete Data Source" type="text" key="delete" icon="trash" onClick={() => onDelete()} />,
      ]}
    >
      <List.Item.Meta
        title={<BodyStrong>{dataSource.name}</BodyStrong>}
        avatar={
          <StyledLogoContainer>
            <img src={getImageLogo(dataSource.type)} alt={`${dataSource.type} logo`} />
          </StyledLogoContainer>
        }
        description={<DescriptionNormal>{dataSource.assets.length} Assets</DescriptionNormal>}
      />
    </List.Item>
  )
}
