/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from "src/ui/Icon/Icon"
import { graphql } from "src/api/graphql/gql"
import { ApolloError, useApolloClient, useMutation } from "@apollo/client"
import { useJobByID } from "src/common/hooks/useJobByID"
import { useEffect, useState } from "react"
import { notification } from "antd"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { NOTIFICATION_WITH_LINK_DURATION_SECONDS } from "src/common/config"
import { SplitterOptionsInput } from "src/api/graphql/graphql"
import { BatchDefinitionForValidationRunPopOver } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/BatchDefinitionForValidationRunPopOver/BatchDefinitionForValidationRunPopOver"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"
import { Link } from "src/ui/typography/Text/Text"
import { Button } from "src/ui/Button/Button"
import { ExpectationsTab_GetExpectationsDocument } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationsTab"

export const VALIDATE_SUCCESS_TEXT = "Data Asset validation complete."
export const VALIDATE_ERROR_TEXT = "Unable to validate Data Asset."
export const SERVER_ERROR_TITLE = "Server Error"
export const VALIDATE_BUTTON_TEXT = "Validate"
export const UNABLE_TO_VALIDATE_SERVER_ERROR_DESCRIPTION = (
  <>
    We were unable to validate your Asset. If the problem persists, contact <SupportEmailLink />
  </>
)
export const VALIDATE_ERROR_DESCRIPTION = (
  <>
    See{" "}
    <Link to="/logs" strong>
      logs
    </Link>{" "}
    for details.
  </>
)

export const CreateRunGXManagedCheckpointJobDocument = graphql(`
  mutation CreateRunGXManagedCheckpointJob($input: CreateRunGxManagedCheckpointJobInput!) {
    createRunGxManagedCheckpointJob(input: $input) {
      jobId
    }
  }
`)

type RunValidationButtonProps = {
  dataAssetId: string
  hasBatchDefinition: boolean
}

export type ValidationStatus =
  | "inProgress"
  | "complete"
  | "error"
  | "inactive"
  | "agentNotConnected"
  | "setBatchDefinition"

export function RunValidationButton({ dataAssetId, hasBatchDefinition }: RunValidationButtonProps) {
  const client = useApolloClient()
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>("inactive")
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  const [createJob, { data }] = useMutation(CreateRunGXManagedCheckpointJobDocument)
  const jobId = data?.createRunGxManagedCheckpointJob?.jobId

  const runJob = (splitterOptions?: SplitterOptionsInput) => {
    setValidationStatus("inProgress")
    dataAssetId &&
      createJob({
        variables: { input: { dataAssetId, splitterOptions } },
        onError: (error: ApolloError) => {
          notificationApi.error({
            message: SERVER_ERROR_TITLE,
            description: UNABLE_TO_VALIDATE_SERVER_ERROR_DESCRIPTION,
            duration: 0,
            placement: "top",
          })
          console.error(error.message)
          setValidationStatus("error")
        },
      })
  }

  const onValidateButtonClick = () => {
    if (validationStatus === "setBatchDefinition") return
    if (hasBatchDefinition && validationStatus !== "inProgress") return setValidationStatus("setBatchDefinition")
    runJob()
  }

  const job = useJobByID(jobId)

  /*
   * Handle validationStatus based on job status
   */
  useEffect(() => {
    if (job?.status) {
      switch (job?.status) {
        case "complete":
          // Forcing refetch expectations here to update the validation
          // We can do better if job will return the updated validation result
          // Remove after https://greatexpectations.atlassian.net/browse/LAK-1314 is done
          client.refetchQueries({
            include: [ExpectationsTab_GetExpectationsDocument],
          })
          notificationApi.success({
            message: VALIDATE_SUCCESS_TEXT,
            duration: NOTIFICATION_WITH_LINK_DURATION_SECONDS,
            placement: "top",
          })
          setValidationStatus("complete")
          break
        case "error":
          if (job.jobError?.errorStackTrace?.includes("No Agent is connected")) {
            setValidationStatus("agentNotConnected")
          } else {
            notificationApi.error({
              message: VALIDATE_ERROR_TEXT,
              description: VALIDATE_ERROR_DESCRIPTION,
              duration: 0,
              placement: "top",
            })
            setValidationStatus("error")
          }
          break
        case "queued":
        case "inProgress":
          setValidationStatus("inProgress")
          break
        default:
          setValidationStatus("inactive")
      }
    }
  }, [client, job?.jobError?.errorStackTrace, job?.status, notificationApi])

  const validateBtn = (
    <Button
      type="primary"
      icon={<Icon name="play" size="14px" />}
      onClick={onValidateButtonClick}
      loading={validationStatus === "inProgress"}
    >
      {VALIDATE_BUTTON_TEXT}
    </Button>
  )

  return (
    <>
      {notificationContextHolder}
      <AgentNotConnectedModal
        isVisible={validationStatus === "agentNotConnected"}
        setIsVisible={() => setValidationStatus("inactive")}
      />
      {hasBatchDefinition ? (
        <BatchDefinitionForValidationRunPopOver
          onClose={() => {
            setValidationStatus("inactive")
          }}
          onSave={runJob}
        >
          {/* TODO: Fix after SUJE release tkt - https://greatexpectations.atlassian.net/browse/LAK-1269
        <BifoldButton
          style={{ width: "150px" }}
          type="primary"
          icon={<CodeIcon />}
          onClick={onValidateButtonClick}
          loading={validationStatus === "inProgress"}
          menu={{
            items: [
              {
                label: "Generate Snippet",
                key: "snippet",
                onClick: () => {},
                disabled: true,
              },
            ],
          }}
        >
          <Icon name="play" size="14px" />
          {VALIDATE_BUTTON_TEXT}
        </BifoldButton> */}
          {validateBtn}
        </BatchDefinitionForValidationRunPopOver>
      ) : (
        validateBtn
      )}
    </>
  )
}
