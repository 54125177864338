/* eslint-disable react-refresh/only-export-components */ // FIXME
import { SmileOutlined } from "@ant-design/icons"
import { Result } from "antd"
import {
  Expectation_ValidationResultFragment,
  ValidationResultColumnNamesQuery,
} from "src/api/graphql/graphql-operations"
import { GroupName } from "src/Expectation/words"

export const NoFailuresScreen = () => (
  <Result
    icon={<SmileOutlined style={{ color: "black" }} />}
    title="Nice job!"
    subTitle="No failures detected in your pipeline."
  />
)

export const getFailedValidationResults = (
  validationResultGroups: Record<string, Expectation_ValidationResultFragment[]> | undefined,
) => {
  let failedGroup = {}
  for (const groupName in validationResultGroups) {
    const failedResults = validationResultGroups[groupName].filter((result) => !result.success)
    if (failedResults.length) {
      failedGroup = { ...failedGroup, [groupName]: failedResults }
    }
  }
  return failedGroup
}

export const searchColumnNames = (columnNamesData: ValidationResultColumnNamesQuery | undefined, search: string) => {
  const columnNames = columnNamesData?.validationResult?.columnNames
  if (!columnNames || !columnNames.length) {
    return []
  }
  const allColumnNames = [GroupName.TABLE, ...columnNames.filter((cn): cn is string => !!cn)]
  return allColumnNames.filter((name) => name.toLowerCase().includes(search.toLowerCase()))
}

export const getFilteredSearchedList = (
  filteredValidations: Record<string, Expectation_ValidationResultFragment[]> | undefined,
  columnNames: string[],
) => {
  const filteredList = Object.keys(filteredValidations ?? []).map((v) => v.toLowerCase())
  return columnNames.filter((cn) => filteredList.includes(cn.toLowerCase()))
}

export function parseJSONStringConfigDEPRECATED(jsonstring: string) {
  let config
  try {
    config = JSON.parse(jsonstring)
  } catch (e) {
    return {}
  }
  return config
}

export function parseJSONStringConfig(jsonstring: string): unknown {
  let config
  try {
    config = JSON.parse(jsonstring)
  } catch (e) {
    return {}
  }
  return config
}
