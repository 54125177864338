import { gitHash } from "src/scripts/git-hash"
import { graphiql, addOrganization, addUser } from "src/scripts/graphiql"

// hidden functionality for use with devtools.
// lets us type with addOrganization({name: <NAME>, title: <TITLE>}) in devtools
// parses our jwt to get a token, and opens up graphiq.
// Mostly tools around onboarding right now, more will be added later.

export const loadHiddenScripts = () => {
  // This must be an object, rather than an array
  // because the keys of the array will preserve the function name,
  // while the function itself is minified in prod
  const hiddenFunctions = { graphiql, addOrganization, addUser, gitHash }
  for (const [name, func] of Object.entries(hiddenFunctions)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any)[name] = func
  }
}
