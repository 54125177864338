import { JsonForms } from "@jsonforms/react"
import { JsonFormsRendererRegistryEntry, JsonFormsUISchemaRegistryEntry, JsonSchema7, createAjv } from "@jsonforms/core"
import { cellRegistryEntries, ossRendererRegistryEntries } from "src/jsonforms/renderers"
import { FromSchema, JSONSchema } from "json-schema-to-ts"
import { RecursivePartial } from "src/common/utils/recursive-partial"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { ConfigProvider } from "antd"
import { theme } from "src/ui/themes/theme"

export type FormData<T extends JSONSchema> = RecursivePartial<FromSchema<T>>
export type ConfigData<T extends JSONSchema> = FromSchema<T>

type Props<T> = {
  data: Record<string, unknown>
  updateData: (data: Record<string, unknown>) => void
  jsonSchema: T
  uiSchema?: UISchema<T>
  uiSchemaRegistryEntries?: JsonFormsUISchemaRegistryEntry[]
  customRendererRegistryEntries?: JsonFormsRendererRegistryEntry[]
  config?: Record<string, unknown>
}

export function JsonForm<T>({
  uiSchema,
  jsonSchema,
  data,
  updateData,
  uiSchemaRegistryEntries,
  customRendererRegistryEntries,
  config,
}: Props<T>) {
  const handleDefaultsAjv = createAjv({ useDefaults: true })

  return (
    <ConfigProvider
      theme={{
        components: {
          List: {
            headerBg: "unset",
          },
          Radio: {
            buttonSolidCheckedActiveBg: "black",
            buttonSolidCheckedBg: "black",
            colorPrimary: "black",
          },
          Segmented: {
            itemSelectedBg: theme.colors.neutralColorPalette.blacks.colorText,
            itemSelectedColor: theme.colors.neutralColorPalette.whites.white,
            colorTextDisabled: theme.colors.neutralColorPalette.blacks.colorTextQuaternary,
            controlItemBgActiveDisabled: theme.colors.neutralColorPalette.blacks.colorTextQuaternary,
          },
        },
      }}
    >
      <JsonForms
        schema={jsonSchema as JsonSchema7} // bleh
        uischema={uiSchema}
        uischemas={uiSchemaRegistryEntries ?? []}
        data={data}
        onChange={({ data }) => updateData(data)}
        cells={[...cellRegistryEntries]}
        renderers={[...ossRendererRegistryEntries, ...(customRendererRegistryEntries ?? [])]}
        config={config}
        ajv={handleDefaultsAjv}
      />
    </ConfigProvider>
  )
}
