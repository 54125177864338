import { pluralize } from "src/common/utils/strings"

export const getAddAssetButtonText = (
  isCACPart2Enabled: boolean,
  jobIsPending: boolean,
  selectedTableNamesLength: number,
): string => {
  if (!isCACPart2Enabled) {
    return "Finish"
  }
  if (jobIsPending || selectedTableNamesLength === 0) {
    return "Add Asset"
  }
  return `Add ${selectedTableNamesLength} ${pluralize("Asset", selectedTableNamesLength)}`
}
