import { JsonFormsUISchemaRegistryEntry, JsonSchema } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { SqlDataAssetQueryJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/sql-data-asset-query-json-schema"
import { SqlDataAssetTableJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/sql-data-asset-table-json-schema"

export const SqlQueryAssetUISchema: UISchema<typeof SqlDataAssetQueryJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/query",
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Asset name",
    },
  ],
}

// TODO: actually use this
export const SqlQueryAssetUISchemaRegistryEntry = (): JsonFormsUISchemaRegistryEntry => ({
  uischema: SqlQueryAssetUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    const rank = schema.title === "QueryAsset" && schemaPath === "#" ? 2 : -1
    // console.log("SqlQueryAsset Tester", { rank, schema, schemaPath, path })
    return rank
  },
})

export const SqlTableAssetUISchema: UISchema<typeof SqlDataAssetTableJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/table_name",
      label: "Table name",
      options: {
        required: true,
        placeholderText: "the table name as defined in the database",
        rules: [
          {
            pattern: new RegExp("^(?! ).*(?<! )$"), // no leading or trailing spaces
            message: "Table name cannot start or end with a space",
          },
        ],
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Asset name",
      options: { placeholderText: "a name for the Data Asset", rules: [] },
    },
  ],
}

// TODO: actually use this
export const SqlTableAssetUISchemaRegistryEntry = (): JsonFormsUISchemaRegistryEntry => ({
  uischema: SqlTableAssetUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    const rank = schema.title === "TableAsset" && schemaPath === "#" ? 2 : -1
    // console.log("TableAssetUISchema Tester", { rank, schema, schemaPath, path })
    return rank
  },
})
