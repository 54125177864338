export const DEFAULT_UNEXPECTED_ERROR_DESCRIPTION = "An unexpected error occurred."

export enum JobErrorCodeEnum {
  GENERIC_UNHANDLED_ERROR = "generic-unhandled-error",
  WRONG_USERNAME_OR_PASSWORD = "wrong-username-or-password",
  AGENT_JOB_TIMEOUT = "agent-job-timeout",
  RUNNER_JOB_TIMEOUT = "runner-job-timeout",
}

const jobErrorCodeToDescriptionMap: Map<JobErrorCodeEnum, string> = new Map([
  [JobErrorCodeEnum.GENERIC_UNHANDLED_ERROR, DEFAULT_UNEXPECTED_ERROR_DESCRIPTION],
  [
    JobErrorCodeEnum.WRONG_USERNAME_OR_PASSWORD,
    "Unable to connect to the database. Confirm the username or password is correct.",
  ],
  [JobErrorCodeEnum.AGENT_JOB_TIMEOUT, "Job timeout exceeded - no response received from the GX Agent."],
  [JobErrorCodeEnum.RUNNER_JOB_TIMEOUT, "Job timeout exceeded."],
])

//TODO: update this method to handle error params once we have an error type that uses them
export function translateJobErrorToDescription(jobErrorCode: string): string {
  try {
    const errorCode = jobErrorCode as JobErrorCodeEnum
    const description = jobErrorCodeToDescriptionMap.get(errorCode)
    return description || DEFAULT_UNEXPECTED_ERROR_DESCRIPTION
  } catch (error) {
    console.error("Error occurred while casting jobErrorCode to JobErrorCodeEnum:", error)
    return DEFAULT_UNEXPECTED_ERROR_DESCRIPTION
  }
}
