import { OrganizationRole } from "src/api/graphql/graphql"
import { getRoleLevel } from "src/organizations/roles"
import { useOrganizations } from "src/organizations/useOrganizations"

// useRequireRole returns true if the current user has the specified minimum role or higher
// if strict is true, it returns true only if the current user has the exact role specified
function useRequireRole(role: OrganizationRole, strict = false) {
  const { currentOrg } = useOrganizations()
  const currentRole = currentOrg?.role

  if (!currentRole) {
    return false
  }

  if (strict) {
    return currentRole === role
  }

  const roleLevel = getRoleLevel(role)
  const currentRoleLevel = getRoleLevel(currentRole)

  return currentRoleLevel <= roleLevel
}

export { useRequireRole }
