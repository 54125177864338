import { Select as AntDSelect, SelectProps } from "antd"
import { PropsWithChildren, useState } from "react"
import { SelectSuffixIcon } from "src/ui/Select/SelectSuffixIcon"

// This is a light wrapper around AntD's select intended to give us a suffix Icon (caret + search Icon)
export function Select({ children, ...props }: PropsWithChildren<SelectProps>) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  return (
    <AntDSelect
      {...props}
      filterOption={(inputValue: string, option) => {
        if (option?.title && String(option.title).toLowerCase().includes(inputValue.toLowerCase())) {
          return true
        }
        return false
      }}
      onDropdownVisibleChange={(open) => {
        if (props.onDropdownVisibleChange) {
          props.onDropdownVisibleChange(open)
        }
        setIsDropdownVisible(open)
      }}
      suffixIcon={<SelectSuffixIcon isDropdownVisible={isDropdownVisible} showSearch={!!props.showSearch} />}
    >
      {children}
    </AntDSelect>
  )
}

export const SelectOption = AntDSelect.Option
