/* eslint-disable react-refresh/only-export-components */ // FIXME
import { withJsonFormsControlProps } from "@jsonforms/react"
import { ControlElement, JsonFormsRendererRegistryEntry, rankWith, scopeEndsWith, or } from "@jsonforms/core"
import { Form } from "antd"
import { Metric } from "src/api/graphql/graphql"
import { useEffect, useMemo } from "react"
import { startCase } from "lodash-es"
import { ColumnNameSelector } from "src/Expectation/uiForms/customRenderers/ColumnNameSelector"
import { DataAssetWithLatestMetricRunFragment } from "src/api/graphql/graphql-operations"

export const ColumnNameRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    90, //increase rank as needed
    or(
      scopeEndsWith("#/properties/column"),
      scopeEndsWith("#/properties/column_A"),
      scopeEndsWith("#/properties/column_B"),
    ),
  ),
  renderer: withJsonFormsControlProps(ColumnNameControl),
}

type ColumnNameControlProps = {
  handleChange: (path: string, value: unknown) => void
  path: string
  data: Record<string, unknown>
  uischema: ControlElement
  config?: { dataAssetWithLatestMetricRun: DataAssetWithLatestMetricRunFragment }
  required?: boolean
}

function ColumnNameControl({ handleChange, config, path, data, required, uischema }: ColumnNameControlProps) {
  const options = useMemo(
    () =>
      config?.dataAssetWithLatestMetricRun?.latestMetricRun?.metrics.map((metric: Metric) => ({
        value: metric.columnName,
        label: metric.columnName,
      })),
    [config],
  )

  const form = Form.useFormInstance()

  // using this mostly to set the initial value https://github.com/ant-design/ant-design/issues/22372
  useEffect(() => {
    if (form) {
      form.setFieldsValue({ [path]: data || null })
    }
  }, [data, path, form])

  const label = startCase(path.split(".").pop())
  return (
    <Form.Item
      name={path}
      label={label}
      tooltip={
        (
          uischema as ControlElement & {
            formItemProps: {
              tooltip?: string
            }
          }
        )?.formItemProps?.tooltip
      }
      rules={[
        {
          required: required,
          message: "Column name is required",
        },
      ]}
      validateTrigger="onBlur"
    >
      <ColumnNameSelector options={options} onChange={(value) => handleChange(path, value)} label={label} path={path} />
    </Form.Item>
  )
}
