import ReactMarkdown from "react-markdown"
import rehypeExternalLinks from "rehype-external-links"
import React from "react"
import { NoteCard } from "src/ui/Card/Card"

interface MetaNotesProps {
  metaNotes: {
    format: string
    content: Array<string>
  }
}

interface MarkdownProps {
  children: string
}

const String: React.FC<MarkdownProps> = ({ children }) => <div>{children}</div>

const Markdown: React.FC<MarkdownProps> = ({ children }) => (
  <ReactMarkdown rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["nofollow", "noreferrer"] }]]}>
    {children}
  </ReactMarkdown>
)

export const MetaNotes = ({ metaNotes }: MetaNotesProps) => {
  const ContentWrapper = metaNotes.format === "markdown" ? Markdown : String

  return (
    <NoteCard>
      {metaNotes.content.map((element, index) => (
        <ContentWrapper key={index}>{element}</ContentWrapper>
      ))}
    </NoteCard>
  )
}
