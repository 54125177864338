/* eslint-disable react-refresh/only-export-components */ // FIXME
import { MainContent } from "src/layout/MainContent"
import { PageHeader } from "src/ui/PageHeader"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { JobsList } from "src/GXAgent/JobsList"
import { AgentInactiveAlert } from "src/GXAgent/AgentInactiveAlert"
import { useQuery } from "@apollo/client"
import { DatasourcesDocument } from "src/api/graphql/graphql-operations"
import { useAgentStatus } from "src/common/hooks/useAgentStatus"
import { Tag, Tooltip } from "antd"
import { theme } from "src/ui/themes/theme"
import { AppLink } from "src/ui/AppLink/AppLink"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"

export const LOGS_PATH_NAME = "logs" as const

const AGENT_INACTIVE_TOOLTIP = (
  <>
    To activate the GX Agent, see{" "}
    <AppLink
      to="https://docs.greatexpectations.io/docs/cloud/deploy_gx_agent/"
      newTab={true}
      style={{
        color: theme.colors.neutralColorPalette.whites.white,
        textDecorationLine: "underline",
        fontWeight: "600",
        lineHeight: "5px",
      }}
    >
      Deploy the GX Agent
    </AppLink>
    .
  </>
)

export function GXAgent() {
  useQuery(DatasourcesDocument, { canonizeResults: true })

  const isGxAgentEnabled = useIsGXAgentEnabled()
  const isAgentActive = useAgentStatus()

  let agentStatusText: string | JSX.Element = "Initializing Agent"
  let agentStatusTooltip: string | JSX.Element = ""
  let agentStatusBadgeColor: string = theme.colors.status.gxBadgeInitializing
  let toolTipEnabled = false

  if (isAgentActive !== undefined) {
    if (isAgentActive) {
      agentStatusText = agentStatusTooltip = "Active Agent"
      agentStatusBadgeColor = theme.colors.status.gxBadgeActive
    } else {
      agentStatusText = "Inactive Agent"
      agentStatusTooltip = AGENT_INACTIVE_TOOLTIP
      agentStatusBadgeColor = theme.colors.status.gxBadgeInactive
      toolTipEnabled = true
    }
  }
  const baseAgentTag: JSX.Element = (
    <Tag color={agentStatusBadgeColor} style={{ cursor: "default" }}>
      {agentStatusText}
    </Tag>
  )
  let agentTag: JSX.Element = baseAgentTag
  if (toolTipEnabled) {
    agentTag = (
      <Tooltip placement="right" title={agentStatusTooltip}>
        {baseAgentTag}
      </Tooltip>
    )
  }

  const headerContent: HeaderTypes = {
    title: <>Logs {isGxAgentEnabled && agentTag}</>,
    rootPath: `/${LOGS_PATH_NAME}`,
  }

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {isGxAgentEnabled && !isAgentActive && <AgentInactiveAlert />}
        <JobsList />
      </MainContent>
    </PageHeader>
  )
}

export { AGENT_INACTIVE_TOOLTIP }
