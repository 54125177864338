import { FragmentType, graphql, unmaskFragment } from "src/api/graphql"

export const UseDemoDataAssetName_DataAssetFragmentDoc = graphql(`
  fragment UseDemoDataAssetName_DataAsset on AssetRef {
    name
  }
`)

const DEMO_DATA_ASSETS = ["nyc_taxi_data"] as const

export type DemoDataAssetName = (typeof DEMO_DATA_ASSETS)[number]

type FragmentData = FragmentType<typeof UseDemoDataAssetName_DataAssetFragmentDoc> | null | undefined

export function useDemoDataAssetName(asset: FragmentData): DemoDataAssetName | undefined {
  const dataAsset = unmaskFragment(UseDemoDataAssetName_DataAssetFragmentDoc, asset)
  const name = DEMO_DATA_ASSETS.find((demoDataAsset) => demoDataAsset === dataAsset?.name)
  return name
}
