import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { JSONSchema } from "json-schema-to-ts"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import {
  DatabricksSQLDataAssetTableJsonSchema,
  DatabricksSQLTableAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/databricks-sql-data-asset-table-json-schema"

import {
  PostgresDataAssetQueryJsonSchema,
  PostgresQueryAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/postgres-data-asset-query-json-schema"
import {
  PostgresDataAssetTableJsonSchema,
  PostgresTableAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/postgres-data-asset-table-json-schema"
import {
  SnowflakeQueryAssetConfigData,
  SnowflakeQueryAssetSchema,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/snowflake-data-asset-query-json-schema"
import {
  SnowflakeTableAssetConfigData,
  SnowflakeTableAssetSchema,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/snowflake-data-asset-table-json-schema"
import {
  SqlDataAssetQueryJsonSchema,
  SqlQueryAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/sql-data-asset-query-json-schema"
import {
  SqlDataAssetTableJsonSchema,
  SqlTableAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/sql-data-asset-table-json-schema"
import {
  SqliteDataAssetQueryJsonSchema,
  SqliteQueryAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/sqlite-data-asset-query-json-schema"
import {
  SqliteDataAssetTableJsonSchema,
  SqliteTableAssetConfigData,
} from "src/DataAssets/connect-to-data/schemas/json-schemas/sqlite-data-asset-table-json-schema"
import { DatabricksSQLTableAssetUISchema } from "src/DataAssets/connect-to-data/schemas/ui-schemas/databricks-sql-data-asset-ui-schema"
import {
  PostgresQueryAssetUISchema,
  PostgresTableAssetUISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/postgres-data-asset-ui-schema"
import {
  SnowflakeQueryAssetUISchema,
  SnowflakeTableAssetUISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/snowflake-data-asset-ui-schema"
import {
  SqlQueryAssetUISchema,
  SqlTableAssetUISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/sql-data-asset-ui-schema"
import {
  SqliteQueryAssetUISchema,
  SqliteTableAssetUISchema,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/sqlite-data-asset-ui-schema"

type SnowflakeAssetConfigData = SnowflakeTableAssetConfigData | SnowflakeQueryAssetConfigData
// TODO: change schemas so type is required
type SnowflakeAssetType = Exclude<SnowflakeAssetConfigData["type"], undefined>
type SnowflakeDataAssetKey = `${"SNOWFLAKE"}-${SnowflakeAssetType}`

type SqlAssetConfigData = SqlTableAssetConfigData | SqlQueryAssetConfigData
// TODO: change schemas so type is required
type SqlAssetType = Exclude<SqlAssetConfigData["type"], undefined>
type SqlDataAssetKey = `${"SQL"}-${SqlAssetType}`

type SqliteAssetConfigData = SqliteTableAssetConfigData | SqliteQueryAssetConfigData
// TODO: change schemas so type is required
type SqliteAssetType = Exclude<SqliteAssetConfigData["type"], undefined>
type SqliteDataAssetKey = `${"SQLITE"}-${SqliteAssetType}`

type PostgresAssetConfigData = PostgresTableAssetConfigData | PostgresQueryAssetConfigData
// TODO: change schemas so type is required
type PostgresAssetType = Exclude<PostgresAssetConfigData["type"], undefined>
type PostgresDataAssetKey = `${"POSTGRES"}-${PostgresAssetType}`

type DatabricksSQLAssetConfigData = DatabricksSQLTableAssetConfigData
// TODO: change schemas so type is required
type DatabricksSQLAssetType = Exclude<DatabricksSQLAssetConfigData["type"], undefined>
type DatabricksSQLDataAssetKey = `${"DATABRICKS_SQL"}-${DatabricksSQLAssetType}`

type DataAssetType = SnowflakeAssetType | SqlAssetType | PostgresAssetType | SqliteAssetType

export type DataAssetConfigData =
  | SnowflakeAssetConfigData
  | SqlAssetConfigData
  | PostgresAssetConfigData
  | SqliteAssetConfigData
  | DatabricksSQLAssetConfigData

type DataSourceDataAssetKey =
  | SnowflakeDataAssetKey
  | SqlDataAssetKey
  | PostgresDataAssetKey
  | SqliteDataAssetKey
  | DatabricksSQLDataAssetKey

const DataAssetJSONSchemaMap: Record<DataSourceDataAssetKey, JSONSchema> = {
  "DATABRICKS_SQL-table": DatabricksSQLDataAssetTableJsonSchema,
  "SNOWFLAKE-query": SnowflakeQueryAssetSchema,
  "SNOWFLAKE-table": SnowflakeTableAssetSchema,
  "SQL-query": SqlDataAssetQueryJsonSchema,
  "SQL-table": SqlDataAssetTableJsonSchema,
  "SQLITE-query": SqliteDataAssetQueryJsonSchema,
  "SQLITE-table": SqliteDataAssetTableJsonSchema,
  "POSTGRES-query": PostgresDataAssetQueryJsonSchema,
  "POSTGRES-table": PostgresDataAssetTableJsonSchema,
}

const DataAssetUISchemaMap: Record<DataSourceDataAssetKey, UISchema<unknown>> = {
  "DATABRICKS_SQL-table": DatabricksSQLTableAssetUISchema,
  "SNOWFLAKE-query": SnowflakeQueryAssetUISchema,
  "SNOWFLAKE-table": SnowflakeTableAssetUISchema,
  "SQL-query": SqlQueryAssetUISchema,
  "SQL-table": SqlTableAssetUISchema,
  "SQLITE-query": SqliteQueryAssetUISchema,
  "SQLITE-table": SqliteTableAssetUISchema,
  "POSTGRES-query": PostgresQueryAssetUISchema,
  "POSTGRES-table": PostgresTableAssetUISchema,
}

export function getJSONSchema(
  dataSourceType: SupportedDataSource,
  assetType: DataAssetType, // really want this to be a string literal union from API
): null | JSONSchema {
  const key = createSchemaKey(dataSourceType, assetType)
  if (!key) {
    return null
  }
  return DataAssetJSONSchemaMap[key]
}

export function getUISchema(
  dataSourceType: SupportedDataSource,
  assetType: DataAssetType, // really want this to be a string literal union from API
): null | UISchema<unknown> {
  const key = createSchemaKey(dataSourceType, assetType)
  if (!key) {
    return null
  }
  return DataAssetUISchemaMap[key]
}

function createSchemaKey(dataSourceType: SupportedDataSource, assetType: DataAssetType): DataSourceDataAssetKey | null {
  const key = `${dataSourceType}-${assetType}` as const

  if (!(key in DataAssetJSONSchemaMap) || !(key in DataAssetUISchemaMap)) {
    return null
  }
  // New data sources do not need to support query assets
  if (key === "DATABRICKS_SQL-query") {
    return null
  }
  return key
}

// TODO: change assetType's type to a union when the GQL API gives us one
export function isAssetEditable(dataSourceType: SupportedDataSource, assetType: string) {
  return !!createSchemaKey(dataSourceType, assetType as DataAssetType)
}
