import { JsonFormsUISchemaRegistryEntry, UISchemaElement } from "@jsonforms/core"
import type { ControlUISchema, UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { Icon } from "src/ui/Icon"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { baseUISchemaElements } from "src/Expectation/uiForms/schemas/base-ui-schema-elements"
import { SupportedExpectation } from "src/schemas/expectation-metadata-utils"
import { betweenMinMaxStrictUiSchemaGroup } from "src/Expectation/uiForms/schemas/strict-min-max-elements"

export const ValueSetUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath, path) => {
    if (schemaPath === "#/properties/value_set" && path === "value_set" && schema.type === "array") {
      return 100
    }
    return -1
  },
  uischema: {
    type: "Control",
    scope: "#",
    options: {
      addButtonProps: {
        children: "+ Add value",
      },
      removeButtonProps: {
        type: "text",
        icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
        children: "",
      },
      showSortButtons: true,
      moveUpButtonProps: {
        type: "text",
        icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
      },
      moveDownButtonProps: {
        type: "text",
        icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
      },
    },
  } satisfies UISchema<
    (typeof jsonSchemas.expectColumnValuesToBeInSet.schema.properties.value_set.oneOf)[0]
  > as UISchemaElement,
}

const expectColumnMaxToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnMaxToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMaxToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnMaxToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMaxToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnMeanToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnMeanToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMeanToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnMeanToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMeanToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnMinToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnMinToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMinToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnMinToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMinToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnMedianToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnMedianToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMedianToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnMedianToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMedianToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToBeInSet = (): UISchema<typeof jsonSchemas.expectColumnValuesToBeInSet.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeInSet.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value_set",
        options: {
          optionType: "segmented",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeInSet.schema.properties.value_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeInSet.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToBeInSet.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToBeInTypeList = (): UISchema<typeof jsonSchemas.expectColumnValuesToBeInTypeList.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeInTypeList.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/type_list",
        options: {
          addButtonProps: {
            children: "+ Add type",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeInTypeList.schema.properties.type_list.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeInTypeList.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToBeInTypeList.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToBeNull = (): UISchema<typeof jsonSchemas.expectColumnValuesToBeNull.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToBeNull.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToNotBeNull = (): UISchema<typeof jsonSchemas.expectColumnValuesToNotBeNull.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToNotBeNull.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToBeOfType = (): UISchema<typeof jsonSchemas.expectColumnValuesToBeOfType.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeOfType.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/type_",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeOfType.schema.properties.type_.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeOfType.schema.properties.mostly.description,
        },
        options: {
          addonAfter: "%",
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToBeOfType.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToBeUnique = (): UISchema<typeof jsonSchemas.expectColumnValuesToBeUnique.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeUnique.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeUnique.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToBeUnique.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectTableColumnsToMatchOrderedList = (): UISchema<
  typeof jsonSchemas.expectTableColumnsToMatchOrderedList.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column_list",
        options: {
          addButtonProps: {
            children: "+ Add column",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectTableColumnsToMatchOrderedList.schema.properties.column_list.description,
        },
      },
      ...baseUISchemaElements,
    ],
  }
}

const expectTableRowCountToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectTableRowCountToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectTableRowCountToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [
            {
              type: "Control",
              scope: "#/properties/min_value",
              formItemProps: {
                tooltip: jsonSchemas.expectTableRowCountToBeBetween.schema.properties.min_value.description,
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectTableRowCountToBeBetween.schema>,
            {
              type: "Control",
              scope: "#/properties/max_value",
              formItemProps: {
                tooltip: jsonSchemas.expectTableRowCountToBeBetween.schema.properties.max_value.description,
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectTableRowCountToBeBetween.schema>,
          ]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectTableRowCountToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectTableRowCountToEqual = (): UISchema<typeof jsonSchemas.expectTableRowCountToEqual.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/value",
        formItemProps: {
          tooltip: jsonSchemas.expectTableRowCountToEqual.schema.properties.value.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectTableRowCountToEqual.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnToExist = (): UISchema<typeof jsonSchemas.expectColumnToExist.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
      },
      {
        type: "Control",
        scope: "#/properties/column_index",
      },
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnPairValuesToBeEqual = (): UISchema<typeof jsonSchemas.expectColumnPairValuesToBeEqual.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column_A",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnPairValuesToBeEqual.schema.properties.column_A.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/column_B",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnPairValuesToBeEqual.schema.properties.column_B.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnPairValuesToBeEqual.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/ignore_row_if",
        options: {
          enumValueToLabelMap: {
            both_values_are_missing: "Both values are missing",
            either_value_is_missing: "Either value is missing",
            neither: "Neither",
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnPairValuesToBeEqual.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnPairValuesAToBeGreaterThanB = (): UISchema<
  typeof jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/column_A",
                formItemProps: {
                  tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.column_A.description,
                },
              },
              {
                type: "Control",
                scope: "#/properties/column_B",
                formItemProps: {
                  tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.column_B.description,
                },
              },
            ],
          },
          {
            type: "Control",
            scope: "#/properties/or_equal",
            formItemProps: {
              tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.or_equal.description,
            },
          },
        ],
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/ignore_row_if",
        options: {
          enumValueToLabelMap: {
            both_values_are_missing: "Both values are missing",
            either_value_is_missing: "Either value is missing",
            neither: "Neither",
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectMulticolumnSumToEqual = (): UISchema<typeof jsonSchemas.expectMulticolumnSumToEqual.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column_list",
        options: {
          addButtonProps: {
            children: "+ Add column",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.column_list.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/sum_total",
        formItemProps: {
          tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.sum_total.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/ignore_row_if",
        options: {
          enumValueToLabelMap: {
            all_values_are_missing: "All values are missing",
            any_value_is_missing: "Any value is missing",
            never: "Never",
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.ignore_row_if.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectMulticolumnSumToEqual.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectCompoundColumnsToBeUnique = (): UISchema<typeof jsonSchemas.expectCompoundColumnsToBeUnique.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column_list",
        options: {
          addButtonProps: {
            children: "+ Add column",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectCompoundColumnsToBeUnique.schema.properties.column_list.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectCompoundColumnsToBeUnique.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/ignore_row_if",
        options: {
          enumValueToLabelMap: {
            all_values_are_missing: "All values are missing",
            any_value_is_missing: "Any value is missing",
            never: "Never",
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectCompoundColumnsToBeUnique.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectSelectColumnValuesToBeUniqueWithinRecord = (): UISchema<
  typeof jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column_list",
        options: {
          addButtonProps: {
            children: "+ Add column",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema.properties.column_list.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectTableColumnCountToEqual = (): UISchema<typeof jsonSchemas.expectTableColumnCountToEqual.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/value",
        formItemProps: {
          tooltip: jsonSchemas.expectTableColumnCountToEqual.schema.properties.value.description,
        },
      },
      ...baseUISchemaElements,
    ],
  }
}

const expectTableColumnsToMatchSet = (): UISchema<typeof jsonSchemas.expectTableColumnsToMatchSet.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column_set",
        options: {
          addButtonProps: {
            children: "+ Add column",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectTableColumnsToMatchSet.schema.properties.column_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/exact_match",
        formItemProps: {
          tooltip: jsonSchemas.expectTableColumnsToMatchSet.schema.properties.exact_match.description,
        },
      },
      ...baseUISchemaElements,
    ],
  }
}

const expectTableColumnCountToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectTableColumnCountToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectTableColumnCountToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [
            {
              type: "Control",
              scope: "#/properties/min_value",
              formItemProps: {
                tooltip: jsonSchemas.expectTableColumnCountToBeBetween.schema.properties.min_value.description,
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectTableColumnCountToBeBetween.schema>,
            {
              type: "Control",
              scope: "#/properties/max_value",
              formItemProps: {
                tooltip: jsonSchemas.expectTableColumnCountToBeBetween.schema.properties.max_value.description,
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectTableColumnCountToBeBetween.schema>,
          ]),
      ...baseUISchemaElements,
    ],
  }
}

const expectTableRowCountToEqualOtherTable = (): UISchema<
  typeof jsonSchemas.expectTableRowCountToEqualOtherTable.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/other_table_name",
        formItemProps: {
          tooltip: jsonSchemas.expectTableRowCountToEqualOtherTable.schema.properties.other_table_name.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectTableRowCountToEqualOtherTable.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValueLengthsToEqual = (): UISchema<typeof jsonSchemas.expectColumnValueLengthsToEqual.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueLengthsToEqual.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueLengthsToEqual.schema.properties.value.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueLengthsToEqual.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValueLengthsToEqual.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToMatchLikePattern = (): UISchema<
  typeof jsonSchemas.expectColumnValuesToMatchLikePattern.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePattern.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/like_pattern",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePattern.schema.properties.like_pattern.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePattern.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToMatchLikePattern.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToMatchLikePatternList = (): UISchema<
  typeof jsonSchemas.expectColumnValuesToMatchLikePatternList.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/like_pattern_list",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.like_pattern_list.description,
        },
        options: {
          addButtonProps: {
            children: "+ Add like pattern",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/match_on",
        options: {
          optionType: "segmented",
          enumValueToLabelMap: {
            any: "Any",
            all: "All",
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.match_on.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToMatchLikePatternList.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToMatchRegex = (): UISchema<typeof jsonSchemas.expectColumnValuesToMatchRegex.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegex.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/regex",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegex.schema.properties.regex.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegex.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToMatchRegex.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToMatchRegexList = (): UISchema<
  typeof jsonSchemas.expectColumnValuesToMatchRegexList.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/regex_list",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.regex_list.description,
        },
        options: {
          addButtonProps: {
            children: "+ Add regex",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/match_on",
        options: {
          optionType: "segmented",
          enumValueToLabelMap: {
            any: "Any",
            all: "All",
          },
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.match_on.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToMatchRegexList.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToNotMatchLikePattern = (): UISchema<
  typeof jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/like_pattern",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema.properties.like_pattern.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToNotMatchLikePatternList = (): UISchema<
  typeof jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/like_pattern_list",
        formItemProps: {
          tooltip:
            jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema.properties.like_pattern_list.description,
        },
        options: {
          addButtonProps: {
            children: "+ Add like pattern",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToNotMatchRegex = (): UISchema<typeof jsonSchemas.expectColumnValuesToNotMatchRegex.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchRegex.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/regex",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchRegex.schema.properties.regex.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchRegex.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToNotMatchRegex.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToNotMatchRegexList = (): UISchema<
  typeof jsonSchemas.expectColumnValuesToNotMatchRegexList.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchRegexList.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/regex_list",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchRegexList.schema.properties.regex_list.description,
        },
        options: {
          addButtonProps: {
            children: "+ Add regex",
          },
          removeButtonProps: {
            type: "text",
            icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
            children: "",
          },
          showSortButtons: true,
          moveUpButtonProps: {
            type: "text",
            icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
          },
          moveDownButtonProps: {
            type: "text",
            icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotMatchRegexList.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToNotMatchRegexList.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToNotBeInSet = (): UISchema<typeof jsonSchemas.expectColumnValuesToNotBeInSet.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotBeInSet.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value_set",
        options: {
          optionType: "segmented",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotBeInSet.schema.properties.value_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToNotBeInSet.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToNotBeInSet.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValuesToBeBetween = (): UISchema<typeof jsonSchemas.expectColumnValuesToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.column.description,
        },
      },
      betweenMinMaxStrictUiSchemaGroup("expectColumnValuesToBeBetween"),
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValuesToBeBetween.schema>, // remove this satisfies when FF is removed

      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValueZScoresToBeLessThan = (): UISchema<
  typeof jsonSchemas.expectColumnValueZScoresToBeLessThan.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.column.description,
        },
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/threshold",
            formItemProps: {
              tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.threshold.description,
            },
          },
          {
            type: "Control",
            scope: "#/properties/double_sided",
            formItemProps: {
              tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.double_sided.description,
            },
          },
        ],
      },
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValueZScoresToBeLessThan.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnValueLengthsToBeBetween = (): UISchema<
  typeof jsonSchemas.expectColumnValueLengthsToBeBetween.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.column.description,
        },
      },
      betweenMinMaxStrictUiSchemaGroup("expectColumnValueLengthsToBeBetween"),
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.mostly.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnValueLengthsToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnDistinctValuesToBeInSet = (): UISchema<
  typeof jsonSchemas.expectColumnDistinctValuesToBeInSet.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnDistinctValuesToBeInSet.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value_set",
        options: {
          optionType: "segmented",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnDistinctValuesToBeInSet.schema.properties.value_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnDistinctValuesToBeInSet.schema>, // remove this satisfies when FF is removed

      ...baseUISchemaElements,
    ],
  }
}

const expectColumnDistinctValuesToContainSet = (): UISchema<
  typeof jsonSchemas.expectColumnDistinctValuesToContainSet.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnDistinctValuesToContainSet.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value_set",
        options: {
          optionType: "segmented",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnDistinctValuesToContainSet.schema.properties.value_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnDistinctValuesToContainSet.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnDistinctValuesToEqualSet = (): UISchema<
  typeof jsonSchemas.expectColumnDistinctValuesToEqualSet.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnDistinctValuesToEqualSet.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value_set",
        options: {
          optionType: "segmented",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnDistinctValuesToEqualSet.schema.properties.value_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnDistinctValuesToEqualSet.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnMostCommonValueToBeInSet = (): UISchema<
  typeof jsonSchemas.expectColumnMostCommonValueToBeInSet.schema
> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMostCommonValueToBeInSet.schema.properties.column.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/value_set",
        options: {
          optionType: "segmented",
        },
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMostCommonValueToBeInSet.schema.properties.value_set.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/ties_okay",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMostCommonValueToBeInSet.schema.properties.ties_okay.description,
        },
      },
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnMostCommonValueToBeInSet.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnProportionOfUniqueValuesToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnProportionOfUniqueValuesToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnStdevToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnStdevToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnStdevToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnStdevToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnStdevToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnSumToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnSumToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnSumToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnSumToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnSumToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

const expectColumnUniqueValueCountToBeBetween = (
  windowedParamsEnabled: boolean,
): UISchema<typeof jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/column",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.column.description,
        },
        ...(windowedParamsEnabled ? { layoutProps: { columns: 24 } } : {}),
      },
      ...(windowedParamsEnabled
        ? [
            {
              type: "Control",
              label: {
                type: "Text",
                text: "to be between",
              },
              scope: "#/properties/to_be_between",
              options: {
                optionType: "dropdown",
              },
            } satisfies ControlUISchema<typeof jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema>, // remove this satisfies when FF is removed
          ]
        : [betweenMinMaxStrictUiSchemaGroup("expectColumnUniqueValueCountToBeBetween")]),
      {
        type: "Control",
        scope: "#/properties/row_condition",
      } satisfies ControlUISchema<typeof jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema>, // remove this satisfies when FF is removed
      ...baseUISchemaElements,
    ],
  }
}

export const UNEXPECTED_ROWS_DOCS_LINK =
  "https://docs.greatexpectations.io/docs/cloud/expectations/manage_expectations#custom-sql-expectations"

const unexpectedRowsExpectation = (): UISchema<typeof jsonSchemas.unexpectedRowsExpectation.schema> => {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/description",
        label: {
          text: "Expectation description",
        },
        options: {
          placeholderText: jsonSchemas.unexpectedRowsExpectation.schema.properties.description.description,
          required: true,
        },
      },
      {
        type: "Control",
        scope: "#/properties/unexpected_rows_query",
        options: {
          required: true,
        },
      },
      {
        type: "Label",
        text: `\`{batch}\` is an optional named query that represents the data you have selected from the Data Asset. GX Cloud will automatically replace \`{batch}\` with the necessary SQL.  For more information view [Custom SQL Expectations](${UNEXPECTED_ROWS_DOCS_LINK} "Custom SQL Expectations").`,
        options: {
          type: "info",
        },
      },
      ...baseUISchemaElements,
    ],
  }
}

export const useUISchemaFromJSONSchema = (expectationClass: string) => {
  const windowedParamsEnabled = useIsFeatureEnabled("windowedParamsEnabled")

  const noFeatureFlags = {
    expectColumnToExist: expectColumnToExist(),
    expectTableColumnCountToEqual: expectTableColumnCountToEqual(),
    expectTableColumnsToMatchOrderedList: expectTableColumnsToMatchOrderedList(),
    expectTableColumnsToMatchSet: expectTableColumnsToMatchSet(),
    unexpectedRowsExpectation: unexpectedRowsExpectation(),
    expectColumnDistinctValuesToBeInSet: expectColumnDistinctValuesToBeInSet(),
    expectColumnDistinctValuesToContainSet: expectColumnDistinctValuesToContainSet(),
    expectColumnDistinctValuesToEqualSet: expectColumnDistinctValuesToEqualSet(),
    expectColumnMostCommonValueToBeInSet: expectColumnMostCommonValueToBeInSet(),
    expectColumnPairValuesAToBeGreaterThanB: expectColumnPairValuesAToBeGreaterThanB(),
    expectColumnPairValuesToBeEqual: expectColumnPairValuesToBeEqual(),
    expectColumnValueLengthsToBeBetween: expectColumnValueLengthsToBeBetween(),
    expectColumnValueLengthsToEqual: expectColumnValueLengthsToEqual(),
    expectColumnValuesToBeBetween: expectColumnValuesToBeBetween(),
    expectColumnValuesToBeInSet: expectColumnValuesToBeInSet(),
    expectColumnValuesToBeInTypeList: expectColumnValuesToBeInTypeList(),
    expectColumnValuesToBeNull: expectColumnValuesToBeNull(),
    expectColumnValuesToBeOfType: expectColumnValuesToBeOfType(),
    expectColumnValuesToBeUnique: expectColumnValuesToBeUnique(),
    expectColumnValuesToMatchLikePattern: expectColumnValuesToMatchLikePattern(),
    expectColumnValuesToMatchLikePatternList: expectColumnValuesToMatchLikePatternList(),
    expectColumnValuesToMatchRegex: expectColumnValuesToMatchRegex(),
    expectColumnValuesToMatchRegexList: expectColumnValuesToMatchRegexList(),
    expectColumnValuesToNotBeInSet: expectColumnValuesToNotBeInSet(),
    expectColumnValuesToNotBeNull: expectColumnValuesToNotBeNull(),
    expectColumnValuesToNotMatchLikePattern: expectColumnValuesToNotMatchLikePattern(),
    expectColumnValuesToNotMatchLikePatternList: expectColumnValuesToNotMatchLikePatternList(),
    expectColumnValuesToNotMatchRegex: expectColumnValuesToNotMatchRegex(),
    expectColumnValuesToNotMatchRegexList: expectColumnValuesToNotMatchRegexList(),
    expectColumnValueZScoresToBeLessThan: expectColumnValueZScoresToBeLessThan(),
    expectCompoundColumnsToBeUnique: expectCompoundColumnsToBeUnique(),
    expectMulticolumnSumToEqual: expectMulticolumnSumToEqual(),
    expectSelectColumnValuesToBeUniqueWithinRecord: expectSelectColumnValuesToBeUniqueWithinRecord(),
    expectTableRowCountToEqual: expectTableRowCountToEqual(),
    expectTableRowCountToEqualOtherTable: expectTableRowCountToEqualOtherTable(),
  }

  const windowedParamsOnly = {
    expectTableColumnCountToBeBetween: expectTableColumnCountToBeBetween(windowedParamsEnabled),
    expectColumnMaxToBeBetween: expectColumnMaxToBeBetween(windowedParamsEnabled),
    expectColumnMeanToBeBetween: expectColumnMeanToBeBetween(windowedParamsEnabled),
    expectColumnMedianToBeBetween: expectColumnMedianToBeBetween(windowedParamsEnabled),
    expectColumnMinToBeBetween: expectColumnMinToBeBetween(windowedParamsEnabled),
    expectColumnStdevToBeBetween: expectColumnStdevToBeBetween(windowedParamsEnabled),
    expectColumnSumToBeBetween: expectColumnSumToBeBetween(windowedParamsEnabled),
    expectTableRowCountToBeBetween: expectTableRowCountToBeBetween(windowedParamsEnabled),
    expectColumnProportionOfUniqueValuesToBeBetween:
      expectColumnProportionOfUniqueValuesToBeBetween(windowedParamsEnabled),
    expectColumnUniqueValueCountToBeBetween: expectColumnUniqueValueCountToBeBetween(windowedParamsEnabled),
  }

  const uiSchemas = {
    ...noFeatureFlags,
    ...windowedParamsOnly,
  } satisfies Record<SupportedExpectation, UISchema<unknown>>

  const uiSchemaName = expectationClass[0].toLowerCase() + expectationClass.slice(1)
  return uiSchemaName in uiSchemas ? uiSchemas[uiSchemaName as SupportedExpectation] : undefined
}
