import { JSONSchema } from "json-schema-to-ts"
export const BatchDefinitionJsonSchema = {
  title: "BatchDefinition",
  type: "object",
  properties: {
    batchDefinition: {
      title: "Batch interval",
      oneOf: [
        {
          title: "Entire table",
          default: "entire_table",
          enum: ["entire_table"],
          type: "string",
        },
        { $ref: "#/definitions/SplitterYear" },
        { $ref: "#/definitions/SplitterYearAndMonth" },
        { $ref: "#/definitions/SplitterYearAndMonthAndDay" },
      ] as const,
    },
  },
  required: ["batchDefinition"],
  additionalProperties: false,
  definitions: {
    SplitterYear: {
      title: "Yearly",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_year",
          enum: ["split_on_year"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
    SplitterYearAndMonth: {
      title: "Monthly",

      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_year_and_month",
          enum: ["split_on_year_and_month"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
    SplitterYearAndMonthAndDay: {
      title: "Daily",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_year_and_month_and_day",
          enum: ["split_on_year_and_month_and_day"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
  },
} as const satisfies JSONSchema
