/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Layout, LayoutProps, rankWith, uiTypeIs } from "@jsonforms/core"
import { withJsonFormsLayoutProps } from "@jsonforms/react"
import { VerticalLayout } from "@jsonforms/vanilla-renderers"

const CategoryControl = (props: LayoutProps) => {
  const { uischema, schema, path, visible, renderers, enabled, data, config } = props

  const layoutProps = {
    elements: (uischema as Layout).elements,
    schema: schema,
    path: path,
    direction: "column",
    visible: visible,
    uischema: uischema,
    renderers: renderers,
    enabled,
    data,
    config,
  }

  return <VerticalLayout {...{ ...props, ...layoutProps }} />
}

export const CategoryRegistryEntry = {
  renderer: withJsonFormsLayoutProps(CategoryControl),
  tester: rankWith(1, uiTypeIs("Category")),
}
