// snowflake
export const CreateUserInSnowflake = `
use role accountadmin;
create user if not exists gx_user password='<YOUR_PASSWORD>';
create role if not exists gx_role;
grant role gx_role to user gx_user;

-- GX recommends creating a separate warehouse for GX Cloud to simplify cost 
-- management and optimize resource consumption. 
create warehouse if not exists gx_wh
warehouse_size=xsmall
auto_suspend=10
auto_resume=true
initially_suspended=true;
grant usage, operate on warehouse gx_wh to role gx_role; 

-- Replace <YOUR_DATABASE> and <YOUR_SCHEMA> with the names of the databases
-- and schemas you want to test in GX Cloud.
grant usage on database <YOUR_DATABASE> to role gx_role;
grant usage on schema <YOUR_DATABASE>.<YOUR_SCHEMA> to role gx_role;
grant select on all tables in schema <YOUR_DATABASE>.<YOUR_SCHEMA> to role gx_role;
grant select on future tables in schema <YOUR_DATABASE>.<YOUR_SCHEMA> to role gx_role; 
-- Gives the user with the gx_role role access to all future tables in the defined schema.
`
export const SnowflakeCTA = "Copy and paste the following code into a SQL worksheet"
export const SnowflakeCodeBlockHeight = 385

// postgres
export const CreateUserInPostgres = `
-- Create and assign the gx_role role and allow GX Cloud 
-- to access to all public schemas and tables on a specific database
CREATE ROLE gx_role WITH LOGIN PASSWORD '<your_password>';
GRANT CONNECT ON DATABASE <your_database> TO gx_role;
GRANT USAGE ON SCHEMA public TO gx_role;
GRANT SELECT ON ALL TABLES in SCHEMA public TO gx_role;
ALTER DEFAULT PRIVILEGES IN SCHEMA public GRANT SELECT ON TABLES TO gx_role
`
export const PostgresCTA = "Copy and paste the following code into the Query pane"
export const PostgresCodeBlockHeight = 150
