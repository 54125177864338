import { Button, Form, Input } from "antd"
import styled, { css } from "styled-components"

import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { SET_ORG_NAME_ERROR_TEXT } from "src/Expectation/words"
import { SignupLoginModal } from "src/ui/LoginScreen/SignupLoginModal"
import { UpdateOrganizationDocument } from "src/api/graphql/graphql-operations"
import { currentOrgIdVar } from "src/organizations"
import { setLocalStorageItem } from "src/common/utils/local-storage"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { ScrollableMainContainer } from "src/ui/OnboardingFlow/styles"

const WelcomeHeader = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WelcomeHeaderText = styled.div`
  color: #2f2f2f;
  ${({ theme }) => css`
    font-family: ${theme.typography.fonts.montserrat};
  `};
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
`

export const EnterOrganizationName = () => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [errorText, setErrorText] = useState("")
  const [form] = Form.useForm()
  const ctx = useCurrentUser()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const updateInput = (orgName: string) => {
    const isOrgNameValid = orgName.length > 2
    setIsSubmitButtonDisabled(!isOrgNameValid)
  }

  const [updateOrganization, { error }] = useMutation(UpdateOrganizationDocument, {
    onCompleted: () => {
      ctx.getAccessTokenSilently({ cacheMode: "off" }).then((updatedToken) => {
        setLocalStorageItem("auth0Token", updatedToken)
        navigate("/")
        navigate(0) //TODO: figure out why the first navigate isn't refreshing the page
      })
    },
    onError: () => {
      setLoading(false)
      setErrorText(SET_ORG_NAME_ERROR_TEXT)
    },
  })

  const onSubmit = () => {
    if (loading || error) return
    setLoading(true)
    const orgName = form.getFieldValue("orgName")
    if (!orgName) {
      throw new Error("Invalid organization name")
    }
    const currentOrgId = currentOrgIdVar()
    updateOrganization({
      variables: {
        input: {
          id: currentOrgId,
          name: orgName,
          title: orgName,
        },
      },
    })
  }

  return (
    <ScrollableMainContainer>
      <SignupLoginModal
        hideFooter
        headerSection={
          <WelcomeHeader>
            <WelcomeHeaderText>Welcome to</WelcomeHeaderText>
            <WelcomeHeaderText>Great Expectations Cloud!</WelcomeHeaderText>
          </WelcomeHeader>
        }
        secondaryHeaderSectionText="Enter your organization name to get started"
        formsSection={
          <>
            <Form style={{ width: "100%" }} form={form} name="enterOrgName" layout="vertical">
              <Form.Item name="orgName" rules={[{ required: true, min: 3, message: "" }]}>
                <Input
                  placeholder="Your organization name"
                  autoComplete="off"
                  aria-label="Organization name input"
                  onChange={(e) => {
                    updateInput(e.target.value)
                  }}
                />
              </Form.Item>
            </Form>
            <Button
              style={{ width: "100%", marginTop: "-16px", boxShadow: "none" }}
              type="primary"
              onClick={onSubmit}
              disabled={isSubmitButtonDisabled}
              loading={loading}
            >
              Continue to GX Cloud
            </Button>
            {errorText && (
              <FeedbackMessageDoNotUse title="Set organization name error" type="error" description={errorText} />
            )}
          </>
        }
      />
    </ScrollableMainContainer>
  )
}
