import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { Flex } from "antd"
import { AppModal } from "src/ui/AppModal/AppModal"
import { Icon } from "src/ui/Icon"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import {
  CreateUserInSnowflake,
  CreateUserInPostgres,
  PostgresCTA,
  SnowflakeCTA,
  PostgresCodeBlockHeight,
  SnowflakeCodeBlockHeight,
} from "src/DataAssets/connect-to-data/CreateGXUserWords"

import { copyToClipboard } from "src/common/utils/clipboard"
import { useEffect } from "react"
import { useAnalytics } from "src/analytics/useAnalytics"
import { theme } from "src/ui/themes/theme"

type InfoParts = {
  snippet: string
  cta: string
  height: number
  title: string
}

const TypeToWordsMap: Record<SupportedDataSource, InfoParts> = {
  DATABRICKS_SQL: {
    snippet: "TODO snippet for creating databricks user",
    title: "todo title",
    cta: "TODO cta",
    height: 30,
  },
  POSTGRES: {
    snippet: CreateUserInPostgres,
    title: "Create a Postgres user",
    cta: PostgresCTA,
    height: PostgresCodeBlockHeight,
  },
  SNOWFLAKE: {
    snippet: CreateUserInSnowflake,
    title: "Create a user in Snowflake",
    cta: SnowflakeCTA,
    height: SnowflakeCodeBlockHeight,
  },
  SQL: { snippet: "", title: "", cta: "", height: 0 },
  SQLITE: { snippet: "", title: "", cta: "", height: 0 },
}

export function CreateGXUserModal({
  dataSourceType,
  isOpen,
  onCancel,
}: {
  dataSourceType: SupportedDataSource
  isOpen: boolean
  onCancel: () => void
}) {
  const { snippet, cta, height, title } = TypeToWordsMap[dataSourceType]
  const posthog = useAnalytics()

  useEffect(() => {
    if (posthog) {
      posthog.capture("add_asset.gxUser_modal_viewed", { dataSourceType })
    }
    // needs to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppModal
      width={750}
      title={title}
      open={isOpen}
      onCancel={onCancel}
      okText="Copy Snippet"
      okButtonProps={{
        // ariaLabel: "Copy",
        icon: <Icon size="14px" name="clipboard" />,
        disabled: !snippet,
        style: { marginBottom: theme.spacing.vertical.xs },
        onClick: () => {
          copyToClipboard(snippet)
          posthog?.capture("add_asset.snippet_copied", { dataSourceType })
        },
      }}
    >
      <Flex vertical gap="middle">
        {cta}
        <div style={{ minHeight: `${height}px` }}>
          <CodeSnippetEditor
            value={snippet}
            language="sql"
            height={`${height}px`}
            fontSize={12}
            showLineNumbers={false}
          />
        </div>
      </Flex>
    </AppModal>
  )
}
