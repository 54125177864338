import { Input, Select, SelectProps } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { sortBy } from "lodash-es"
import { StyledFormItem } from "src/ui/Form/FormItem"
import { StyledTooltipIcon } from "src/ui/Tooltip/Tooltip"
import { ChangeEventHandler } from "react"

import { ExpectationSuitesIdsAndNamesQuery } from "src/api/graphql/graphql"
import { EXPECTATION_SUITE_HELP_TEXT } from "src/DataAssets/AssetDetails/Expectations/words"
import { BodyStrong } from "src/ui/typography/Text/Text"

interface ExpectationSuiteSelectorForDrawerProps {
  initialSuiteName: string
  allowChangeInitialSuiteName: boolean
  expectationSuites?: ExpectationSuitesIdsAndNamesQuery["expectationSuitesV2"]
  existingSuiteOnChange?: SelectProps["onChange"]
  newSuiteOnChange?: ChangeEventHandler<HTMLInputElement>
}

export const ExpectationSuiteSelectorForDrawer = ({
  initialSuiteName,
  allowChangeInitialSuiteName,
  expectationSuites,
  existingSuiteOnChange,
  newSuiteOnChange,
}: ExpectationSuiteSelectorForDrawerProps) => {
  const expectationSuiteOptions: DefaultOptionType[] = sortBy(
    (expectationSuites ?? []).map((suite) => ({
      label: suite.name,
      value: suite.name,
    })),
    (opt) => opt.value,
  )

  const placeholder = "Enter an Expectation Suite name"

  return (
    <StyledFormItem
      initialValue={initialSuiteName}
      name="suiteName"
      label="Expectation Suite"
      rules={[{ required: true, message: "Expectation Suite name is required" }]}
      validateTrigger={["onBlur", "onSubmit"]}
      tooltip={{ icon: <StyledTooltipIcon name="questionCircle" width="18px" />, title: EXPECTATION_SUITE_HELP_TEXT }}
    >
      {(!allowChangeInitialSuiteName || expectationSuiteOptions.length === 1) && (
        <BodyStrong aria-label="Expectation Suite name">{initialSuiteName}</BodyStrong>
      )}
      {allowChangeInitialSuiteName && expectationSuiteOptions.length > 1 && (
        <Select
          defaultValue={initialSuiteName}
          options={expectationSuiteOptions}
          placeholder={placeholder}
          aria-label="Expectation Suite name select"
          onChange={existingSuiteOnChange}
        />
      )}
      {allowChangeInitialSuiteName && expectationSuiteOptions.length === 0 && (
        <Input
          placeholder={placeholder}
          defaultValue={initialSuiteName}
          aria-label="Expectation Suite name input"
          onChange={newSuiteOnChange}
        />
      )}
    </StyledFormItem>
  )
}
