import { Alert } from "antd"
import { DemoDataAssetName } from "src/common/hooks/useDemoDataAssetName"
import { Icon } from "src/ui/Icon"
import { BodyStrong } from "src/ui/typography/Text/Text"

const MESSAGE = "What is demo data?"
const NYC_TAXI_DESCRIPTION = (
  <span>
    This dataset contains NYC yellow taxi trip records with fields capturing pick-up and drop-off dates, times,
    locations, and fare amounts. Below are the profiled metrics of the Data Asset. Start creating Expectations by
    clicking
    {` `}
    <BodyStrong>
      <Icon name="plus" size="11px" />
      {` `}
      New Expectation
    </BodyStrong>
    .
  </span>
)

/**
 * A record of demo data assets and their descriptions.
 * @param assetName The name of the demo data asset
 * @returns A JSX element describing the demo data asset
 */
const ASSET_RECORD: Record<DemoDataAssetName, JSX.Element> = {
  nyc_taxi_data: NYC_TAXI_DESCRIPTION,
}

export function DataAssetOverviewAlert({ assetName }: { assetName: DemoDataAssetName | undefined }) {
  const description = ASSET_RECORD[assetName ?? "nyc_taxi_data"]
  return <Alert message={MESSAGE} description={description} type="info" showIcon />
}
