import { MutationHookOptions, useMutation } from "@apollo/client"
import {
  CheckpointWithJobsFragment,
  CheckpointWithJobsFragmentDoc,
  CreateRunCheckpointJobDocument,
  JobFragment,
} from "src/api/graphql/graphql-operations"
import { ExecutableDefinitionNode } from "graphql"
import { SplitterOptionsInput } from "src/api/graphql/graphql"

export const useRunCheckpointJob = ({ refetchQueries = [], onCompleted }: MutationHookOptions) => {
  const [createJob, { loading, error }] = useMutation(CreateRunCheckpointJobDocument, {})

  const runJob = (checkpointId: string, splitterOptions?: SplitterOptionsInput) => {
    createJob({
      variables: { checkpointId, splitterOptions: splitterOptions || null },
      refetchQueries,
      onCompleted,
      update(cache, result) {
        cache.updateFragment(
          {
            broadcast: true,
            fragment: CheckpointWithJobsFragmentDoc,
            fragmentName: (CheckpointWithJobsFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
            id: `Checkpoint:${checkpointId}`,
          },
          (fragment) => {
            const id = result?.data?.createRunCheckpointJob?.jobId
            if (!id || !fragment) {
              return null
            }
            // eventually this mutation will return the job and not just an id
            // this is like an optimistic cache update (except it happens after mutation returns)
            // that gets updated when the polling query returns
            const newJob: JobFragment = {
              __typename: "Job" as const,
              timeQueued: new Date().toISOString(),
              id: id,
              status: "queued",
              sourceResources: [],
              createdResources: [],
              timeCompleted: undefined,
              errorMessage: undefined,
              timeStarted: undefined,
            }
            const newFragment: CheckpointWithJobsFragment = {
              ...fragment,
              jobs: [...(fragment?.jobs ?? []), ...(newJob ? [newJob] : [])],
              __typename: "Checkpoint" as const,
            }
            return newFragment
          },
        )
      },
    })
  }
  return { runJob, loading, error }
}
