import { useQuery } from "@apollo/client"
import { GetAllCheckpointsDocument, JobFragment } from "src/api/graphql/graphql-operations"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Dropdown, notification } from "antd"
import { useRunCheckpointJob } from "src/DataAssets/AssetDetails/Expectations/useRunCheckpointJob"
import { Button } from "src/ui/Button/Button"
import { DownOutlined } from "@ant-design/icons"
import Tooltip from "src/ui/Tooltip/Tooltip"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import { Icon } from "src/ui/Icon"
import { ValidateExpectationsModal } from "src/DataAssets/AssetDetails/ValidateExpectationsModal"
import styled from "styled-components"
import { useAnalytics } from "src/analytics/useAnalytics"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { JobStateObserver } from "src/common/components/JobStateObserver"
import {
  VALIDATE_ERROR_DESCRIPTION,
  VALIDATE_ERROR_TEXT,
  VALIDATE_JOB_CREATION_ERROR_DESCRIPTION,
  VALIDATE_SUCCESS_TEXT,
} from "src/DataAssets/words"
import { NOTIFICATION_INFINITE_DURATION_SECONDS, NOTIFICATION_WITH_LINK_DURATION_SECONDS } from "src/common/config"
import { Link } from "src/ui/typography/Text/Text"
import { CodeIcon } from "src/ui/Icon/CodeIcon"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { BifoldButton } from "src/ui/BifoldButton/BifoldButton"

interface RunCheckpointJobButtonProps {
  expectationSuiteId: string
  assetId: string
  disabled?: boolean
}

const StyledIconContainer = styled.span`
  display: flex;
  width: 24px;
  justify-content: center;
`

export const RunCheckpointJobButton = ({ expectationSuiteId, assetId, disabled }: RunCheckpointJobButtonProps) => {
  const [selectedCheckpointId, setSelectedCheckpointId] = useState<string>("")
  const [openValidateExpectationsModal, setOpenValidateExpectationsModal] = useState(false)
  const [jobId, setJobId] = useState<string | null>()
  const { runJob, error: createJobError } = useRunCheckpointJob({
    onCompleted: (data) => {
      setJobId(data.createRunCheckpointJob.jobId)
    },
  })

  const [isLoadingCurrentJobRequest, setIsLoadingCurrentJobRequest] = useState(false)
  const analytics = useAnalytics()

  const [notificationApi, notificationContextHolder] = notification.useNotification()
  /*
   * Get Checkpoints for selected Expectation Suite
   */
  const { data: checkpointData } = useQuery(GetAllCheckpointsDocument, {
    variables: {
      expectationSuiteID: expectationSuiteId,
      assetRefID: assetId,
    },
    skip: !expectationSuiteId || !assetId,
  })
  const checkpointList = useMemo(() => {
    return (
      checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId?.map((item) => ({
        label: item?.name,
        key: item?.id,
        icon: (
          <StyledIconContainer>
            <Icon name="play" size="14px" />
          </StyledIconContainer>
        ),
      })) ?? []
    )
  }, [checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId])

  /**
   ** Set selected Checkpoint when list length is 1
   */
  useEffect(() => {
    if (!selectedCheckpointId && checkpointList.length === 1 && checkpointList[0]?.key) {
      setSelectedCheckpointId(checkpointList[0].key)
    }
  }, [checkpointData, selectedCheckpointId, checkpointList])

  const reset = useCallback(() => {
    // Reset states when job is finished
    setIsLoadingCurrentJobRequest(false)
    if (checkpointList.length > 1) {
      setSelectedCheckpointId("")
    }
    setJobId(null)
  }, [checkpointList.length])

  const successMessage = (validationResultUrl: string) => {
    return (
      <>
        <Link to={validationResultUrl} strong>
          See results
        </Link>
      </>
    )
  }

  /**
   ** Get agent status to show error modal if agent is not active
   */
  const { executeFunction: loadAgentStatus, loading: isLoadingAgentStatus } = useLazyAgentStatus()
  const agentEnabled = useIsGXAgentEnabled()

  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)
  /**
   ** Show success or error messages when job is completed
   */

  const displayError = useCallback(() => {
    notificationApi.error({
      message: VALIDATE_ERROR_TEXT,
      description: VALIDATE_ERROR_DESCRIPTION,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
    reset()
  }, [notificationApi, reset])

  const displayCreateJobError = useCallback(() => {
    notificationApi.error({
      message: VALIDATE_ERROR_TEXT,
      description: VALIDATE_JOB_CREATION_ERROR_DESCRIPTION,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
    reset()
  }, [notificationApi, reset])

  useEffect(() => {
    if (createJobError) {
      displayCreateJobError()
    }
  }, [displayCreateJobError, createJobError])

  const onJobComplete = (job: JobFragment) => {
    const validationResultId = job.createdResources?.find(
      ({ entityType }) => entityType === "SuiteValidationResult",
    )?.entityId

    const valResultUrl = `/data-assets/${assetId}/validations/expectation-suites/${expectationSuiteId}/results/${validationResultId}`

    notificationApi.success({
      message: VALIDATE_SUCCESS_TEXT,
      description: successMessage(valResultUrl),
      duration: NOTIFICATION_WITH_LINK_DURATION_SECONDS,
      placement: "top",
    })
    reset()
  }

  const onJobError = () => {
    displayError()
  }

  const getButtonLabel = () => (isLoadingCurrentJobRequest ? "Validating" : "Validate")

  const getDropdownItems = () => {
    const snippet = {
      label: "Generate Snippet",
      key: "snippet",
      icon: (
        <StyledIconContainer>
          <CodeIcon />
        </StyledIconContainer>
      ),
    }
    const options = (checkpointList as ItemType[]).concat([{ type: "divider" }, snippet])
    return { label: "Checkpoint List", key: "checkpoints", children: options, type: "group" }
  }
  const runJobWithAgent = async function (checkpointId: string) {
    const res = await loadAgentStatus()
    const isAgentConnected = res.data?.agentStatus.active

    if (isAgentConnected) {
      setSelectedCheckpointId(checkpointId)
      runJob(checkpointId)
      setIsLoadingCurrentJobRequest(true)
      analytics?.capture("checkpoint.run_from_agent")
    } else {
      setIsAgentErrorModalVisible(true)
      reset()
    }
  }
  const runJobWithRunner = async function (checkpointId: string) {
    setSelectedCheckpointId(checkpointId)
    runJob(checkpointId)
    setIsLoadingCurrentJobRequest(true)
    analytics?.capture("checkpoint.run_from_runner")
  }

  const tryToRunJobUsing = async function (checkpointId: string) {
    const runJobFn = agentEnabled ? runJobWithAgent : runJobWithRunner
    runJobFn(checkpointId)
  }

  return (
    <>
      {notificationContextHolder}
      <Tooltip title={isLoadingCurrentJobRequest && <TooltipContent />}>
        {checkpointList.length > 1 ? (
          <Dropdown
            menu={{
              items: [getDropdownItems()],
              onClick: (value) => {
                if (value.key === "snippet") {
                  setOpenValidateExpectationsModal(true)
                  analytics?.capture("checkpoint.view_snippet")
                } else {
                  tryToRunJobUsing(value.key)
                }
              },
            }}
            disabled={isLoadingCurrentJobRequest || isLoadingAgentStatus || disabled}
          >
            <Button
              style={{ width: "145px", display: "flex", justifyContent: "space-between" }}
              loading={isLoadingCurrentJobRequest}
              icon="play"
              type="primary"
            >
              {getButtonLabel()}
              <DownOutlined />
            </Button>
          </Dropdown>
        ) : (
          <BifoldButton
            style={{ width: "150px" }}
            icon={<CodeIcon />}
            type="primary"
            disabled={disabled}
            menu={{
              items: [
                {
                  label: "Generate Snippet",
                  key: "snippet",
                  onClick: () => {
                    setOpenValidateExpectationsModal(true)
                    analytics?.capture("checkpoint.view_snippet")
                  },
                },
              ],
            }}
            trigger={["click"]}
            onClick={() => tryToRunJobUsing(selectedCheckpointId)}
            loading={isLoadingCurrentJobRequest || isLoadingAgentStatus}
          >
            {!isLoadingCurrentJobRequest && <Icon name="play" size="14px" />}
            {getButtonLabel()}
          </BifoldButton>
        )}
      </Tooltip>
      <ValidateExpectationsModal
        expectationSuiteID={expectationSuiteId}
        assetRefID={assetId}
        open={openValidateExpectationsModal}
        onCancel={() => setOpenValidateExpectationsModal(false)}
        onOk={() => setOpenValidateExpectationsModal(false)}
      />
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
      {jobId && <JobStateObserver jobId={jobId} onComplete={onJobComplete} onError={onJobError} />}
    </>
  )
}

function TooltipContent() {
  const pageName = "Logs"
  return <>We are currently validating your data. Go to the {pageName} page to view all your jobs in the queue.</>
}
