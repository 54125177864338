import { useFragment_experimental } from "@apollo/client"
import { ExecutableDefinitionNode } from "graphql"
import { JobFragmentWithTableNamesDocument } from "src/layout/routes/PollForJobs"

export const useJobByID = (jobId: string | undefined) => {
  // get the latest state of a job from the cache
  const { data: jobDataWithTableNames } = useFragment_experimental({
    fragment: JobFragmentWithTableNamesDocument,
    fragmentName: (JobFragmentWithTableNamesDocument.definitions[0] as ExecutableDefinitionNode).name?.value,
    from: {
      __typename: "Job",
      id: jobId,
    },
  })

  return jobDataWithTableNames
}
