import { MainContent } from "src/layout/MainContent"
import styled from "styled-components"
import { GetStarted } from "src/ui/GetStarted/GetStarted"
import { DataSourceChoice, NewAssetDrawer } from "src/DataAssets/connect-to-data/NewAssetDrawer"
import { NewDemoAssetDrawer } from "src/DataAssets/connect-to-data/demo-data/NewDemoAssetDrawer"

import { DataSourceCard, DataSourceCards } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { useState } from "react"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { ConnectToDataButton } from "src/DataAssets/connect-to-data/ConnectToDataButton"
import { Heading2, LG } from "src/ui/typography/Text/Text"
import { Col, Row } from "antd"
import { theme } from "src/ui/themes/theme"

const CenteredContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

export function DataAssetsEmptyScreen({ isEditorOrAdmin }: Readonly<{ isEditorOrAdmin: boolean }>) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isDemoDataDrawerOpen, setIsDemoDataDrawerOpen] = useState(false)
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice>()

  const onDatasourceSelect = (choice: DataSourceChoice) => {
    setIsDrawerOpen(true)
    setDataSourceChoice(choice)
  }

  const isNewDataAssetEmptyStateEnabled = useIsFeatureEnabled("dataAssetEmptyStateEnabled")
  const emptyStateTitle = isNewDataAssetEmptyStateEnabled
    ? "Connect to a data source to get started"
    : "Get started by adding a data asset."

  const showDataAssetCards = isEditorOrAdmin && isNewDataAssetEmptyStateEnabled
  const showConnectToDataButton = isEditorOrAdmin && !isNewDataAssetEmptyStateEnabled
  return (
    <MainContent>
      <CenteredContainer>
        <GetStarted large title={emptyStateTitle}>
          {showConnectToDataButton && <ConnectToDataButton />}
          {showDataAssetCards && (
            <>
              <DataSourceCards large setChoice={onDatasourceSelect} />

              <Heading2 style={{ marginTop: theme.spacing.vertical.s }}>Not ready to connect to your data?</Heading2>
              <Row gutter={16}>
                <Col span={8}>
                  <DataSourceCard
                    large
                    type="POSTGRES"
                    onClick={() => setIsDemoDataDrawerOpen(true)}
                    isDemoDataSource
                  />
                </Col>
              </Row>

              <NewAssetDrawer
                dataSourceChoiceFromEmptyState={dataSourceChoice}
                isOpen={isDrawerOpen}
                setIsOpen={setIsDrawerOpen}
              />
              <NewDemoAssetDrawer isOpen={isDemoDataDrawerOpen} setIsOpen={setIsDemoDataDrawerOpen} />
            </>
          )}
          {!isEditorOrAdmin && <LG>Only Editors and Admins can add Data Assets.</LG>}
        </GetStarted>
      </CenteredContainer>
    </MainContent>
  )
}
