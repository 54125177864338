import { List } from "antd"
import { useQuery } from "@apollo/client"
import { DatasourceWithRunsFragment, DatasourcesWithRunsDocument } from "src/api/graphql/graphql-operations"
import { ManageDatasourceListItem } from "src/DataAssets/manage-datasources/ManageDatasourceListItem"
import { DeleteDatasourceModal } from "src/DataAssets/DeleteDatasourceModal"
import { useEffect, useState } from "react"
import { EditDataSourceDrawer } from "src/DataAssets/manage-datasources/EditDataSourceDrawer"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { Drawer } from "src/ui/Drawer/Drawer"

type Props = {
  open: boolean
  close: () => void
}

type DataSourceToEdit = { name: string; id: string; type: SupportedDataSource } | undefined

export function ManageDatasourcesDrawer({ open, close }: Props) {
  const { data, loading } = useQuery(DatasourcesWithRunsDocument, {
    fetchPolicy: "cache-only",
    onCompleted: (data) => {
      data.datasourcesV2.sort((a, b) => a.name.localeCompare(b.name))
    },
  })
  const [dataSourceToDelete, setDataSourceToDelete] = useState<{ name: string; id: string } | undefined>()
  const [dataSourceToEdit, setDataSourceToEdit] = useState<DataSourceToEdit>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    if (dataSourceToEdit) {
      setIsDrawerOpen(true)
    }
  }, [dataSourceToEdit])

  return (
    <Drawer title="Manage Data Sources" open={open} onClose={close} size="large">
      {dataSourceToDelete && (
        <DeleteDatasourceModal
          datasource={dataSourceToDelete}
          isVisible={!!dataSourceToDelete}
          setIsVisible={() => setDataSourceToDelete(undefined)}
        />
      )}
      <EditDataSourceDrawer
        isOpen={isDrawerOpen}
        afterClose={() => setDataSourceToEdit(undefined)}
        onClose={() => setIsDrawerOpen(false)}
        dataSource={dataSourceToEdit}
      />
      <List
        dataSource={data?.datasourcesV2 ?? ([] as DatasourceWithRunsFragment[])}
        loading={loading}
        rowKey={(datasource) => datasource.id}
        renderItem={(dataSource) => (
          <ManageDatasourceListItem
            dataSource={dataSource}
            onDelete={() => setDataSourceToDelete(dataSource)}
            onEdit={() => setDataSourceToEdit(dataSource as DataSourceToEdit)} // don't love casting, but the button that can call this is disabled if type is not supported
          />
        )}
      />
    </Drawer>
  )
}
