import { Divider, Empty, Flex } from "antd"
import { useQuery } from "@apollo/client"
import { Button } from "src/ui/Button/Button"
import {
  DataAssetBreadcrumbs_DataSourceFragmentDocument,
  getDataAssetBreadcrumbs,
} from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { FragmentType } from "src/api/graphql/fragment-masking"
import { SimpleScheduleSummary } from "src/DataAssets/AssetDetails/Expectations/SimpleScheduleSummary"
import { BatchDefinitionWithEditing } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionWithEditing"
import { RunValidationButton } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/RunValidationButton"
import { theme } from "src/ui/themes/theme"
import { SimpleNewExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer"
import { AssetFlowIds } from "src/Alerts/AlertsDrawer"
import { AlertNotificationsButton } from "src/Alerts/AlertNotificationsButton"
import { ExpectationsTabDataAssetDocument } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationsTab"
import { isWholeAssetSplitterType } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"

type ActionsProps = {
  isEditor: boolean
  hasAlertBtn?: boolean
  assetId: string | undefined
  isNewExpDrawerOpen: boolean
  setIsNewExpDrawerOpen: (open: boolean) => void
}

type HeaderContentProps = {
  dataAssetName: string
  subtitle: string
  datasources: FragmentType<typeof DataAssetBreadcrumbs_DataSourceFragmentDocument> | null | undefined
}

export const headerContent = ({
  dataAssetName,
  subtitle,
  isEditor,
  assetId,
  isNewExpDrawerOpen,
  setIsNewExpDrawerOpen,
  datasources,
}: ActionsProps & HeaderContentProps) => ({
  title: dataAssetName,
  subtitle: subtitle,
  rootPath: "data-assets",
  navigateBackTo: "/data-assets",
  rightActions: {
    reactNode: (
      <HeaderActions
        isEditor={isEditor}
        hasAlertBtn={true}
        assetId={assetId}
        isNewExpDrawerOpen={isNewExpDrawerOpen}
        setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
      />
    ),
  },
  customBreadcrumbs: getDataAssetBreadcrumbs({
    dataSource: datasources,
    tooltipPlacement: "right",
  }),
  footer: <DataAssetTabs currentTab="expectations" />,
})

export function ExpectationsTableHeader({
  dataAssetId,
  hasExpectations,
}: {
  dataAssetId: string | undefined
  hasExpectations: boolean
}) {
  const { data } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: { id: dataAssetId as string },
    skip: !dataAssetId,
  })

  const hasBatchDefinition = !isWholeAssetSplitterType(data?.dataAsset?.splitter?.__typename)

  return (
    <>
      <Flex justify="space-between" align="center">
        {data?.dataAsset?.splitter && (
          <BatchDefinitionWithEditing dataAssetId={data.dataAsset.id} splitterDataFragment={data.dataAsset.splitter} />
        )}
        {dataAssetId && <RunValidationButton dataAssetId={dataAssetId} hasBatchDefinition={hasBatchDefinition} />}
      </Flex>
      {hasExpectations && (
        <>
          <Divider style={{ margin: `${theme.spacing.vertical.xs} 0` }} />
          <SimpleScheduleSummary schedule={data?.dataAsset?.schedule} />
        </>
      )}
    </>
  )
}

export function HeaderActions({
  isEditor,
  hasAlertBtn,
  assetId,
  isNewExpDrawerOpen,
  setIsNewExpDrawerOpen,
}: ActionsProps) {
  return (
    <>
      {isEditor && (
        <Flex justify="space-between" gap={theme.spacing.scale.xxxs}>
          {hasAlertBtn && <AlertNotificationsButton assetFlowIds={{ assetId } as AssetFlowIds} />}
          <Button icon="plus" type="primary" onClick={() => setIsNewExpDrawerOpen(true)}>
            New Expectation
          </Button>
          {assetId && (
            <SimpleNewExpectationDrawer
              dataAssetId={assetId}
              open={isNewExpDrawerOpen}
              close={() => setIsNewExpDrawerOpen(false)}
            />
          )}
        </Flex>
      )}
    </>
  )
}

export function EmptyFilteredState({
  isEditor,
  assetId,
  isNewExpDrawerOpen,
  setIsNewExpDrawerOpen,
  hasExpectations,
}: ActionsProps & { hasExpectations: boolean }) {
  if (!isEditor && !hasExpectations) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  const displayText = hasExpectations
    ? "No Expectations match filtering criteria"
    : "Add the first Expectation to start validating your Asset"

  return (
    <Flex align="center" vertical>
      <p>{displayText}</p>
      {!hasExpectations && (
        <HeaderActions
          isEditor={isEditor}
          hasAlertBtn={false}
          assetId={assetId}
          isNewExpDrawerOpen={isNewExpDrawerOpen}
          setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
        />
      )}
    </Flex>
  )
}
