import { Outlet } from "react-router-dom"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { useOrganizations } from "src/organizations/useOrganizations"
import { LoadingState } from "src/ui/LoadingState"
import { useEffect } from "react"
import { __to_uuid } from "src/common/utils/uuid"
import { useQuery } from "@apollo/client"
import { OrganizationDocument } from "src/api/graphql/graphql-operations"
import { NotFound } from "src/ui/error/NotFound"

/**
 * Organization Root Component. This is the top-level
 * component that renders any subroutes nested under
 * an organization.
 */
export function OrganizationRoot(): JSX.Element {
  const { orgSlug } = useOrganizationSlug()
  const {
    loading: orgDataLoading,
    data,
    error: orgError,
  } = useQuery(OrganizationDocument, {
    variables: { slug: orgSlug },
  })
  const { isLoading: userDataLoading, currentOrg, setCurrentOrg } = useOrganizations()
  const isLoading = orgDataLoading || userDataLoading

  /*
   * This is currently kind of confusing.
   * The URL (from useOrganizationSlug) needs to dictate which org we are in, but
   * currentOrg (from useOrganizations) is used as the source of truth in the rest
   * of the SPA. So this loads the org based on the slug, then sets the currentOrg.
   *
   * TODO:
   *  - get rid of replace useOrganizations with useOrganizationSlug and simplify this?
   *  - add an organization context so all children have a loaded org?
   */
  useEffect(() => {
    const idToUse = data?.organization?.id ? __to_uuid(data.organization.id) : undefined
    if (!isLoading && data?.organization?.slug && idToUse && idToUse !== currentOrg?.id) {
      setCurrentOrg(data.organization.slug)
    }
  }, [isLoading, orgSlug, data, currentOrg?.id, setCurrentOrg])

  if (orgError) {
    return <NotFound />
  }
  if (isLoading) {
    return <LoadingState loading />
  }
  return <Outlet />
}
