import { DatePicker, Flex, Form } from "antd"
import { useState } from "react"
import { SplitterOptions } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/BatchDefinitionForValidationRunPopOver/BatchDefinitionForValidationRunPopOver"
import { getFormatString, parseDate, SplitterDateConfig } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { SplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { Button } from "src/ui/Button/Button"
import styled from "styled-components"

const RunButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.vertical.xs};
`

type BatchDefinitionForValidationRunFormProps = {
  onFinish: (values?: SplitterOptions) => void
  splitterData: Omit<SplitterData, "isSplitterSupported">
  isCustomBatchType: boolean
}

export const BatchDefinitionForValidationRunForm = ({
  onFinish,
  splitterData,
  isCustomBatchType,
}: BatchDefinitionForValidationRunFormProps) => {
  const [dateSelection, setDateSelection] = useState<string | null>(null)
  const { dateFormat, picker, splitterMethod, splitterOptionsString } = splitterData
  const [form] = Form.useForm()

  const formatStr = getFormatString(dateFormat)

  const formatCustomSplitterOption = (): Record<string, SplitterDateConfig> | undefined => {
    if (isCustomBatchType) return dateSelection ? { [splitterOptionsString]: parseDate(dateSelection) } : undefined
  }

  return (
    <Form
      style={{ marginTop: 20 }}
      form={form}
      name="selectCheckpointValidationDrawer"
      layout="vertical"
      validateTrigger={["onBlur"]}
      preserve={false}
      onFinish={() => {
        onFinish(formatCustomSplitterOption())
      }}
    >
      <Form.Item
        name="datepicker"
        label={`${splitterMethod}:`}
        id="datepicker"
        rules={[{ required: isCustomBatchType, message: `Use the format ${formatStr}` }]}
        style={{ display: isCustomBatchType ? "block" : "none", width: "100%" }}
      >
        <DatePicker
          picker={picker}
          style={{ width: "50%" }}
          placeholder={formatStr}
          format={dateFormat}
          onChange={(_date, dateString) => {
            setDateSelection(Array.isArray(dateString) ? dateString[0] : dateString)
          }}
        />
      </Form.Item>
      <Flex justify="flex-end">
        <RunButton type="primary" htmlType="submit">
          Run
        </RunButton>
      </Flex>
    </Form>
  )
}
