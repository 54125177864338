import { Empty, Space } from "antd"
import { PageHeader } from "src/ui/PageHeader"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { ErrorState } from "src/ui/error"
import { pluralize } from "src/common/utils/strings"
import { MainContent } from "src/layout/MainContent"
import { DataAssetsList } from "src/DataAssets/DataAssetsList"
import { DataAssetsEmptyScreen } from "src/DataAssets/DataAssetsEmptyScreen"
import { ConnectToDataButton } from "src/DataAssets/connect-to-data/ConnectToDataButton"
import { DataAssetsFilter } from "src/DataAssets/DataAssetsFilter"
import { useDataAssets } from "src/DataAssets/useDataAssets"
import { useUrlParams } from "src/common/utils/url-params"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { DatasourcesWithRunsDocument } from "src/api/graphql/graphql-operations"
import { ManageDatasourcesButton } from "src/DataAssets/manage-datasources/ManageDatasourcesButton"
import { useRefetchOnJobComplete } from "src/DataAssets/AssetDetails/useRefetchOnJobComplete"

export const DataAssets = () => {
  const isEditorOrAdmin = useRequireRole("EDITOR")

  const {
    datasourceCount,
    assetCount,
    loading,
    error,
    isDataAssetsEmpty,
    isDataSourcesEmpty,
    assetAndDatasourceTuples,
    refetch,
  } = useDataAssets(DatasourcesWithRunsDocument)
  useRefetchOnJobComplete({ jobType: "ValidateDataJob", refetch })

  const headerData: HeaderTypes = {
    title: "Data Assets",
    subtitle: `${datasourceCount} ${pluralize("Data Source", datasourceCount)}, ${assetCount} ${pluralize(
      "Data Asset",
      assetCount,
    )}`,
  }

  return (
    <PageHeader
      headerContent={{
        ...headerData,
        rightActions: isDataSourcesEmpty
          ? {}
          : {
              reactNode: isEditorOrAdmin && (
                <Space direction="horizontal">
                  <ManageDatasourcesButton />
                  <ConnectToDataButton />
                </Space>
              ),
            },
      }}
      loading={loading}
    >
      {isDataAssetsEmpty ? (
        <DataAssetsEmptyScreen isEditorOrAdmin={isEditorOrAdmin} />
      ) : (
        <MainContent>
          {error ? (
            <ErrorState errorMessage="There was a problem loading Data Assets" />
          ) : (
            <DataAssetsList
              dataAssets={assetAndDatasourceTuples}
              header={<DataAssetsFilter />}
              emptyState={<NoResultsState />}
              loading={loading}
            />
          )}
        </MainContent>
      )}
    </PageHeader>
  )
}

function NoResultsState() {
  const [{ search: searchTerm, failures }] = useUrlParams({
    failures: "",
    search: "",
  })

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        failures === "true"
          ? "Nice job! No failed validations were found."
          : `Can't find Data Asset or Data Source with ${searchTerm} `
      }
    />
  )
}
