import { debounce } from "lodash-es"
import { useUrlParams } from "src/common/utils/url-params"

import styled from "styled-components"
import { Search as BaseStyledSearch } from "src/ui/Search/Search"

const StyledSearch = styled(BaseStyledSearch)`
  width: 100%;
`

type SearchParams = {
  search: string
}

export const Search = ({ placeholder = "Search..." }: { placeholder?: string }) => {
  const [{ search }, setParams] = useUrlParams<SearchParams>({ search: "" })
  const debouncedOnChange = debounce((value) => {
    setParams({ search: value })
  }, 300)
  return (
    <StyledSearch
      placeholder={placeholder}
      defaultValue={search}
      allowClear
      onChange={(e) => {
        e.stopPropagation()
        debouncedOnChange(e.target.value)
      }}
      role="search"
      type="search"
      name="search"
      aria-label="search data source or data asset input"
    />
  )
}
