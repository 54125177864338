import styled from "styled-components"
import { Icon, IconProps, IconTypes } from "src/ui/Icon"

type FeedbackMessageProps = {
  title: string
  description?: string | React.ReactNode
  type: "error" | "success" | "warning"
}

const Background = styled.div<{ $type: "success" | "error" | "warning" }>`
  display: flex;
  width: 100%;
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case "error":
        return theme.colors.error.gxErrorBackground
      case "warning":
        return theme.colors.warning.colorWarningBg
      case "success":
        return theme.colors.success.gxSuccessBackground
      // no default
    }
  }};
  padding: ${({ theme }) => theme.spacing.horizontal.xs};
`

const StyledIcon = styled<React.FC<IconProps>>(Icon)`
  margin-right: ${({ theme }) => theme.spacing.horizontal.xs};

  & svg {
    color: ${({ theme, name }) => {
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (name) {
        case "warning":
          return theme.colors.error.gxError
        case "checkCircle":
          return theme.colors.success.gxSuccess
        case "exclamationCircle":
          return theme.colors.warning.colorWarning
        // no default
      }
    }};
  }
`

const MessageWrapper = styled.div``

const Title = styled.h4`
  font-size: ${({ theme }) => theme.typography.fontSize.small};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorText};
`
const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.small};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorText};
  margin-bottom: 0;
`

// FeedbackMessage is deprecated - use antd Alert instead
export function FeedbackMessageDoNotUse({ title, description, type }: FeedbackMessageProps) {
  const typeToIconNameMap: Record<FeedbackMessageProps["type"], IconTypes> = {
    error: "warning",
    warning: "exclamationCircle",
    success: "checkCircle",
  }
  return (
    <Background $type={type}>
      <StyledIcon name={typeToIconNameMap[type]} />
      <MessageWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </MessageWrapper>
    </Background>
  )
}
