/* eslint-disable react-refresh/only-export-components */ // FIXME
import { AppRouter } from "src/layout/routes/AppRouter"
import { ErrorBoundary } from "react-error-boundary"
import posthog from "posthog-js"
import { POSTHOG_API_HOST } from "src/common/config"
import { REACT_APP_POSTHOG_API_KEY } from "src/common/env"
import { FeedbackForm } from "src/observability/sentry/FeedbackForm"
import { Sentry } from "src/observability/sentry/sentry"
import { ConfiguredThemeProvider } from "src/ui/themes/ConfiguredThemeProvider"
import Auth0ProviderWithHistory from "src/authentication/Auth0ProviderWithHistory"
import { ApolloAuth0AuthenticatedProvider } from "src/api/ApolloAuthenticatedProvider"
import LayoutContextProvider from "src/layout/layoutContext"

function App() {
  posthog.init(REACT_APP_POSTHOG_API_KEY, { api_host: POSTHOG_API_HOST })
  return (
    <ConfiguredThemeProvider>
      <ErrorBoundary FallbackComponent={FeedbackForm}>
        <Auth0ProviderWithHistory>
          <ApolloAuth0AuthenticatedProvider>
            <LayoutContextProvider>
              <AppRouter />
            </LayoutContextProvider>
          </ApolloAuth0AuthenticatedProvider>
        </Auth0ProviderWithHistory>
      </ErrorBoundary>
    </ConfiguredThemeProvider>
  )
}

export default Sentry.withProfiler(App)
