import { camelCase } from "lodash-es"

export function pluralize(singular: string, count: number): string {
  if (count === 1) {
    return singular
  }

  if (singular.slice(-2) === "us") {
    return singular.slice(0, singular.length - 2) + "i"
  }

  if (singular.slice(-2) === "ss") {
    return singular + "es"
  }

  if (singular.slice(-1) === "y") {
    return singular.slice(0, singular.length - 1) + "ies"
  }

  return singular + "s"
}

export const pascalCase = (s: string = ""): string => camelCase(s).charAt(0).toUpperCase() + camelCase(s).slice(1)
