import { useQuery } from "@apollo/client/react/hooks/useQuery"
import { ExpectationChangesDocument } from "src/api/graphql/graphql-operations"
import { groupExpectationChanges } from "src/common/utils/groupExpectations"
import { useMemo } from "react"

export function useExpectationHistoryQuery(search: string, expectationSuiteId: string, limit?: number) {
  const {
    data: expectationsChangesData,
    loading: expectationsChangesLoading,
    error: expectationsChangesError,
  } = useQuery(ExpectationChangesDocument, {
    variables: {
      expectation_suite_id: expectationSuiteId,
      limit,
    },
    fetchPolicy: limit ? "cache-and-network" : "network-only",
    skip: !expectationSuiteId,
  })

  const expectationsWithChanges = expectationsChangesData?.expectationChanges
  const expectationChangesGroupedByColumn = useMemo(() => {
    return groupExpectationChanges(expectationsWithChanges, search)
  }, [expectationsWithChanges, search])
  const changeLogCount =
    expectationsWithChanges?.flatMap((expectationChange) => {
      return expectationChange.changes
    }).length || 0
  return {
    expectationsChangesData,
    expectationsChangesLoading,
    expectationsChangesError,
    expectationChangesGroupedByColumn,
    expectationsWithChanges,
    changeLogCount,
  }
}
