import { Skeleton } from "antd"
import { EmptyState } from "src/ui/EmptyState/EmptyState"
import { EmptyExpectationSuite } from "src/DataAssets/AssetDetails/Expectations/EmptyExpectationSuite"
import { ErrorState } from "src/ui/error/ErrorState"

type ErrorAndEmptyStateProps = {
  expectationsLoading?: boolean
  showNoSearchResults?: boolean
  showExpectationsEmptyState?: boolean
  search?: string
  expectationSuiteId?: string
  assetId?: string
  errorMessage?: string
  children?: React.ReactNode
}

export const ErrorAndEmptyState = ({
  expectationsLoading,
  showNoSearchResults,
  showExpectationsEmptyState,
  search,
  expectationSuiteId,
  assetId,
  errorMessage,
  children,
}: ErrorAndEmptyStateProps) => {
  if (expectationsLoading) {
    return <LoadingList loading={expectationsLoading} />
  }

  if (showNoSearchResults && search) {
    return <EmptyState title={`No Columns found with the search term "${search}"`} />
  }

  if (showExpectationsEmptyState && expectationSuiteId && assetId) {
    return <EmptyExpectationSuite assetId={assetId} expectationSuiteId={expectationSuiteId} />
  }

  if (errorMessage) {
    return <ErrorState errorMessage={errorMessage} />
  }

  return <>{children}</>
}

function LoadingList({ loading }: Readonly<{ loading: boolean | undefined }>) {
  return (
    <>
      {new Array(3).fill(null).map((value, index) => (
        <Skeleton
          aria-label="Loading Expectation list"
          key={index}
          active
          paragraph={{ rows: 5 }}
          title={false}
          loading={loading}
        />
      ))}
    </>
  )
}
