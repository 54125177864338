import { useAnalytics } from "src/analytics/useAnalytics"
import { snakeCase } from "lodash-es"
import { getFilteredExpectationsCardsData } from "src/Expectation/CreateExpectationDrawer/utils"
import { ExpectationCardProps } from "src/Expectation/CreateExpectationDrawer/types"
import { ExpectationCategory } from "src/schemas/expectation-metadata-category"

type AnalyticsProps = {
  searchTerms?: string
  filtersApplied?: string[]
  suiteId?: string | null
  suiteName?: string | null
}

type CreateExpectationClickHandler = (
  props: {
    onSelectExpectation: (newExpectation: ExpectationCardProps) => void
    expectationCardProps: ExpectationCardProps
    selectionPosition: number
  } & AnalyticsProps,
) => () => void

export const useCreateExpectationSelection: () => CreateExpectationClickHandler = () => {
  const posthog = useAnalytics()

  return ({ onSelectExpectation, expectationCardProps: data, selectionPosition, ...additionalAnalytics }) => {
    const expectationType = data.title
    const categories = data.category ? [snakeCase(data.category)] : []

    return () => {
      onSelectExpectation(data)
      posthog?.capture("expectation.selected", {
        expectationType,
        categories,
        selectionPosition,
        ...additionalAnalytics,
      })
    }
  }
}

type UseFilteredExpectations = (
  props: {
    onSelectExpectation: (newExpectation: ExpectationCardProps) => void
    selectedCategoryFilter?: ExpectationCategory
    searchInput?: string
  } & Omit<AnalyticsProps, "searchTerms" | "filtersApplied">,
) => {
  filteredExpectationsCardsData: ExpectationCardProps[]
}

export const useFilteredExpectationsCards: UseFilteredExpectations = ({
  onSelectExpectation,
  selectedCategoryFilter,
  searchInput,
  ...analyticsProps
}) => {
  const cards = getFilteredExpectationsCardsData(searchInput, selectedCategoryFilter)
  const clickMaker = useCreateExpectationSelection()
  return {
    filteredExpectationsCardsData: cards.map((filteredExpectation, selectionPosition) => ({
      ...filteredExpectation,
      onClick: clickMaker({
        onSelectExpectation,
        expectationCardProps: filteredExpectation,
        selectionPosition,
        searchTerms: searchInput,
        filtersApplied: selectedCategoryFilter ? [snakeCase(selectedCategoryFilter)] : undefined,
        ...analyticsProps,
      }),
    })),
  }
}
