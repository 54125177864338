import { ApolloClient, ApolloLink, createHttpLink, DefaultOptions, from } from "@apollo/client"
import { ErrorHandler, ErrorResponse, onError } from "@apollo/client/link/error"
import { Sentry } from "src/observability/sentry/sentry"
import { createCache } from "src/api/apollo-cache"
import { NetworkError } from "@apollo/client/errors"
import { NODE_ENV } from "src/common/env"

export const defaultOptions: DefaultOptions = {
  query: {
    errorPolicy: "all", //Always show any partial data & error when error is present
  },
  watchQuery: {
    errorPolicy: "all", //Always show any partial data & error when error is present
  },
}

type NetworkErrorWithStatusCode = NetworkError & { statusCode: number }

function hasStatusCode(networkError: NetworkError | undefined): networkError is NetworkErrorWithStatusCode {
  return !!networkError && "statusCode" in networkError
}

const generateApolloClientWithAuth0 = (
  authLink: ApolloLink,
  dataPlaneUrl: string,
  isAuthenticated: boolean,
  organizationId: string,
  onAuthError?: () => void,
) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const errorLink = (onAuthError?: () => void) => {
    const errorHandler: ErrorHandler = ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
      const authErrorCodes = [401, 403]
      if (hasStatusCode(networkError) && authErrorCodes.includes(networkError.statusCode)) {
        Sentry.captureMessage(networkError.message)
        onAuthError?.()
      }

      if (networkError) {
        Sentry.captureMessage(networkError.message)
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Sentry.captureMessage(message)
        })
      }
      return forward(operation)
    }
    return onError(errorHandler)
  }

  const apiLink = createHttpLink({
    uri: `${dataPlaneUrl}/organizations/${organizationId}/graphql`,
    credentials: "include",
  })

  const client = new ApolloClient({
    connectToDevTools: NODE_ENV === "development",
    cache: createCache(),
    defaultOptions,
    link: isAuthenticated ? from([errorLink(onAuthError), authLink, apiLink]) : errorLink(onAuthError),
  })

  return client
}

export { generateApolloClientWithAuth0 }
