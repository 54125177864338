import { LabelLink2 } from "src/ui/typography/Text/Text"
import { SUPPORT_EMAIL_URL } from "src/common/config"

/** For consistency, use this component for all usages of the support email address */
export function SupportEmailLink() {
  return (
    <LabelLink2 href={SUPPORT_EMAIL_URL} target="_blank" rel="noopener noreferrer">
      support@greatexpectations.io
    </LabelLink2>
  )
}
