export const ALERT_CREATE_SUCCESS_MESSAGE = "Alert created successfully"
export const ALERT_DELETE_SUCCESS_MESSAGE = "Alert deleted successfully"
export const ALERT_UPDATE_SUCCESS_MESSAGE = "Alert updated successfully"
export const EDIT_ALERT_ERROR_TEXT = {
  EMPTY: "Field cannot be blank.",
  UNEXPECTED_ERROR: "An unexpected error occurred when attempting to update this alert. Try again or contact support.",
  CONNECTION_TEST_FAILURE: "Ensure incoming webhook link is correct.",
}
export const FAILED_TO_SAVE_ALERT = "Failed to save alert"

// Checkpoint Alert Words
export const ALERT_NAME = {
  LABEL: "Alert name",
  PLACEHOLDER: "Name your alert",
}
export const WEBHOOK = {
  LABEL: "Webhook",
  PLACEHOLDER: "https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXX....",
}

const EMAIL_ALERT_DESCRIPTION_END = " fails validation."
export const EMAIL_ALERT_ENABLED_DESCRIPTION = [
  "You will receive an email when any Expectation on ",
  EMAIL_ALERT_DESCRIPTION_END,
]
export const EMAIL_ALERT_DISABLED_DESCRIPTION = [
  "You will not receive an email when any Expectation on ",
  EMAIL_ALERT_DESCRIPTION_END,
]

type AlertType = {
  [key: string]: string
}

export const ALERT_TYPE: AlertType = {
  LABEL: "Alert type",
  ALL: "Show all",
  FAILURE: "Failures",
  SUCCESS: "Success",
}

// Form Names
export const SLACK_ALERT_FORM = "slackAlertForm"

// Field Names
export const CHECKPOINT_DROPDOWN_FIELD = "checkpointsDropdown"
export const SLACK_ALERT_NAME_FIELD = "alertName"
export const SLACK_ALERT_WEBHOOK_FIELD = "webhook"
export const SLACK_ALERT_TYPE_FIELD = "alertType"

export const SLACK_ACTION = "SlackNotificationAction"
