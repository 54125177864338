import { graphql } from "src/api/graphql/gql"

export const EditBatchForm_SplitterFragmentDocument = graphql(`
  fragment EditBatchForm_Splitter on SplitterUnion {
    ... on SplitterYearAndMonthAndDay {
      __typename
      id
      columnName
    }
    ... on SplitterYearAndMonth {
      id
      columnName
      __typename
    }
    ... on SplitterYear {
      id
      columnName
      __typename
    }
    ... on SplitterColumnValue {
      id
      columnName
      __typename
    }
    ... on SplitterConvertedDateTime {
      id
      columnName
      __typename
    }
    ... on SplitterDatetimePart {
      id
      columnName
      __typename
    }
    ... on SplitterDividedInteger {
      id
      columnName
      __typename
    }
    ... on SplitterHashedColumn {
      id
      columnName
      __typename
    }
    ... on SplitterModInteger {
      id
      columnName
      __typename
    }
    ... on SplitterMultiColumnValue {
      id
      columnNames
      __typename
    }
    ... on SplitterWholeAsset {
      id
    }
  }
`)
