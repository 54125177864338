//do not use - this is should be avoided
export function __to_uuid(str: string): string {
  let result = ""

  try {
    result = atob(str)
  } catch {
    console.error("failed to parse global ID: atob")
    return ""
  }

  const splits = result.split(":")

  if (splits.length === 1) {
    return splits[0]
  }
  if (splits.length !== 2) {
    console.error("failed to parse global ID: expected 2 segments, got " + splits.length)
    return ""
  }
  return splits[1]
}
