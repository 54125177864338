import { vanillaCells } from "@jsonforms/vanilla-renderers"
import { JsonFormsCellRendererRegistryEntry, JsonFormsRendererRegistryEntry } from "@jsonforms/core"

import { CategorizationRegistryEntry } from "src/jsonforms/complex/CategorizationRenderer"
import { CategoryRegistryEntry } from "src/jsonforms/complex/CategoryControl"
import { UnknownCellRegistryEntry } from "src/jsonforms/unknown-renderer"
import { rendererRegistryEntries as _rendererRegistryEntries } from "@great-expectations/jsonforms-antd-renderers"

export const ossRendererRegistryEntries: JsonFormsRendererRegistryEntry[] = [
  ..._rendererRegistryEntries.slice(1),
  CategorizationRegistryEntry,
  CategoryRegistryEntry,
  ..._rendererRegistryEntries.slice(0, 1),
]

export const cellRegistryEntries: JsonFormsCellRendererRegistryEntry[] = [...vanillaCells, UnknownCellRegistryEntry]
