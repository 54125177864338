import { RuleEffect } from "@jsonforms/core"

export const baseUISchemaElements = [
  {
    type: "Control",
    scope: "#/properties/id",
    rule: {
      effect: RuleEffect.HIDE,
      condition: {},
    },
  },
  {
    type: "Control",
    scope: "#/properties/meta",
    rule: {
      effect: RuleEffect.HIDE,
      condition: {},
    },
  },
  {
    type: "Control",
    scope: "#/properties/rendered_content",
    rule: {
      effect: RuleEffect.HIDE,
      condition: {},
    },
  },
  {
    type: "Control",
    scope: "#/properties/batch_id",
    rule: {
      effect: RuleEffect.HIDE,
      condition: {},
    },
  },
  {
    type: "Control",
    scope: "#/properties/metadata",
    rule: {
      effect: RuleEffect.HIDE,
      condition: {},
    },
  },
  {
    type: "Control",
    scope: "#/properties/notes",
  },
  {
    type: "Control",
    scope: "#/properties/description",
    rule: {
      effect: RuleEffect.HIDE,
      condition: {},
    },
  },
]
