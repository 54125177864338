import { useState, useCallback, useRef } from "react"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { Form, message, Space } from "antd"
import { Drawer } from "src/ui/Drawer/Drawer"
import { useMutation, useQuery } from "@apollo/client"
import { LoadingState } from "src/ui/LoadingState"
import { graphql } from "src/api/graphql/gql"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { ExpectationEditorSimplified } from "src/Expectation/ExpectationEditorSimplified"
import { ExpectationPickerSimplified } from "src/Expectation/CreateExpectationDrawer/ExpectationPickerSimplified"
import {
  SaveExpectationFooter,
  SelectExpectationFooter,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerFooters.tsx"
import { SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/types"
import {
  ExpectationMetaInfo,
  ExpectationTitle,
  SnakeCasedExpectationType,
} from "src/schemas/expectation-metadata-utils"
import { handleWindowedPayload } from "src/Expectation/CreateExpectationDrawer/windowedExpectationUtils"
import { handleRowConditionPayload } from "src/Expectation/uiForms/customRenderers/RowConditionControl/rowConditionParser"
import {
  ExpectationsTab_GetExpectationsDocument,
  ExpectationsTabDataAssetDocument,
} from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationsTab"
import { isEqual } from "lodash-es"
import { CREATE_EXPECTATION_SUCCESS } from "src/Expectation/CreateExpectationDrawer/words"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { CreateExpectationDrawerAlert } from "src/Alerts/DemoData/CreateExpectationDrawerAlert"
import { useIsDemoData } from "src/common/hooks/useIsDemoData"
import { theme } from "src/ui/themes/theme"
import { useDemoDataAssetName } from "src/common/hooks/useDemoDataAssetName"

export const ExpectationDrawer_DataAssetDocument = graphql(`
  query expectationDrawer_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      ...ExpectationEditor_DataAssetMetricRun
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

const AddExpectationToAssetDocument = graphql(`
  mutation addExpectationToAsset($input: AddExpectationToDataAssetInput!) {
    addExpectationToDataAsset(input: $input) {
      expectation {
        geCloudId
      }
    }
  }
`)

type Props = {
  open: boolean
  close: () => void
  dataAssetId: string
}

export function SimpleNewExpectationDrawer({ open, dataAssetId, close }: Props) {
  const windowedParamsEnabled = useIsFeatureEnabled("windowedParamsEnabled")
  const [expectationConfiguration, setExpectationConfiguration] = useState({})
  const [selectedExpectation, setSelectedExpectation] = useState<SelectedExpectation>()
  const [drawerPage, setDrawerPage] = useState<DrawerPage>("1 Expectation Picker")
  const [createExpectationMutation, { loading, error: addExpectationToAssetError, reset }] = useMutation(
    AddExpectationToAssetDocument,
    {
      refetchQueries: [
        { query: ExpectationsTab_GetExpectationsDocument, variables: { input: { dataAssetId } } },
        { query: ExpectationsTabDataAssetDocument, variables: { id: dataAssetId as string } },
      ],
    },
  )
  const [form] = Form.useForm()

  const lastConfigRef = useRef({})

  const handleConfigChange = useCallback((newConfig: Record<string, unknown>) => {
    // There seems to be a side effect where the onChange handler is invoked after
    // form submission but before the modal is closed resetting the state to the
    // form value when drawer is closed. lastConfigRef acts as an intermediate step
    // to compare previous and new configurations, preventing state from persisting
    // between drawer sessions.
    setExpectationConfiguration((prevConfig) => {
      if (isEqual(lastConfigRef.current, newConfig)) {
        return prevConfig
      }
      lastConfigRef.current = newConfig
      return newConfig
    })
  }, [])

  const resetState = useCallback(() => {
    setDrawerPage("1 Expectation Picker")
    setExpectationConfiguration({})
    setSelectedExpectation(undefined)
    reset()
  }, [reset])

  const handleClose = useCallback(() => {
    close()
    resetState()
  }, [close, resetState])

  const saveExpectation = useCallback(
    async (addMore: boolean | undefined) => {
      try {
        await form.validateFields()

        if (!selectedExpectation?.value) return // compiler assurance

        await createExpectationMutation({
          variables: {
            input: {
              dataAssetId,
              expectationConfiguration: JSON.stringify(
                handleRowConditionPayload(
                  handleWindowedPayload(
                    {
                      ...expectationConfiguration,
                      expectation_type: selectedExpectation.value,
                    },
                    selectedExpectation.value as SnakeCasedExpectationType,
                    windowedParamsEnabled,
                  ),
                ),
              ),
            },
          },
        })

        message.success(CREATE_EXPECTATION_SUCCESS, MESSAGE_DURATION_SECONDS)
        addMore ? resetState() : handleClose()
      } catch {
        // nothing to do here; form validation will render error text as feedback
      }
    },
    [
      form,
      selectedExpectation?.value,
      createExpectationMutation,
      dataAssetId,
      expectationConfiguration,
      windowedParamsEnabled,
      resetState,
      handleClose,
    ],
  )

  const { data } = useQuery(ExpectationDrawer_DataAssetDocument, {
    variables: {
      id: dataAssetId,
    },
    skip: !dataAssetId,
  })

  const onSelectExpectation = (expectation: SelectedExpectation) => {
    setSelectedExpectation(expectation)
    setDrawerPage("2 Expectation Editor")
  }
  const isDemoData = useIsDemoData(data?.dataAsset)
  const assetName = useDemoDataAssetName(data?.dataAsset)

  return (
    <Drawer
      title="New Expectation"
      placement="right"
      size="large"
      footer={
        <Footer
          drawerPage={drawerPage}
          onSave={saveExpectation}
          onBack={resetState}
          onSelectExpectation={onSelectExpectation}
          loading={loading}
        />
      }
      destroyOnClose
      open={open}
      onClose={handleClose}
    >
      {isDemoData && (
        <Space direction="vertical" style={{ marginBottom: theme.spacing.vertical.xs }}>
          <CreateExpectationDrawerAlert
            assetName={assetName}
            page={drawerPage === "1 Expectation Picker" ? "picker" : "editor"}
            selectedExpectationTitle={selectedExpectation?.title as ExpectationTitle}
          />
        </Space>
      )}
      {drawerPage === "1 Expectation Picker" && (
        <ExpectationPickerSimplified onSelectExpectation={onSelectExpectation} />
      )}
      {drawerPage === "2 Expectation Editor" && selectedExpectation?.title ? (
        <Form form={form} layout="vertical">
          <ExpectationEditorSimplified
            value={expectationConfiguration}
            expectationMetaInfo={{ ...selectedExpectation, type: selectedExpectation.value } as ExpectationMetaInfo}
            onChange={handleConfigChange}
            dataAsset={data?.dataAsset ?? undefined}
          />
          {addExpectationToAssetError && (
            <AlertBanner message="Failed to add Expectation" description={addExpectationToAssetError.message} />
          )}
        </Form>
      ) : (
        <LoadingState loading={loading} />
      )}
    </Drawer>
  )
}

type DrawerPage = "1 Expectation Picker" | "2 Expectation Editor"
type FooterProps = {
  drawerPage: DrawerPage
  onBack: () => void
  onSave: (addMore?: boolean) => void
  loading: boolean
  onSelectExpectation: (expectation: SelectedExpectation) => void
}
function Footer({ drawerPage, onBack, onSave, loading, onSelectExpectation }: FooterProps) {
  if (drawerPage === "1 Expectation Picker") {
    return <SelectExpectationFooter onSelectExpectation={onSelectExpectation} />
  }
  if (drawerPage === "2 Expectation Editor") {
    return (
      <SaveExpectationFooter
        onBack={onBack}
        onSave={onSave}
        onSaveAndAdd={() => {
          onSave(true)
        }}
        saveLoading={loading}
      />
    )
  }
  return null
}
