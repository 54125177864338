import { Navigate, useLocation } from "react-router-dom"
import { LoadingState } from "src/ui/LoadingState"
import { useOrganizations } from "src/organizations/useOrganizations"
import { ErrorState } from "src/ui/error/ErrorState"
import { useQuery } from "@apollo/client"
import { OrganizationDocument } from "src/api/graphql/graphql-operations"

/**
 * Redirects to the current path, except nested under the org.
 * Primary use cases:
 *  - Putting a user who arrives at / in a usable state with an org prefix
 *  - Providing backward compatibility if users had links bookmarked prior to org prefixes in urls
 *
 * Note: The logic here is spread across 2 components because the inputs to useOrganizationQuery
 *       depend on the output from another async hook
 */
export const DefaultOrganizationRedirect = () => {
  const { currentOrg } = useOrganizations()

  if (currentOrg) {
    return <RedirectToOrgById id={currentOrg.id} />
  }
  return <LoadingState loading />
}

type RedirectToOrgByIdProps = {
  id: string
}

const RedirectToOrgById = ({ id }: RedirectToOrgByIdProps) => {
  const location = useLocation()
  const { loading, data } = useQuery(OrganizationDocument, { variables: { id } })

  if (loading) {
    return <LoadingState loading />
  }
  if (data?.organization?.slug) {
    return <Navigate to={`/organizations/${data.organization.slug}${location.pathname}`} />
  }
  return <ErrorState errorMessage="There was a problem loading your data" />
}
