type StyleType = "regular" | "param"
export const parseStringWithColor = (value: string) => {
  const splitStringArray = value.split("")
  let openParenCount = 0
  let startIndex = 0
  const result = splitStringArray.reduce((acc: { value: string; styleType: StyleType }[], char, indx) => {
    let newAcc = acc
    const defineColor = (styleType: StyleType, newValue: string) => {
      newAcc = [
        ...newAcc,
        {
          value: newValue,
          styleType,
        },
      ]
    }
    if (char === "(") {
      if (!openParenCount) {
        defineColor("regular", value.substring(startIndex, indx + 1))
        startIndex = indx + 1
      }
      openParenCount++
    }

    if (char === ")") {
      openParenCount--
      if (!openParenCount) {
        // sStyle text = "param" is set only for the content inside outter parentheses
        defineColor("param", value.substring(startIndex, indx))
        startIndex = indx
      }
    }

    if (value.length === indx + 1) {
      defineColor("regular", value.substring(startIndex))
    }
    return newAcc
  }, [])
  return result
}
