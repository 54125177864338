import { message, Space } from "antd"
import { useCallback } from "react"
import { AppModal } from "src/ui/AppModal/AppModal"
import { Button } from "src/ui/Button/Button"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { BodyWrapper, ButtonWrapper, FlexContainer, WarningIcon } from "src/users/Users.styles"
import {
  DeleteUserFromOrganizationDocument,
  DeleteUserFromOrganizationMutation,
  UsersDocument,
  UsersQuery,
} from "src/api/graphql/graphql-operations"
import { ApolloCache, FetchResult, useMutation, useQuery } from "@apollo/client"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { BodyNormal, Heading1 } from "src/ui/typography/Text/Text"

interface DeleteUserModalProps {
  userIdToRemove: string
  isModalVisible: boolean
  onReset: () => void
}

const DeleteUserModal = ({ userIdToRemove, onReset, isModalVisible }: DeleteUserModalProps) => {
  const { data } = useQuery(UsersDocument, { fetchPolicy: "cache-only" })
  const [removeUserFromOrganizationMutation, { loading, error, reset }] = useMutation(
    DeleteUserFromOrganizationDocument,
    {
      variables: { input: { id: userIdToRemove } },
      onError: () => undefined,
      onCompleted: () => {
        onReset()
        message.success("User removed from organization", MESSAGE_DURATION_SECONDS)
      },
      update: updateCacheToRemoveUser,
    },
  )
  const userToRemove = data?.allUsers?.edges.find((user) => user?.node?.id === userIdToRemove)?.node
  const onCancel = useCallback(() => {
    reset()
    onReset()
  }, [reset, onReset])

  return (
    <AppModal
      onCancel={onReset}
      open={isModalVisible}
      title={
        <FlexContainer>
          <Space direction="horizontal" align="center">
            <WarningIcon size="28px" name="exclamationCircle" />
            <Heading1>Remove User</Heading1>
          </Space>
        </FlexContainer>
      }
      footer={
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" danger onClick={() => removeUserFromOrganizationMutation()} loading={loading}>
            Yes, Remove This User
          </Button>
        </ButtonWrapper>
      }
    >
      <BodyWrapper>
        <div>
          <BodyNormal>
            Are you sure you want to remove the user
            <b> {userToRemove?.email ?? ""}</b>? Their accesss to Great Expectations Cloud will be revoked.
          </BodyNormal>
          {error && (
            <FeedbackMessageDoNotUse
              title="Error removing user"
              type="error"
              description="An error ocurred while removing this user, Try again later."
            />
          )}
        </div>
      </BodyWrapper>
    </AppModal>
  )
}

export { DeleteUserModal }

function updateCacheToRemoveUser(cache: ApolloCache<unknown>, result: FetchResult<DeleteUserFromOrganizationMutation>) {
  cache.updateQuery({ query: UsersDocument }, (data: UsersQuery | null) => {
    const deletedUserId = result?.data?.deleteUserFromOrganization?.id
    if (!deletedUserId || data?.allUsers === null || data?.allUsers === undefined) {
      return null
    }
    return {
      ...data,
      allUsers: {
        ...data.allUsers,
        edges: data.allUsers.edges.filter((e) => e?.node?.id !== deletedUserId),
      },
    }
  })
}
