import { Location, RouteObject as RRDomRouteObject } from "react-router-dom"
import { MainLayout } from "src/layout/routes/MainLayout"
import { OrganizationRoot } from "src/organizations/OrganizationRoot"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import Users from "src/users/Users"
import { GXAgent, LOGS_PATH_NAME } from "src/GXAgent/GXAgent"
import Tokens from "src/Tokens/Tokens"
import { DataAssets } from "src/DataAssets/DataAssets"
import { DefaultOrganizationRedirect } from "src/DefaultOrganizationRedirect/DefaultOrganizationRedirect"
import { NotFound } from "src/ui/error/NotFound"
import { RootRouteRedirect } from "src/RootRouteRedirect/RootRouteRedirect"
import { ErrorBoundary } from "react-error-boundary"
import { FeedbackForm } from "src/observability/sentry/FeedbackForm"
import { UserOnboardingWrapper } from "src/ui/EnterYourOrganizationName/UserOnboardingWrapper"

import { IconTypes } from "src/ui/Icon"
import { ExpectationsTab } from "src/DataAssets/AssetDetails/Expectations/ExpectationsTab"
import { ValidationsTab } from "src/DataAssets/AssetDetails/ValidationsTab"
import { OverviewTab } from "src/DataAssets/AssetDetails/OverviewTab"
import { AgentSetup } from "src/agentSetup/AgentSetup"
import { App } from "antd"
import { FeatureFlagList } from "src/devtools/FeatureFlagList"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { SimpleExpectationsTab } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationsTab"

export interface CrumbHandleConfig {
  // data is an arbitrary value returned by the corresponding react-router route loader
  data?: unknown
  // location is the current URL location
  location: Location
}

export interface CrumbHandleItem {
  // icon displays an inline icon
  icon?: IconTypes
  // title displays text after the icon
  title?: string | null
}

// Standardized page name for logging $pageview analytic events.
// Must match the Event Name on https://greatexpectations.atlassian.net/l/cp/UzQx6Dpe.
// Consult with Tetris when making any changes.
type PageviewName =
  | "data_asset.list_viewed"
  | "data_asset.overview_viewed"
  | "data_asset.metrics_viewed"
  | "data_asset.expectations_viewed"
  | "validation_result.viewed"
  | "expectation_suite.list_viewed"
  | "expectation_suite.viewed"
  | "users.list_viewed"
  | "tokens.list_viewed"
  | "agent_job.list_viewed"

export type Handle = {
  crumb?: (cfg: CrumbHandleConfig) => CrumbHandleItem
  pageviewName?: PageviewName
}

export type RouteObject = RRDomRouteObject & {
  handle?: Handle
}

const dataAssets: RouteObject = {
  path: "data-assets",
  handle: {
    crumb: (cfg: CrumbHandleConfig): CrumbHandleItem => ({
      icon: "database",
      title: cfg.location.pathname.endsWith("data-assets") ? null : "Data Assets",
    }),
  },
  children: [
    {
      index: true,
      element: <DataAssets />,
      handle: {
        pageviewName: "data_asset.list_viewed",
      },
    },
    {
      path: ":assetId",
      children: [
        {
          path: "expectations/expectation-suites/:expectationSuiteId?",
          element: <ExpectationsTab />,
          handle: {
            pageviewName: "data_asset.expectations_viewed",
          },
        },
        {
          path: "validations/expectation-suites/:expectationSuiteId/results/:validationResultId?",
          element: <ValidationsTab />,
          handle: {
            pageviewName: "validation_result.viewed",
          },
        },
        {
          path: "overview",
          element: <OverviewTab />,
          handle: {
            pageviewName: "data_asset.overview_viewed",
          },
        },
        {
          path: "metrics",
          element: <OverviewTab />,
          handle: {
            pageviewName: "data_asset.metrics_viewed",
          },
        },
      ],
    },
  ],
}

const simpleDataAssets: RouteObject = {
  path: "data-assets",
  handle: {
    crumb: (cfg: CrumbHandleConfig): CrumbHandleItem => ({
      icon: "database",
      title: cfg.location.pathname.endsWith("data-assets") ? null : "Data Assets",
    }),
  },
  children: [
    {
      index: true,
      element: <DataAssets />,
      handle: {
        pageviewName: "data_asset.list_viewed",
      },
    },
    {
      path: ":assetId",
      children: [
        {
          path: "overview",
          element: <OverviewTab />,
          handle: {
            pageviewName: "data_asset.overview_viewed",
          },
        },
        {
          path: "metrics",
          element: <OverviewTab />,
          handle: {
            pageviewName: "data_asset.metrics_viewed",
          },
        },
        {
          path: "expectations",
          element: <SimpleExpectationsTab />,
          handle: {
            pageviewName: "data_asset.expectations_viewed",
          },
        },
        {
          path: "validations/expectation-suites/:expectationSuiteId/results/:validationResultId?",
          element: <ValidationsTab />,
          handle: {
            pageviewName: "validation_result.viewed",
          },
        },
      ],
    },
  ],
}

const welcome: RouteObject = {
  path: "welcome",
  element: <UserOnboardingWrapper />,
}

const agentSetup: RouteObject = {
  path: "agent-setup",
  element: <AgentSetup />,
}

const four04: RouteObject = {
  path: "404",
  element: <NotFound />,
}

const empty: RouteObject = {
  // {/* -- Redirect "/" or "/login"  to landing path -- */}
  path: "",
  element: <RootRouteRedirect />,
}

const login: RouteObject = {
  path: "login",
  element: <RootRouteRedirect />,
}

const splat: RouteObject = {
  path: "*",
  element: <NotFound />,
}

const users: RouteObject = {
  path: "users",
  handle: {
    crumb: () => ({
      icon: "settings" as const,
    }),
    pageviewName: "users.list_viewed" as const,
  },
  element: <Users />,
}

const tokens: RouteObject = {
  path: "tokens",
  handle: {
    crumb: () => ({
      icon: "settings" as const,
    }),
    pageviewName: "tokens.list_viewed" as const,
  },
  element: <Tokens />,
}

const logs: RouteObject = {
  path: LOGS_PATH_NAME,
  handle: {
    crumb: () => ({
      icon: "history" as const,
    }),
    pageviewName: "agent_job.list_viewed" as const,
  },
  element: <GXAgent />,
}

const ff: RouteObject = {
  path: "_ff",
  element: <FeatureFlagList />,
}

export function useRoutes(): RouteObject[] {
  const isAdmin = useRequireRole("ORGANIZATION_ADMIN")
  const isEditor = useRequireRole("EDITOR")
  const sujeEnabled = useIsFeatureEnabled("sujeEnabled")

  const orgRoutes = [
    welcome,
    logs,
    ...(isAdmin ? [users] : []),
    ...(isEditor ? [tokens] : []),
    ...(sujeEnabled ? [simpleDataAssets] : [dataAssets]),
    four04,
    empty,
    login,
    splat,
    agentSetup,
    ff,
  ]

  return [
    {
      element: (
        <App>
          <MainLayout />
        </App>
      ),
      ErrorBoundary: () => <ErrorBoundary FallbackComponent={FeedbackForm} />,
      children: [
        {
          path: "organizations/:orgSlug",
          element: <OrganizationRoot />,
          children: orgRoutes,
        },
        {
          path: "*",
          element: <DefaultOrganizationRedirect />,
        },
      ],
    },
  ]
}
