import { useQuery } from "@apollo/client"
import { uniqBy } from "lodash-es"
import { Maybe } from "src/api/graphql/graphql"
import {
  DeleteDatasource_DatasourceFragment as DatasourceFragment,
  GetCheckpointsForDatasourceDocument,
} from "src/api/graphql/graphql-operations"
import { nonNull } from "src/common/utils/nonNull"

export function useCheckpointsForDatasource(
  datasourceId: string,
  skip: boolean,
): {
  loading: boolean
  checkpoints: { name: string; id: string }[]
  empty: boolean
} {
  const { data, loading } = useQuery(GetCheckpointsForDatasourceDocument, {
    skip,
    variables: { datasourceId },
  })
  const checkpoints = getCheckpointsFromDatasource(data?.datasourceV2)

  return { loading, checkpoints, empty: !checkpoints.length && !loading }
}

function getCheckpointsFromDatasource(datasource?: Maybe<DatasourceFragment>): { name: string; id: string }[] {
  if (!datasource) {
    return []
  }
  return uniqBy(
    datasource.assets.flatMap((asset) => asset?.checkpoints ?? []),
    "id",
  ).filter(nonNull)
}
