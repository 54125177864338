import { getRenderer, ExpectationContainer } from "src/Expectation/utils"
import { StyledPreviewSection } from "src/Expectation/Preview.styles"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { useMemo } from "react"

export function Preview({ renderer, success }: { renderer: ExpectationRenderer; success?: boolean }) {
  const previewRenderer = useMemo(() => ({ ...renderer, isPreview: true }), [renderer])
  return (
    <StyledPreviewSection>
      <ExpectationContainer success={success}>
        <div>{getRenderer(previewRenderer)}</div>
      </ExpectationContainer>
    </StyledPreviewSection>
  )
}
