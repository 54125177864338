import { Radio } from "antd"
import { RadioGroupProps } from "antd/es/radio/interface"
import styled, { css } from "styled-components"

const StyledRadioGroup = styled(Radio.Group)`
  ${({ theme }) => css`
    &.ant-radio-group.ant-radio-group-solid {
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
      }
    }
  `}
`
// Custom wrapper in order to prevent AntD's ::before pseudo-element from clashing when using solid buttons with our theme styling
export const RadioGroup = (props: RadioGroupProps) => {
  // @ts-expect-error "Property role does not exist on type." Antd generates the radiogroup with a11y issues
  return <StyledRadioGroup role="radiogroup" {...props} />
}
