import { ExpectationContainer, SpaceBetweenContainer, getRenderer } from "src/Expectation/utils"
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations"
import { DefaultChart } from "src/Expectation/Charts/DefaultChart"
import { NumericBetweenChart } from "src/Expectation/Charts/NumericBetweenChart"
import { isStringDate } from "src/Expectation/Charts/ChartUtils"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
type ValidationResultChartsProps = {
  validationResultCharts: Expectation_ValidationResultFragment[]
}

export function ValidationResultCharts({ validationResultCharts }: ValidationResultChartsProps) {
  const mostRecentExpectationValidationResult = validationResultCharts[0]
  const expectationType = mostRecentExpectationValidationResult.expectationConfig?.expectationType ?? ""
  const observedValue = JSON.parse(mostRecentExpectationValidationResult.result || "")
  const skipChart = isStringDate(observedValue["observed_value"])
  const supportedNumericBetweenExpectationTypesForGraphs = [
    "expect_column_max_to_be_between",
    "expect_column_min_to_be_between",
    "expect_column_mean_to_be_between",
    "expect_column_median_to_be_between",
    "expect_table_row_count_to_be_between",
    "expect_table_row_count_to_equal",
  ]

  let Chart = DefaultChart
  if (supportedNumericBetweenExpectationTypesForGraphs.includes(expectationType) && !skipChart) {
    Chart = NumericBetweenChart
  }
  const splitterData = useGetSplitterData({ isVisible: true })

  return (
    <ExpectationContainer success={mostRecentExpectationValidationResult.success ?? null}>
      <SpaceBetweenContainer>
        {getRenderer({
          renderedValue: mostRecentExpectationValidationResult?.expectationConfig?.renderedContent?.[0],
          fallback: expectationType,
          kwargs: mostRecentExpectationValidationResult?.expectationConfig?.kwargs,
        })}
      </SpaceBetweenContainer>
      {Chart && <Chart validationResultCharts={validationResultCharts} splitter={splitterData} />}
    </ExpectationContainer>
  )
}
