import { useLDClient } from "launchdarkly-react-client-sdk"
import { Table, TableProps } from "antd"
import styled from "styled-components"

interface FlagTableProps {
  name: string
  value: string | boolean
}

const columns: TableProps<FlagTableProps>["columns"] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: (value: string | boolean) => {
      if (typeof value === "boolean") {
        return <p style={{ color: value ? "green" : "red" }}>{`${value}`}</p>
      }

      return <pre>{JSON.stringify(value, null, 2)}</pre>
    },
  },
]

const StyledTable = styled(Table<FlagTableProps>)`
  tbody {
    tr {
      td {
        padding: 0 !important;
        padding-left: 1rem !important;
      }
    }
  }
`

export function FeatureFlagList() {
  const ld = useLDClient()
  const flags = ld?.allFlags() ?? {}
  const tableData = []

  for (const [key, value] of Object.entries(flags)) {
    tableData.push({
      key,
      name: key,
      value,
    })
  }

  tableData.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div style={{ marginLeft: "1rem" }}>
      <h1>Feature Flags</h1>
      <div style={{ width: "75vw", maxWidth: "45rem", paddingBottom: "5rem" }}>
        <StyledTable bordered pagination={false} columns={columns} dataSource={tableData} />
      </div>
    </div>
  )
}
