import { JsonFormsUISchemaRegistryEntry, JsonSchema, RuleEffect } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { BatchDefinitionJsonSchema } from "src/Expectation/batch-definition-json-schema"

const splitterUISchema: UISchema<typeof BatchDefinitionJsonSchema.definitions.SplitterYear> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column_name",
      label: "Batch Column",
    },
    {
      type: "Control",
      scope: "#/properties/method_name",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
  ],
}

export const BatchDefinitionUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  uischema: splitterUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    //console.log("SplitterUISchema Tester!!", { rank, schema, schemaPath, path })
    const rank =
      ["Yearly", "Monthly", "Daily"].includes(<string>schema?.title) && schemaPath === "#/properties/batchDefinition"
        ? 2
        : -1
    return rank
  },
}

const wholeTableSplitterUISchema: UISchema<(typeof BatchDefinitionJsonSchema.properties.batchDefinition.oneOf)[0]> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
  ],
}

export const WholeTableBatchDefinitionUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  uischema: wholeTableSplitterUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    const rank = schema.title?.startsWith("Entire table") && schemaPath === "#/properties/batchDefinition" ? 2 : -1
    //console.log("SplitterUISchema Tester!!", { rank, schema, schemaPath, path })
    return rank
  },
}
