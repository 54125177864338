import { Maybe } from "graphql/jsutils/Maybe"
import { getExpectationGroupName } from "src/common/utils/getExpectationGroupName"
import { sortAscWithTableExpectationsFirst } from "src/common/utils/sortAscWithTableExpectationsFirst"
import { GroupName } from "src/Expectation/words"
import { InteractiveExpectationFragment } from "src/api/graphql/graphql-operations"
import { ExpectationChanges } from "src/api/graphql/graphql"
import { nonNull } from "src/common/utils/nonNull"

export type ExpectationGroupResult = [GroupName | string, InteractiveExpectationFragment[]]

export type ExpectationChangesGroupResult = [GroupName | string, ExpectationChanges[]]

export function groupExpectations(
  expectations?: Maybe<Maybe<InteractiveExpectationFragment>[]>,
  searchValue?: string,
): ExpectationGroupResult[] {
  const groups =
    expectations
      ?.filter(nonNull)
      ?.reduce<Record<string, InteractiveExpectationFragment[]>>((acc, expectationConfig) => {
        const groupName = getExpectationGroupName(expectationConfig)
        if (searchValue && !groupName.toLowerCase().includes(searchValue.toLowerCase())) {
          return acc
        }
        if (Array.isArray(acc[groupName])) {
          acc[groupName].push(expectationConfig)
        } else {
          acc[groupName] = [expectationConfig]
        }
        return acc
      }, {}) ?? {}

  return Object.entries(groups).sort(([a], [b]) => sortAscWithTableExpectationsFirst(a, b))
}

export function groupExpectationChanges(
  expectationsWithChanges?: Maybe<Maybe<ExpectationChanges>[]>,
  searchValue?: string,
): ExpectationChangesGroupResult[] {
  const allExpectationsLatestConfigurations = expectationsWithChanges?.map((expectationWithChanges) => {
    return expectationWithChanges?.changes[0]?.expectationConfiguration
  })
  const groupedExpectationsWithoutChanges = groupExpectations(allExpectationsLatestConfigurations, searchValue)
  const groupedExpectationsWithChanges = groupedExpectationsWithoutChanges.map(
    ([columnName, columnExpectationsWithoutChanges]) => {
      const columnExpectationsWithChanges = columnExpectationsWithoutChanges.map((columnExpectationWithoutChanges) => {
        return expectationsWithChanges?.find((expectationWithChanges) => {
          return expectationWithChanges?.expectationId === columnExpectationWithoutChanges?.expectationId
        })
      })
      return [columnName, columnExpectationsWithChanges]
    },
  )
  return groupedExpectationsWithChanges as ExpectationChangesGroupResult[]
}
