import { List, Tooltip } from "antd"
import { useJobListItems } from "src/GXAgent/useJobListItems"
import { JobListItem } from "src/GXAgent/JobListItem"
import { Icon } from "src/ui/Icon"
import { theme } from "src/ui/themes/theme"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { Heading3 } from "src/ui/typography/Text/Text"

export function JobsList() {
  const { items: jobs, loading } = useJobListItems()
  const isGXAgentEnabled = useIsGXAgentEnabled()
  return (
    <List
      bordered
      size="small"
      loading={loading}
      style={{ overflow: "hidden" }}
      itemLayout="horizontal"
      header={
        <>
          <Heading3>
            Recent jobs{" "}
            <Tooltip title={`Displays 100 most recent ${isGXAgentEnabled ? "GX Agent " : ""}jobs.`}>
              <Icon
                size={theme.spacing.scale.xxs}
                className="icon"
                name="questionCircle"
                style={{ verticalAlign: "middle" }}
                color={theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
              />
            </Tooltip>
          </Heading3>
        </>
      }
      dataSource={jobs}
      renderItem={(job) => <JobListItem key={job.id} job={job} />}
      rowKey={(job) => job.id}
    />
  )
}
