import { Space } from "antd"
import { useCallback, useState } from "react"
import { AppModal } from "src/ui/AppModal/AppModal"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { Icon } from "src/ui/Icon"
import styled from "styled-components"
import { EditExpectationDrawer } from "src/Expectation/EditExpectationDrawer"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { useMutation } from "@apollo/client"
import { DeleteExpectationDocument } from "src/api/graphql/graphql-operations"
import { Button } from "src/ui/Button/Button"
import { InteractiveExpectationFragment } from "src/api/graphql/graphql"
import { NonNullableObject } from "src/common/utils/nonnullable-object"
import { Heading1 } from "src/ui/typography/Text/Text.tsx"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const AlignedFlexRowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.warning.colorWarning};
`

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  flex-direction: row;

  @media screen and (width <= 600px) {
    flex-direction: column;
  }
`

type ExpectationActionsProps = {
  expectation: Omit<InteractiveExpectationFragment, "expectationId"> &
    NonNullableObject<Required<Pick<InteractiveExpectationFragment, "expectationId">>>
  exists?: boolean
  queriesToRefetchOnDelete?: string[]
  renderedExpectation: ExpectationRenderer
  expectationJson: string
}

export const ExpectationActions = ({
  expectation,
  queriesToRefetchOnDelete,
  renderedExpectation,
  expectationJson,
}: ExpectationActionsProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const handleClose = () => {
    setIsDrawerVisible(false)
  }
  const [deleteExpectationMutation, { loading, data, error }] = useMutation(DeleteExpectationDocument, {
    refetchQueries: queriesToRefetchOnDelete,
  })
  const deleteExpectation = useCallback(async () => {
    await deleteExpectationMutation({
      variables: { input: { expectationId: expectation.expectationId } },
    })
  }, [deleteExpectationMutation, expectation.expectationId])

  return (
    <Container>
      <AppModal
        open={isModalVisible}
        title={
          <AlignedFlexRowContainer>
            <Space direction="horizontal" align="center">
              <StyledIcon size="28px" name="exclamationCircle" />
              <Heading1>Delete Expectation</Heading1>
            </Space>
          </AlignedFlexRowContainer>
        }
        okText="Yes, delete Expectation"
        okType="primary"
        onOk={() => deleteExpectation()}
        okButtonProps={{
          danger: true,
          loading: loading || Boolean(data),
        }}
        onCancel={() => setIsModalVisible(false)}
      >
        <>
          {error && (
            <FeedbackMessageDoNotUse title="Error deleting Expectation" type="error" description={error.message} />
          )}
          Are you sure you want to delete this Expectation?
          <br /> This action can&apos;t be undone.
        </>
      </AppModal>
      {expectation?.expectationId && (
        <EditExpectationDrawer
          // specifying the expectation this way is a little awkward, but required to assure compiler that expectationId is both present and non-null
          expectation={{ ...expectation, expectationId: expectation.expectationId }}
          expectationJson={expectationJson}
          renderer={renderedExpectation}
          isVisible={isDrawerVisible}
          onClose={handleClose}
        />
      )}
      <StyledButtonContainer>
        <Button
          type="text"
          title="Edit Expectation"
          aria-label="Edit Expectation"
          icon="edit"
          onClick={(e) => {
            showDrawer()
            e.stopPropagation()
          }}
        />
        <Button
          type="text"
          title="Delete Expectation"
          aria-label="Delete Expectation"
          icon="trash"
          onClick={(e) => {
            setIsModalVisible(true)
            e.stopPropagation()
          }}
        />
      </StyledButtonContainer>
    </Container>
  )
}
