import { useState } from "react"
import { Button } from "src/ui/Button/Button"
import { ValidateSubsetDrawer } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetDrawer"

interface ValidateSubsetButtonProps {
  expectationSuiteId: string
  assetId: string
  disabled?: boolean
}

export const ValidateSubsetButton = ({ expectationSuiteId, assetId, disabled }: ValidateSubsetButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  return (
    <>
      <Button icon="play" type="primary" onClick={() => setIsDrawerOpen(true)} disabled={disabled}>
        Validate
      </Button>
      <ValidateSubsetDrawer
        expectationSuiteId={expectationSuiteId}
        assetId={assetId}
        open={isDrawerOpen}
        close={() => setIsDrawerOpen(false)}
      />
    </>
  )
}
