import { useQuery } from "@apollo/client"
import { GetAgentStatusDocument } from "src/api/graphql/graphql-operations"
import { REACT_APP_AGENT_STATUS_POLL_INTERVAL } from "src/common/env"

export function PollForAgentStatus() {
  useQuery(GetAgentStatusDocument, {
    pollInterval: REACT_APP_AGENT_STATUS_POLL_INTERVAL,
    canonizeResults: true,
    fetchPolicy: "network-only",
  })
  return null
}
