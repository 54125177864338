import { Flex, Space } from "antd"
import { FilterBox } from "src/ui/FilterBox/FilterBox"
import { useEffect, useState } from "react"
import { useAnchorScroll } from "src/DataAssets/AssetDetails/useAnchorScroll"
import { groupExpectations } from "src/common/utils/groupExpectations"
import { ExpectationList } from "src/DataAssets/AssetDetails/Expectations/ExpectationList/ExpectationList"
import { useParams } from "react-router-dom"
import {
  ExpectationChangesDocument,
  ExpectationSuiteDocument,
  ExpectationSuitesDocument,
} from "src/api/graphql/graphql-operations"
import { useApolloClient, useQuery } from "@apollo/client"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"

import { ListAndFilterLayout } from "src/ui/ListAndFilterLayout/ListAndFilterLayout"
import { ExpectationSuiteSelector } from "src/DataAssets/AssetDetails/ExpectationSuiteSelector/ExpectationSuiteSelector"
import { PageHeader } from "src/ui/PageHeader"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { ValidateExpectationsModal } from "src/DataAssets/AssetDetails/ValidateExpectationsModal"
import { MainContent } from "src/layout/MainContent"
import { ViewModes, ViewToggle, ViewModeTypes } from "src/ui/Button/ViewToggle"
import { useUrlParams } from "src/common/utils/url-params"
import { useExpectationHistoryQuery } from "src/common/hooks/useExpectationHistoryQuery"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { CreateExpectationForAssetAndSuiteDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetAndSuiteDrawer"
import { ErrorAndEmptyState } from "src/DataAssets/AssetDetails/Expectations/ErrorAndEmptyState"
import { DataAssetHeaderActions } from "src/DataAssets/AssetDetails/DataAssetHeaderActions"
import {
  getColumnsFromExpectationsList,
  getColumnsWithChanges,
  getSubtitle,
} from "src/DataAssets/AssetDetails/Expectations/utils"
import { EXPECTATION_CHANGE_LOG_LIMIT } from "src/common/config"
import { EXPECTATIONS_LIST_ERROR_MESSAGE, HISTORY_LOG_ERROR_MESSAGE } from "src/Expectation/words"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { ScheduleSummary } from "src/DataAssets/AssetDetails/Expectations/ScheduleSummary"
import { useEmailAlertsEvent } from "src/DataAssets/AssetDetails/Validations/ValidationHooks"
import { graphql } from "src/api/graphql/gql"
import { useIsDemoData } from "src/common/hooks/useIsDemoData"
import { CreateExpectationsAlert } from "src/Alerts/DemoData/CreateExpectationsAlert"
import { ValidateExpectationsAlert } from "src/Alerts/DemoData/ValidateExpectationsAlert"

export const ExpectationsTab_DataAssetDocument = graphql(`
  query expectationsTab_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      __typename
      id
      name
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
      }
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

export function ExpectationsTab() {
  // Analytics events
  useEmailAlertsEvent()

  const [search, setSearch] = useState("")
  const [openCreateExpectationDrawer, setOpenCreateExpectationDrawer] = useState(false)
  const [openValidateExpectationsModal, setOpenValidateExpectationsModal] = useState(false)
  const [limit, setLimit] = useState<number | undefined>(() => EXPECTATION_CHANGE_LOG_LIMIT)
  const { assetId = "", expectationSuiteId = "" } = useParams<Record<"assetId" | "expectationSuiteId", string>>()
  const [{ viewMode }, setParams] = useUrlParams<{ viewMode: ViewModes }>({ viewMode: "currentView" })
  const client = useApolloClient()

  const { data: dataAssetData } = useQuery(ExpectationsTab_DataAssetDocument, {
    variables: {
      id: assetId,
    },
    skip: !assetId,
  })

  const isDemoData = useIsDemoData(dataAssetData?.dataAsset)

  const {
    data: expectationSuitesData,
    loading: suitesLoading,
    error: suitesError,
  } = useQuery(ExpectationSuitesDocument, {
    variables: {
      options: {
        filterByAssetRefId: assetId,
      },
    },
    skip: !assetId,
  })

  const {
    data: expectationsData,
    loading: suiteLoading,
    error: expectationsError,
  } = useQuery(ExpectationSuiteDocument, {
    variables: {
      id: expectationSuiteId,
    },
    skip: !expectationSuiteId || expectationSuiteId.length === 0,
  })

  const expectationChanges = useExpectationHistoryQuery(search, expectationSuiteId, limit)

  const expectationsLoading =
    suitesLoading || suiteLoading || (viewMode === "changeLog" && expectationChanges.expectationsChangesLoading)
  const { selectedAnchor, onAnchorSelection } = useAnchorScroll(expectationsLoading)

  const expectations = expectationsData?.expectationSuiteV2?.expectations
  const expectationsGroupedByColumn = groupExpectations(expectations, search)

  const columns =
    viewMode === "currentView"
      ? getColumnsFromExpectationsList(expectations, search)
      : getColumnsWithChanges(search, expectationChanges.expectationsWithChanges)

  const onSearch = (value: string) => {
    setSearch(value)
  }

  const { navigateInOrg } = useOrganizationSlug()

  /*
   * Redirect to first expectation suite after getting the list of Expectation Suites by Asset Ref ID
   */
  useEffect(() => {
    if (expectationSuitesData?.expectationSuitesV2?.[0]?.id && !expectationSuiteId) {
      const newExpectationSuiteId = expectationSuitesData.expectationSuitesV2[0].id
      navigateInOrg(`/data-assets/${assetId}/expectations/expectation-suites/${newExpectationSuiteId}`, {
        replace: true,
      })
    }
  }, [assetId, expectationSuitesData, expectationSuiteId, navigateInOrg])

  const noExpectations = expectations?.length === 0 || !expectations
  const showExpectationsError = (suitesError || expectationsError) && expectationsGroupedByColumn.length === 0
  const showHistoryError =
    viewMode === "changeLog" &&
    expectationChanges.expectationsChangesError &&
    !expectationChanges.expectationsChangesData?.expectationChanges.length
  const { splitter, isSplitterSupported } = useGetSplitterData({ isVisible: true })
  const showValidateSubsetButton = !!(isSplitterSupported && splitter)
  const handleViewToggleClick = (value: ViewModes) => {
    if (value === "changeLog") {
      client.refetchQueries({ include: [ExpectationChangesDocument] })
    }
    setParams({ viewMode: value })
  }
  const dataAssetName = dataAssetData?.dataAsset?.name ?? ""

  return (
    <PageHeader
      headerContent={{
        title: dataAssetName,
        rootPath: "data-assets",
        subtitle: getSubtitle(expectations?.length ?? 0, expectationsLoading),
        navigateBackTo: "/data-assets",
        rightActions: {
          reactNode: (
            <DataAssetHeaderActions
              assetId={assetId}
              showValidateSubsetButton={showValidateSubsetButton}
              noExpectations={noExpectations}
              expectationSuiteId={expectationSuiteId}
              onNewExpectationButtonClick={() => setOpenCreateExpectationDrawer(true)}
            />
          ),
        },
        customBreadcrumbs: getDataAssetBreadcrumbs({
          dataSource: dataAssetData?.dataAsset?.datasourceV2,
          tooltipPlacement: "right",
        }),
        footer: <DataAssetTabs expectationSuiteId={expectationSuiteId} currentTab="expectations" />,
      }}
      loading={expectationsLoading}
    >
      <MainContent $noIllustration={true}>
        <ErrorAndEmptyState
          errorMessage={
            (showHistoryError && HISTORY_LOG_ERROR_MESSAGE) ||
            (showExpectationsError && EXPECTATIONS_LIST_ERROR_MESSAGE) ||
            undefined
          }
        >
          <CreateExpectationDrawerContextProvider
            open={openCreateExpectationDrawer}
            onClose={() => {
              setOpenCreateExpectationDrawer(false)
            }}
          >
            <CreateExpectationForAssetAndSuiteDrawer assetId={assetId} expectationSuiteId={expectationSuiteId} />
          </CreateExpectationDrawerContextProvider>
          <ValidateExpectationsModal
            expectationSuiteID={expectationSuiteId}
            assetRefID={assetId}
            open={openValidateExpectationsModal}
            onCancel={() => setOpenValidateExpectationsModal(false)}
            onOk={() => setOpenValidateExpectationsModal(false)}
          />
          <ListAndFilterLayout
            filter={
              <Flex vertical gap={20}>
                <ViewToggle
                  defaultValue={viewMode && ViewModeTypes.includes(viewMode) ? viewMode : "currentView"}
                  onToggleClick={handleViewToggleClick}
                />
                <ExpectationSuiteSelector
                  currentTab="expectations"
                  onSelect={() => {
                    !limit && setLimit(EXPECTATION_CHANGE_LOG_LIMIT)
                  }}
                />
                <FilterBox
                  onSearch={onSearch}
                  list={columns}
                  loading={expectationsLoading}
                  onSelect={onAnchorSelection}
                  selectedItem={selectedAnchor}
                  title="Columns"
                />
              </Flex>
            }
            stickyOffset={170}
          >
            <Space direction="vertical" size="large" style={{ display: "flex" }}>
              {isDemoData &&
                (noExpectations && !expectationsLoading ? <CreateExpectationsAlert /> : <ValidateExpectationsAlert />)}

              {viewMode === "currentView" && (
                <ScheduleSummary
                  assetId={assetId}
                  expectationSuiteId={expectationSuiteId}
                  canActivate={!expectationsLoading && !!expectations && expectations.length > 0}
                />
              )}
              <ErrorAndEmptyState
                expectationsLoading={expectationsLoading}
                showNoSearchResults={Boolean(
                  !expectationsLoading && search && expectationsGroupedByColumn.length === 0,
                )}
                showExpectationsEmptyState={Boolean(
                  !expectationsLoading && expectationSuiteId && noExpectations && viewMode === "currentView",
                )}
                search={search}
                expectationSuiteId={expectationSuiteId}
                assetId={assetId}
              >
                <ExpectationList
                  currentExpectationGroups={expectationsGroupedByColumn}
                  expectationGroupsWithChanges={expectationChanges.expectationChangesGroupedByColumn}
                  viewMode={viewMode}
                  selectedGroup={selectedAnchor}
                  changeLogLimit={limit}
                  removeChangeLogLimit={() => setLimit(undefined)}
                  changeLogWithoutLatestChangesCount={expectationChanges.changeLogCount - (expectations?.length ?? 0)}
                />
              </ErrorAndEmptyState>
            </Space>
          </ListAndFilterLayout>
        </ErrorAndEmptyState>
      </MainContent>
    </PageHeader>
  )
}
