import { Alert } from "antd"
import { useMemo } from "react"
import { BodyStrong } from "src/ui/typography/Text/Text"
import { ExpectationTitle } from "src/schemas/expectation-metadata-utils"
import { DemoDataAssetName } from "src/common/hooks/useDemoDataAssetName"

export const MESSAGE = "Try creating the following expectations"

/**
 * A function that returns a JSX element array that describes the
 * expectations that should be created for a given demo data asset.
 * @param selectedExpectationTitle The title of the selected expectation, if any
 * @returns An array of JSX elements describing the expectations
 * that could be created for the given demo data asset
 */
export type DemoDataExpectationDescriptionFunction = (
  selectedExpectationTitle: ExpectationTitle | undefined,
) => JSX.Element[]

type CreateExpectationDrawerAlertProps = {
  /** The name of the demo data asset */
  assetName: DemoDataAssetName | undefined
  /** The title of the selected expectation, if any */
  selectedExpectationTitle: ExpectationTitle | undefined
  /** The current page of the drawer */
  page: "picker" | "editor"
}

type ItemChecker = Partial<Record<ExpectationTitle, boolean>>

const NYC_FARE_ITEM_EXPECTATION_DICT: ItemChecker = {
  "Expect column minimum to be between": true,
}
const NYC_PICKUP_BOROUGH_ITEM_EXPECTATION_DICT: ItemChecker = {
  "Expect column distinct values to be in set": true,
  "Expect column distinct values to equal set": true,
  "Expect column values to be in set": true,
}

/**
 * A function that returns a JSX element array that describes the
 * expectations that should be created for the NYC Taxi Data demo data asset.
 *
 * - suggests fare_amount and pickup_borough expectations if no selected expectation
 * - suggests fare_amount only if selected expectation is:
 *     - expect column minimum to be between
 * - suggests pickup_borough only if selected expectation is:
 *     - expect column distinct values to equal
 *     - expect column distinct values to be in set
 *     - expect column values to be in set
 * @param selectedExpectationName The name of the selected expectation, if any
 * @returns An array of JSX elements describing the expectations
 * that could be created for the NYC Taxi Data demo data asset
 */
const nycTaxiTester: DemoDataExpectationDescriptionFunction = (selectedExpectationName) => {
  const noSelection = !selectedExpectationName
  const isFareAmount = selectedExpectationName && NYC_FARE_ITEM_EXPECTATION_DICT[selectedExpectationName]
  const isPickupBorough = selectedExpectationName && NYC_PICKUP_BOROUGH_ITEM_EXPECTATION_DICT[selectedExpectationName]
  const jsx = []
  if (noSelection || isFareAmount) {
    jsx.push(
      <li>
        Check range of <BodyStrong>fare_amount</BodyStrong> using{" "}
        <BodyStrong>Expect column minimum to be between</BodyStrong> 0 and 250
      </li>,
    )
  }
  if (noSelection || isPickupBorough) {
    jsx.push(
      <li>
        Check accuracy of <BodyStrong>pickup_borough</BodyStrong> using{" "}
        <BodyStrong>Expect column distinct values to equal set</BodyStrong> [Bronx, Brooklyn, Manhattan, Queens, Staten
        Island]
      </li>,
    )
  }
  return jsx
}

/** A mapping of asset names to their respective creation
 * functions.  When new demo data assets are added to the
 * demo database they should be added here.
 */
const DESCRIPTION: Record<DemoDataAssetName, DemoDataExpectationDescriptionFunction> = {
  nyc_taxi_data: nycTaxiTester,
}

export function CreateExpectationDrawerAlert({
  assetName,
  selectedExpectationTitle,
  page,
}: CreateExpectationDrawerAlertProps) {
  const description = useMemo(() => {
    const checker = DESCRIPTION[assetName ?? "nyc_taxi_data"]

    if (typeof checker !== "function") return []

    return checker(page === "picker" ? undefined : selectedExpectationTitle)
  }, [assetName, page, selectedExpectationTitle])

  if (description.length === 0) return null

  return (
    <Alert
      message={MESSAGE}
      description={<ul style={{ margin: 0, paddingLeft: "30px" }}>{description}</ul>}
      type="info"
      showIcon
    />
  )
}
