import { OnboardingSurvey } from "src/ui/OnboardingSurvey/OnboardingSurvey"
import { EnterOrganizationName } from "src/ui/EnterYourOrganizationName/EnterOrganizationName"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

export const UserOnboardingWrapper = () => {
  const isFeatureEnabled = useIsFeatureEnabled("onboardingSurveyMarch24Enabled")

  if (isFeatureEnabled) {
    return <OnboardingSurvey />
  }
  return <EnterOrganizationName />
}
