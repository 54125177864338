import styled from "styled-components"
import { GX_AGENT_DOCS_LINK, GX_AGENT_INACTIVE } from "src/GXAgent/words"
import { Alert } from "antd"

const Container = styled.div`
  margin: ${({ theme }) => theme.spacing.horizontal.xs} 0;
`
const StyledAppAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => `${theme.spacing.horizontal.s}`};
`

export function AgentInactiveAlert() {
  return (
    <Container>
      <StyledAppAlert
        description={
          <>
            {GX_AGENT_INACTIVE} {GX_AGENT_DOCS_LINK}
          </>
        }
        type="warning"
        showIcon
        aria-label="Inactive Agent Alert"
      />
    </Container>
  )
}
