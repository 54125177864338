import { Input, InputNumber } from "antd"
import { ColumnType } from "src/Expectation/uiForms/customRenderers/RowConditionControl/useColumnTypeOperators.tsx"
import { DateFnsDatePicker } from "src/ui/DatePicker/DateFnsDatePicker"

interface RowConditionParameterProps {
  columnType: ColumnType
  onChange: (value: string) => void
  value?: string
}

export const RowConditionParameter = ({ columnType, onChange, ...props }: RowConditionParameterProps) => {
  if (columnType === "DateTime") {
    return (
      <DateFnsDatePicker
        onChange={(_date, dateString) => {
          // Due to an AntD bug we have to check if the dateString is an array or not even though it shouldn't be an array in this case https://github.com/ant-design/ant-design/issues/49198
          onChange(Array.isArray(dateString) ? dateString[0] : dateString)
        }}
        style={{ width: "100%" }}
        value={props?.value ? new Date(props.value) : undefined}
        showTime
      />
    )
  }
  if (columnType === "Numeric") {
    return (
      <InputNumber onChange={(event) => onChange(event !== null ? event : "")} style={{ width: "100%" }} {...props} />
    )
  }
  return <Input onChange={(e) => onChange(e.target.value)} style={{ width: "100%" }} {...props} />
}
