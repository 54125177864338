import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { BatchDefinitionJsonSchema } from "src/Expectation/batch-definition-json-schema"

export const batchDefinitionSegmentedUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/batchDefinition",
      options: { optionType: "segmented" },
    },
  ],
} satisfies UISchema<typeof BatchDefinitionJsonSchema>
