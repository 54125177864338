import { Maybe } from "graphql/jsutils/Maybe"
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations"
import { getExpectationGroupName } from "src/common/utils/getExpectationGroupName"
import { nonNull } from "src/common/utils/nonNull"

export function groupValidationResults(expectations?: Maybe<Maybe<Expectation_ValidationResultFragment>[]>) {
  return expectations
    ?.filter(nonNull)
    .reduce<Record<string, Expectation_ValidationResultFragment[]>>((acc, expectation) => {
      const groupName = getExpectationGroupName(expectation.expectationConfig)
      return {
        ...acc,
        [groupName]: [...(Array.isArray(acc[groupName]) ? acc[groupName] : []), expectation],
      }
    }, {})
}
