import { Skeleton } from "antd"

import { PageHeader } from "src/ui/PageHeader"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { ExpandableContainer } from "src/ui/ExpandableContainer"
import { Button } from "src/ui/Button/Button"
import { TokensTable } from "src/Tokens/TokensTable"
import {
  StyledBodyContainer,
  StyledDataPlaneContents,
  StyledPath,
  StyledPathRow,
  StyledTokenSection,
} from "src/Tokens/Tokens.styles"
import { formatOrgRole } from "src/organizations/roles"
import { copyToClipboard } from "src/common/utils/clipboard"
import { useOrganizations } from "src/organizations/useOrganizations"
import { MainContent } from "src/layout/MainContent"
import { useQuery } from "@apollo/client"
import { GetUserTokensDocument, OrganizationApiTokensDocument } from "src/api/graphql/graphql-operations"

export interface Token {
  id: string
  token: string
  name: string
  role: undefined | string
}

const Tokens = () => {
  const { currentOrg, isLoading: orgLoading } = useOrganizations()

  const headerData: HeaderTypes = {
    title: "Tokens",
    rootPath: "tokens",
  }

  const { data: userTokenData, loading: userTokenLoading } = useQuery(GetUserTokensDocument, {
    variables: {},
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  })
  const { data: orgTokenData, loading: orgTokenLoading } = useQuery(OrganizationApiTokensDocument, {
    variables: {},
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  })

  const allUserTokens: Token[] =
    userTokenData?.allUserApiTokens?.edges.map((edge) => {
      return {
        id: edge?.node?.id || "",
        name: edge?.node?.name || "",
        token: edge?.node?.token || "",
        role: formatOrgRole(edge?.node?.organizationRole),
      }
    }) ?? []

  const allOrgTokens: Token[] =
    orgTokenData?.allOrganizationApiTokens?.edges.map((edge) => {
      return {
        id: edge?.node?.id || "",
        name: edge?.node?.name || "",
        token: edge?.node?.token || "",
        role: formatOrgRole(edge?.node?.organizationRole),
      }
    }) ?? []

  const currentOrgId = currentOrg?.id ?? ""
  return (
    <PageHeader headerContent={headerData}>
      <MainContent>
        <StyledBodyContainer>
          <StyledTokenSection>
            <TokensTable
              createButtonText="Create user access token"
              data={allUserTokens}
              description="This token provides GX Cloud Editor permissions to users."
              sectionTitle="User access tokens"
              isDeletable={true}
              loading={userTokenLoading}
            />
          </StyledTokenSection>
          <StyledTokenSection>
            <ExpandableContainer title="Organization ID" collapsible={false} contentSpacing={false}>
              <StyledDataPlaneContents>
                <StyledPathRow>
                  <Skeleton loading={orgLoading} paragraph={false} active={true}>
                    <StyledPath value={currentOrgId} aria-label="organization id" />
                    <Button
                      icon="clipboard"
                      type="default"
                      aria-label="Copy"
                      title="Copy Organization ID"
                      onClick={() => copyToClipboard(currentOrgId)}
                    />
                  </Skeleton>
                </StyledPathRow>
              </StyledDataPlaneContents>
            </ExpandableContainer>
          </StyledTokenSection>
          <StyledTokenSection>
            <TokensTable
              createButtonText="Create organization access token"
              data={allOrgTokens}
              description="This token provides GX Cloud Editor permissions to shared systems, such as a scheduled pipeline run."
              sectionTitle="Organization access tokens"
              isDeletable={true}
              loading={orgTokenLoading}
            />
          </StyledTokenSection>
        </StyledBodyContainer>
      </MainContent>
    </PageHeader>
  )
}

export default Tokens
