import { message } from "antd"
import { useEffect, useState } from "react"
import { useGetAutogenerateStatus } from "src/common/hooks/useGetAutogenerateStatus"
import { Link } from "src/ui/typography/Text/Text.tsx"

export default function AutogenerateExpectationsToast() {
  const { jobMessage, jobStatus } = useGetAutogenerateStatus()
  const [messageApi, contextHolder] = message.useMessage()
  const [jobInProgress, setJobInProgress] = useState<boolean>(false)
  const key = "global"

  useEffect(() => {
    if (!jobInProgress && jobStatus === "loading") {
      setJobInProgress(true)
      messageApi.open({
        key,
        content: jobMessage,
        type: "loading",
        duration: 0,
      })
    }

    if (jobInProgress && jobStatus === "success") {
      setJobInProgress(false)
      messageApi.open({
        key,
        content: jobMessage,
        type: "success",
        duration: 2,
      })
    }

    if (jobInProgress && jobStatus === "error") {
      setJobInProgress(false)
      messageApi.open({
        key,
        content: (
          <>
            {jobMessage}
            {"\n"}
            See{" "}
            <Link to="/logs" strong>
              logs
            </Link>{" "}
            for details.
          </>
        ),
        type: "error",
        duration: 5,
      })
    }
  }, [jobInProgress, jobMessage, jobStatus, messageApi])

  return <>{contextHolder}</>
}
